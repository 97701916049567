// ThreeColumnPages.jsx

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "Components/misc/Headings";
import { SectionDescription } from "Components/misc/Typography";
import { Container, ContentWithPaddingXl } from "Components/misc/Layouts";
import ArrowRightIcon from "images/arrow-right-icon.avif"; // Ensure this path is correct
import { Link } from "react-router-dom";
import { Span } from "./DashedBorderSixFeatures";

// Styled Components
const Heading = tw(SectionHeading)`text-black`;
const Description = tw(SectionDescription)`text-center mx-auto`;

// Updated ThreeColumnContainer to use CSS Grid with responsive columns and visible border
const ThreeColumnContainer = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 border-2 border-black p-0 items-center md:mx-4 w-full mx-auto py-24 font-poppins`}
  grid-auto-rows: minmax(0, 1fr); /* Ensure all grid items have equal height */
`;

// Adjusted Column component for grid layout
const Column = styled.div`
  ${tw`flex flex-col`}
`;

// Updated Card component
const Card = styled.div`
  ${tw`flex flex-col 
    lg:py-12
    lg:px-8
    items-center 
    justify-between
    border-2 border-primary-500 
    rounded-4xl
    mt-4 
    p-6
    shadow-cxl relative sm:py-10 md:w-[80%] h-3/4`} 

  .iconWrapper {
    ${tw`flex items-center justify-center bg-gray-100 rounded-full p-4`} /* Styled icon background */
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none text-black no-underline`}
    text-decoration: none !important;
  }

  .description {
    ${tw`mt-3 font-medium text-secondary-100 text-center w-full leading-loose no-underline flex-grow`}
  }

  &:hover {
    ${tw`transform scale-105`} /* Scale on hover */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2); /* Add shadow effect on hover */
    transition: all 0.3s ease; /* Smooth transition */
  }

  .link {
    ${tw`mt-4 flex items-center text-primary-500 font-semibold hover:text-secondary-100 transition duration-300`}
  }

  .link img.icon {
    ${tw`ml-2 w-4 h-4`}
  }
`;

// Styled Link to remove default underline
const StyledLink = styled(Link)`
  ${tw`no-underline`}
`;

// Subheading Wrapper with lines and text
const SubheadingWrapper = styled.div`
  ${tw`flex items-center justify-center text-center w-full my-4`}
`;

// Line styled component
const Line = styled.div`
  ${tw`w-12 h-[2px] bg-black`} /* Adjust color and width as needed */
`;

// Text styled component for subheading
const Text = styled.h2`
  ${tw`px-2 text-customPurple font-semibold text-lg`}
`;

export default ({
  cards = [],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && (
          <SubheadingWrapper>
            <Line />
            <Text>{subheading}</Text>
            <Line />
          </SubheadingWrapper>
        )}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <StyledLink to={card.url}>
                <Card>
                  <span className="imageContainer" css={imageContainerCss}>
                    <div className="iconWrapper">
                      {card.icon} {/* Rendered icon component */}
                    </div>
                  </span>
                  <span className="title">{card.title}</span>
                  <p className="description">{card.description}</p>
                  {linkText && (
                    <Span className="link ">
                      <Span>{linkText}</Span>
                      <img src={ArrowRightIcon} alt="arrow right" className="icon" />
                    </Span>
                  )}
                </Card>
              </StyledLink>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
