// src/components/TwoColumnList.jsx
import React, { useContext, useRef } from "react";
import tw, { styled } from "twin.macro";
import { SectionHeading } from "Components/misc/Headings";
import { Line, SubheadingWrapper, Text } from "./TwoColWithButton";
import { Span } from "./DashedBorderSixFeatures";
import { CursorContext } from "context/CursorContext";

// Importing icons from react-icons/fa
import {
  FaPaintBrush,
  FaBullhorn,
  FaBook,
  FaRocket,
  FaLock,
  FaCogs,
  FaSearch,
  FaMobileAlt,
  FaPenNib,
  FaUsers,
  FaChartLine,
  FaBullseye,
  FaMoneyBillWave,
  FaChartPie,
  FaDraftingCompass,
  FaLightbulb,
  FaRegFileAlt,
  FaCut,
  FaStore,
  FaBoxes,
  FaStar,
  FaRegNewspaper,
  FaAd,
  FaEdit,
} from "react-icons/fa";

// Styled Components
const Container = styled.section`
  ${tw``}

  ${({ features }) =>
    features
      ? tw`py-10 mt-0 mb-0 p-4`
      : tw`relative p-4 mt-4 md:mt-8 lg:mt-8 xl:mt-8 md:mb-8 lg:mb-8 xl:mb-8`}
`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-center gap-0 md:gap-32 lg:gap-32 xl:gap-32 max-w-6xl mx-auto`;

const TextColumn = tw.div`w-full md:w-5/12`;

const VideoColumn = tw.div`w-full md:w-8/12 flex items-center justify-center relative`;

const Heading = styled(SectionHeading)(({ black }) => [
  tw`text-left text-2xl sm:text-3xl lg:text-4xl font-bold leading-tight mt-4`,
  black ? tw`text-white` : tw`text-black`,
]);
const Description = styled.p(({ isBlack }) => [
  tw`mt-2 text-left text-sm md:text-lg leading-relaxed`,
  isBlack ? tw`text-black` : tw`text-white`,
]);

const List = tw.ul`mt-4 space-y-2`;

// Styled components for Feature Title and Description
const FeatureTitle = styled.span(({ isBlack }) => [
  tw`font-bold`,
  isBlack ? tw`text-black` : tw`text-customPurple`,
]);

const FeatureDescription = styled.span(({ isBlack }) => [
  tw`ml-2`,
  isBlack ? tw`text-customPurple` : tw`text-white`,
]);

// Updated ListItem Component
const ListItem = styled.li(({ isBlack }) => [
  tw`flex items-start gap-x-4 text-left text-lg font-semibold`,
  `
    svg {
      ${tw`w-10 h-10 mr-3 mt-1`}
      color: ${isBlack ? `var(--tw-color-customPurple)` : `var(--tw-color-white)`};
    }
  `,
]);

// Styled components for the Call to Action section
const CallToActionWrapper = styled.div(({ isBlack }) => [
  tw`mt-8 flex flex-col items-start p-6 rounded-2xl`,
  isBlack ? tw`bg-white` : tw`bg-white`,
]);

const CallToActionText = styled.p`
  ${tw`text-left text-2xl md:text-3xl font-medium text-black mb-4`}
`;

const CallToActionButton = styled.a`
  ${tw`bg-black text-white text-lg font-medium border-2 py-3 px-6 rounded-lg hover:bg-white hover:text-red-500 hover:border-transparent transition-all duration-300`}
  cursor: pointer;
  text-decoration: none;

  &:focus {
    outline: 2px solid #fff;
    outline-offset: 2px;
  }
`;

const VideoWrapper = styled.div`
  ${tw`w-full h-2/4 rounded-4xl overflow-hidden shadow-2xl relative`}

  /* Conditional styling based on 'features' prop */
  ${({ features }) => features && tw`h-1/2`}

  /* Maintain aspect ratio */
  padding-top: 26.25%; /* 16:9 Aspect Ratio */

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    cursor: none; /* Hide the default cursor over the video */
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
`;

// Enhanced and Concise Services Features
const servicesFeatures = {
  "Brand Identity Development": {
    heading: "Brand Identity Development",
    description:
      "Craft a distinctive brand identity that tells your story and resonates with your audience. We focus on creating a cohesive brand image that reflects your values and stands out in the market.",
    features: [
      {
        icon: FaPaintBrush,
        title: "Custom Logo Design",
        description: "Memorable logos that embody your brand’s essence.",
      },
      {
        icon: FaBullhorn,
        title: "Consistent Messaging",
        description: "Unified brand voice across all platforms.",
      },
      {
        icon: FaBook,
        title: "Style Guides",
        description: "Detailed manuals for typography and color schemes.",
      },
      {
        icon: FaLightbulb,
        title: "Innovative Concepts",
        description: "Creative visuals and slogans that impress.",
      },
    ],
    videoUrl:
      "https://res.cloudinary.com/dhjdz1kjb/video/upload/v1735044488/branding_q8aqvu.mp4",
  },
  "Web Design and Development": {
    heading: "Web Design and Development",
    description:
      "Develop high-performing websites that captivate visitors and convert them into customers. We utilize the latest technologies and user-centric designs to deliver fast, secure, and scalable websites.",
    features: [
      {
        icon: FaRocket,
        title: "Fast Performance",
        description: "Optimized loading times for a seamless experience.",
      },
      {
        icon: FaLock,
        title: "Robust Security",
        description: "Advanced measures to protect your website and data.",
      },
      {
        icon: FaCogs,
        title: "Intuitive CMS",
        description: "Easy content management with user-friendly tools.",
      },
      {
        icon: FaSearch,
        title: "SEO Optimization",
        description: "Designed to rank high and drive organic traffic.",
      },
    ],
    videoUrl:
      "https://res.cloudinary.com/dhjdz1kjb/video/upload/v1735045218/webdev_xrsg8n.mp4",
  },
  "Social Media Management": {
    heading: "Social Media Management",
    description:
      "Establish a strong social media presence with engaging content and strategic campaigns. We help you connect with your audience and build lasting relationships through effective social media management.",
    features: [
      {
        icon: FaPenNib,
        title: "Content Creation",
        description: "High-quality visuals and posts aligned with your brand.",
      },
      {
        icon: FaUsers,
        title: "Community Engagement",
        description: "Foster trust and loyalty through active interaction.",
      },
      {
        icon: FaChartLine,
        title: "Analytics Insights",
        description: "Measure performance to refine your strategies.",
      },
      {
        icon: FaRegFileAlt,
        title: "Cross-Platform Management",
        description: "Optimize presence across all major platforms.",
      },
    ],
    videoUrl:
      "https://res.cloudinary.com/dhjdz1kjb/video/upload/v1735046032/socialmedia_p759mr.mp4",
  },
  "Paid Media Campaigns": {
    heading: "Paid Media Campaigns",
    description:
      "Drive targeted traffic and maximize ROI with our data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently.",
    features: [
      {
        icon: FaBullseye,
        title: "Precision Targeting",
        description: "Reach your ideal audience with advanced methods.",
      },
      {
        icon: FaMoneyBillWave,
        title: "Cost Optimization",
        description: "Maximize effectiveness while minimizing spend.",
      },
      {
        icon: FaChartPie,
        title: "Comprehensive Tracking",
        description: "Monitor metrics to enhance performance.",
      },
      {
        icon: FaAd,
        title: "Creative Designs",
        description: "Engaging visuals and messaging that convert.",
      },
    ],
    videoUrl:
      "https://res.cloudinary.com/dhjdz1kjb/video/upload/v1735047235/media_w9hwkr.mp4",
  },
  "UI/UX Design": {
    heading: "UI/UX Design",
    description:
      "Design intuitive and visually appealing interfaces that enhance user experiences. We focus on user-centric principles to ensure every interaction is meaningful and enjoyable.",
    features: [
      {
        icon: FaLightbulb,
        title: "User Research",
        description: "Understand behaviors to inform design decisions.",
      },
      {
        icon: FaDraftingCompass,
        title: "Wireframing",
        description: "Visualize ideas with detailed wireframes and prototypes.",
      },
      {
        icon: FaMobileAlt,
        title: "Responsive Design",
        description: "Ensure usability across all devices.",
      },
      {
        icon: FaStar,
        title: "Aesthetic Excellence",
        description: "Combine functionality with stunning visuals.",
      },
    ],
    videoUrl:
      "https://res.cloudinary.com/dhjdz1kjb/video/upload/v1735047702/uiux_2_mlk8tr.mp4",
  },
  "Digital Marketing": {
    heading: "Digital Marketing",
    description:
      "Implement integrated marketing strategies tailored to your business goals. We focus on boosting your visibility and driving measurable results through comprehensive digital marketing efforts.",
    features: [
      {
        icon: FaSearch,
        title: "SEO",
        description: "Improve rankings and attract organic traffic.",
      },
      {
        icon: FaRegNewspaper,
        title: "Content Marketing",
        description: "Create valuable content that engages your audience.",
      },
      {
        icon: FaAd,
        title: "PPC Advertising",
        description: "Drive immediate traffic with strategic ads.",
      },
      {
        icon: FaBullhorn,
        title: "Email Marketing",
        description: "Deliver personalized messages to build loyalty.",
      },
    ],
    videoUrl:
      "https://res.cloudinary.com/dhjdz1kjb/video/upload/v1735047878/digitalMarketing_gclfw1.mp4",
  },
  "E-commerce Solutions": {
    heading: "E-commerce Solutions",
    description:
      "Enhance your online store with our comprehensive e-commerce solutions. We provide custom storefront designs and advanced tools to ensure your business thrives in the competitive online market.",
    features: [
      {
        icon: FaStore,
        title: "Custom Storefronts",
        description: "Tailored designs that reflect your brand and products.",
      },
      {
        icon: FaBoxes,
        title: "Inventory Management",
        description: "Streamline operations with smart tools.",
      },
      {
        icon: FaStar,
        title: "Product Optimization",
        description: "Enhance listings for better visibility and sales.",
      },
      {
        icon: FaRocket,
        title: "Scalable Solutions",
        description: "Prepare your store for future growth.",
      },
    ],
    videoUrl:
      "https://res.cloudinary.com/dhjdz1kjb/video/upload/v1735048566/ecommerce_qe7exs.mp4",
  },
  "Video Ad Production": {
    heading: "Video Ad Production",
    description:
      "Create impactful video advertisements that tell your brand’s story. From concept to final edit, we produce high-quality videos designed to engage and convert your audience.",
    features: [
      {
        icon: FaCut,
        title: "Concept Development",
        description: "Generate compelling ideas that align with your brand.",
      },
      {
        icon: FaRegFileAlt,
        title: "Scriptwriting",
        description: "Develop scripts that effectively communicate your message.",
      },
      {
        icon: FaEdit,
        title: "Professional Editing",
        description: "Deliver polished and engaging videos.",
      },
      {
        icon: FaAd,
        title: "Targeted Distribution",
        description: "Reach the right audience with strategic placements.",
      },
    ],
    videoUrl:
      "https://res.cloudinary.com/dhjdz1kjb/video/upload/v1735049275/videoad_2_j902t5.mp4",
  },
  "Search Engine Optimization (SEO)": { // Added SEO Service
    heading: "Search Engine Optimization (SEO)",
    description:
      "Enhance your website's visibility and attract more organic traffic with our expert SEO services. We implement proven strategies to improve your search engine rankings and drive sustainable growth.",
    features: [
      {
        icon: FaSearch,
        title: "Keyword Research",
        description: "Identify and target the most effective keywords for your business.",
      },
      {
        icon: FaRegFileAlt,
        title: "On-Page Optimization",
        description: "Optimize content and HTML elements for better search engine performance.",
      },
      {
        icon: FaCogs,
        title: "Technical SEO",
        description: "Improve website structure, speed, and mobile-friendliness.",
      },
      {
        icon: FaChartLine,
        title: "Analytics Tracking",
        description: "Monitor and analyze SEO performance to refine strategies.",
      },
    ],
    videoUrl:
      "https://res.cloudinary.com/dhjdz1kjb/video/upload/v1734812944/seo_aqexni.mp4",
  },
};

// Define the default content when no service is selected
const defaultContent = {
  heading: "Welcome to MilaajBrandset",
  description:
    "At MilaajBrandset, we are dedicated to providing top-notch digital solutions tailored to your business needs. Whether you're looking to enhance your online presence, engage your audience, or drive sales, our expert team is here to help you succeed.",
  features: [
    {
      icon: FaPaintBrush,
      title: "Comprehensive Digital Strategies",
      description: "Develop holistic approaches to digital marketing and branding.",
    },
    {
      icon: FaCogs,
      title: "Innovative Web Solutions",
      description: "Create cutting-edge websites that perform and impress.",
    },
    {
      icon: FaBullhorn,
      title: "Effective Marketing Campaigns",
      description: "Launch campaigns that generate real, measurable results.",
    },
    {
      icon: FaUsers,
      title: "User-Centric Design",
      description: "Design interfaces focused on user experience and engagement.",
    },
    {
      icon: FaLock,
      title: "Reliable Support and Maintenance",
      description: "Ensure your digital assets are always up-to-date and secure.",
    },
  ],
  videoUrl:
    "https://res.cloudinary.com/dhjdz1kjb/video/upload/v1735042819/home_e7ksz3.mp4",
};

const TwoColumnList = React.forwardRef(({ selectedService, features, isBlack, black }, ref) => {
  const { setIsActive } = useContext(CursorContext);

  // Determine which content to display based on whether a service is selected
  const isServiceSelected =
    selectedService && servicesFeatures[selectedService.title];
  const content = isServiceSelected
    ? servicesFeatures[selectedService.title]
    : defaultContent;

  // Create a ref for the video element
  const videoRef = useRef(null);

  // State to track if video is playing

  // Handle video play on hover
  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to play the video:", error);
      });
      setIsActive(true); // Activate custom cursor
    }
  };



  // Handle video toggle on click (for mobile)
  const handleTogglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play().catch((error) => {
          console.error("Error attempting to play the video:", error);
        });
        setIsActive(true);
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        setIsActive(false);
      }
    }
  };

  return (
    <Container ref={ref} features={features}>
      <TwoColumn>
        {/* Left Content */}
        <TextColumn>
          <div tw="mb-6">
            <SubheadingWrapper>
              <Line />
              <Text>{isServiceSelected ? content.heading : "Welcome"}</Text>
              <Line />
            </SubheadingWrapper>
            <Heading black={black}>
              <Span tw="text-red-500">
                {isServiceSelected ? content.heading : "MilaajBrandset"}
              </Span>{" "}
              {isServiceSelected
                ? "Built to Elevate You"
                : "Built to Empower Your Business"}
            </Heading>
            <Description isBlack={isBlack}>{content.description}</Description>
          </div>

          {/* Features List */}
          <List>
            {content.features &&
              content.features.map((feature, index) => (
                <ListItem isBlack={isBlack} key={index}>
                  <feature.icon size={40} /> {/* Adjust size as needed */}
                  <div>
                    <FeatureTitle isBlack={isBlack}>{feature.title}:</FeatureTitle>
                    <FeatureDescription isBlack={isBlack}>
                      {feature.description}
                    </FeatureDescription>
                  </div>
                </ListItem>
              ))}
          </List>

          {/* Call to Action Section */}
          <CallToActionWrapper isBlack={isBlack}>
            <CallToActionText>
              Ready to <Span tw="text-white font-bold">elevate</Span> your business?
            </CallToActionText>
            <CallToActionButton
              href="https://wa.me/971505225056"
              target="_blank"
              rel="noopener noreferrer"
            >
              Let’s Chat
            </CallToActionButton>
          </CallToActionWrapper>
        </TextColumn>

        {/* Right Content - Embedded Video */}
        <VideoColumn>
          <VideoWrapper
            features={features}
            onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            onClick={handleTogglePlay} // Add onClick handler
          >
            <video
              ref={videoRef}
              src={content.videoUrl}
              autoPlay={true} // Play is controlled manually
              loop={true}
              muted={true} // Mute the video to comply with autoplay policies
              playsInline
              preload="auto" // Changed to "auto" for better loading
              aria-label={
                isServiceSelected
                  ? `${content.heading} Video`
                  : "Welcome Video"
              }
            >
              Your browser does not support the video tag.
            </video>
          </VideoWrapper>
        </VideoColumn>
      </TwoColumn>
    </Container>
  );
});

// Export the component with React.forwardRef
export default TwoColumnList;
