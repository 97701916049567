import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPenNib,
  FaVideo,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Content Marketing Agency Page Component
export const ContentMarketingAgencyInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Content Marketing Agency in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Strategic Content Partner in DIFC</Subheading>}
        heading={
          <Heading>
            Elevate Your Brand with a{" "}
            <span tw="text-primary-500">Content Marketing Agency in DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in crafting compelling content strategies that drive engagement and growth. Whether it’s blog writing, video production, or social media campaigns, we help businesses in DIFC tell their stories effectively.
          </Description>
        }
        imageSrcs={[
            "https://media.istockphoto.com/id/506610728/photo/blog-weblog-media-digital-dictionary-online-concept.webp?a=1&b=1&s=612x612&w=0&k=20&c=ilt_0ph4UnF49IaEPwVI3tm111BrFNN1mLRWR4tATSE=", // Content team
            "https://images.unsplash.com/photo-1617899516937-54fb61f7d3d2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDB8fGNvbnRlbnQlMjBjcmVhdG9yfGVufDB8fDB8fHww", // Dubai skyline
            "https://images.unsplash.com/photo-1581464647113-0196a7dc232b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y29udGVudCUyMGNyZWF0b3J8ZW58MHx8MHx8fDA%3D", // Team working
          ]}
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle="height: 400px;"
          imageStyle="width: 45%; height: 60%;"
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Services Section */}
      <Features
        subheading={<Subheading>Our Expertise</Subheading>}
        heading="Tailored Content Marketing Solutions for DIFC Businesses"
        description="We offer a wide range of services designed to elevate your brand's presence and engagement:"
        cards={[
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Engaging Blog Writing",
            description:
              "Create thought leadership content that educates and inspires your audience.",
          },
          {
            icon: <FaVideo color="#ff5464" size={40} />,
            title: "Professional Video Production",
            description:
              "Produce high-quality videos that captivate and convert your audience.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Campaigns",
            description:
              "Optimize your content strategy with actionable insights and analytics.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Social Media Storytelling",
            description:
              "Craft shareable content that resonates with your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Workshops",
            description:
              "Engage in brainstorming sessions to align your vision with actionable plans.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Creative Copywriting",
            description:
              "Write persuasive copy that drives action and builds trust.",
          },
        ]}
      />

     

      {/* Why Choose Us Section */}
      <MainFeature1
        subheading={<Subheading>Why Work With Us?</Subheading>}
        heading="Your Trusted Content Marketing Experts in DIFC"
        description={
          <Description>
            Here’s why businesses in DIFC choose MilaajBrandset as their content marketing partner:
            <ul tw="mt-4 list-disc pl-6">
              <li>Proven expertise in delivering measurable results.</li>
              <li>A client-centric approach tailored to your goals.</li>
              <li>Innovative strategies that set your brand apart.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://plus.unsplash.com/premium_photo-1663931932651-ea743c9a0144?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Y2hhcnR8ZW58MHx8MHx8fDA%3D", // Content team
            "https://plus.unsplash.com/premium_photo-1684179641331-e89c6320b6a9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8bWFya2V0aW5nfGVufDB8fDB8fHww", // Dubai skyline
            "https://media.istockphoto.com/id/980243748/photo/successful-business-colleagues-giving-high-five-in-office.webp?a=1&s=612x612&w=0&k=20&c=MPYUUy0fVYLBeNVAvLxeHZPVdFhN-Z_kDhxuIugmfIU=", // Team working
          ]}
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle="height: 400px;"
          imageStyle="width: 45%; height: 60%;"
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={true}
      />
    </AnimationRevealPage>
  );
};

