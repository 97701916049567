import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaLaptopCode,
  FaMobileAlt,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Website Design and Development Page Component
export const WebsiteDesignAndDevelopmentInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Website Design and Development in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Web Partner in DIFC</Subheading>}
        heading={
          <Heading>
            Professional Website Design and Development in{" "}
            <span tw="text-primary-500">DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in creating stunning, high-performance websites tailored to businesses in DIFC. From responsive design to custom development, we ensure your website stands out and drives results.
          </Description>
        }
        imageSrcs={[
          "https://plus.unsplash.com/premium_photo-1661389360947-a4d0f67b2941?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHdlYiUyMGRlc2lnbnxlbnwwfHwwfHx8MA%3D%3D", // Web development team
          "https://images.unsplash.com/photo-1547658719-da2b51169166?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdlYiUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww", // Dubai skyline
          "https://img.freepik.com/free-photo/web-design-technology-browsing-programming-concept_53876-163260.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_incoming", // Team working
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
      />

      {/* Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Website Design and Development Solutions"
        description="Explore our professional web services designed to help your business thrive in DIFC:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Custom Website Design",
            description:
              "Create a unique and visually appealing website tailored to your brand.",
          },
          {
            icon: <FaMobileAlt color="#ff5464" size={40} />,
            title: "Responsive Development",
            description:
              "Ensure your website looks great and functions flawlessly on all devices.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Optimization",
            description:
              "Boost your website's speed and search engine rankings with advanced optimization.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "User Experience (UX) Design",
            description:
              "Design intuitive interfaces that engage and convert your audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Process",
            description:
              "Work closely with our team to bring your vision to life.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Post-Launch Support",
            description:
              "Receive ongoing maintenance and updates to keep your website running smoothly.",
          },
        ]}
      />

      {/* Success Stories Section */}
      <MainFeature1
        subheading={<Subheading>Our Impact</Subheading>}
        heading={<Heading>Website Success Stories in DIFC</Heading>}
        description={
          <Description>
            <strong>E-commerce Platform:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Built a fully functional e-commerce website for a retail client in DIFC.</li>
              <li>Increased online sales by 60% within six months.</li>
              <li>Achieved measurable results with a user-friendly interface.</li>
            </ul>
            <strong tw="mt-8 block">Corporate Website Redesign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Revamped a corporate website to improve usability and aesthetics.</li>
              <li>Boosted organic traffic by 45% through SEO optimization.</li>
              <li>Enhanced brand credibility and trust among users.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://plus.unsplash.com/premium_photo-1690391719468-ad77d3fbdd41?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTd8fHdlYiUyMGRlc2lnbnxlbnwwfHwwfHx8MA%3D%3D", // Web development team
          "https://images.unsplash.com/photo-1534670007418-fbb7f6cf32c3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTJ8fHdlYiUyMGRlc2lnbnxlbnwwfHwwfHx8MA%3D%3D", // Dubai skyline
          "https://images.unsplash.com/photo-1609921212029-bb5a28e60960?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTJ8fHdlYiUyMGRlc2lnbnxlbnwwfHwwfHx8MA%3D%3D" // Team working
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Discover More Success Stories"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Web Experts in DIFC"
        description="Here’s why businesses in DIFC trust us as their website design and development partner:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "We have years of experience building high-performing websites for diverse industries.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Approach",
            description:
              "Our strategies are backed by analytics to ensure maximum ROI.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Focus",
            description:
              "Your goals and vision are at the core of everything we do in DIFC.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

