import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaPenNib,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaSearchDollar,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Affordable Content Marketing Page Component
export const AffordableContentMarketingInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Affordable Content Marketing Companies in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Content Marketing Partner in Al Barsha</Subheading>}
        heading={
          <Heading>
            Affordable Content Marketing Companies in{" "}
            <span tw="text-primary-500">Al Barsha</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are one of the most affordable content marketing companies in Al Barsha, offering high-quality content solutions tailored to your business needs. From blog writing to video production, we help you engage your audience and drive results.
          </Description>
        }
        
        imageSrcs={[
            "https://plus.unsplash.com/premium_photo-1661666522635-bfda55b4af2b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Content team
            "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fG1hcmtldGluZ3xlbnwwfHwwfHx8MA%3D%3D", // Dubai skyline
            "https://images.unsplash.com/photo-1562577309-4932fdd64cd1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjB8fGNvbnRlbnQlMjBtYXJrZXRpbmd8ZW58MHx8MHx8fDA%3D", // Team working
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
      />

      {/* Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Content Marketing Solutions"
        description="Explore our range of affordable content marketing services designed to help your business thrive in Al Barsha:"
        cards={[
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Blog Writing",
            description:
              "Create engaging and informative blog posts that attract and educate your audience.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Tracking",
            description:
              "Monitor the impact of your content with detailed analytics and reports.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Social Media Content",
            description:
              "Develop creative and shareable content for platforms like Instagram, Facebook, and Twitter.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Strategy",
            description:
              "Work closely with our team to align your vision with actionable content plans.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Video Production",
            description:
              "Produce high-quality videos that captivate your audience and boost engagement.",
          },
          {
            icon: <FaSearchDollar color="#ff5464" size={40} />,
            title: "Cost-Effective Campaigns",
            description:
              "Maximize ROI with budget-friendly content strategies tailored to your needs.",
          },
        ]}
      />

      {/* Success Stories Section */}
      <MainFeature1
        subheading={<Subheading>Our Impact</Subheading>}
        heading={<Heading>Success Stories in Al Barsha</Heading>}
        description={
          <Description>
            <strong>Local Retailer Growth:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Increased website traffic by 80% through consistent blog content.</li>
              <li>Improved customer engagement with targeted social media posts.</li>
              <li>Achieved measurable results within a limited budget.</li>
            </ul>
            <strong tw="mt-8 block">Startup Brand Awareness:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Built a strong online presence for a new startup in Al Barsha.</li>
              <li>Grew email subscribers by 50% with engaging newsletters.</li>
              <li>Generated leads at a low cost-per-acquisition rate.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://plus.unsplash.com/premium_photo-1661762833325-9b7e1c0b4abf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjl8fGNvbnRlbnQlMjBtYXJrZXRpbmd8ZW58MHx8MHx8fDA%3D", // Content team
            "https://plus.unsplash.com/premium_photo-1683880731020-83b984105a72?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y29udGVudCUyMHdyaXRpbmd8ZW58MHx8MHx8fDA%3D", // Dubai skyline
            "https://images.unsplash.com/photo-1613579917953-d35e6b72d32b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGNvbnRlbnQlMjBtYXJrZXRpbmd8ZW58MHx8MHx8fDA%3D", // Team working
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Discover More Success Stories"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Affordable Content Marketing Experts in Al Barsha"
        description="Here’s why businesses in Al Barsha trust us as their content marketing partner:"
        cards={[
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "We bring fresh, innovative ideas to every content project in Al Barsha.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "Our track record speaks for itself—delivering impactful content solutions in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the core of everything we do in Al Barsha.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

