import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaGoogle,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaLightbulb,
  FaWrench,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const GoogleAdsManagementDifc = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Google Ads Management in DIFC" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Google Ads Partner in DIFC</Subheading>}
        heading={
          <Heading>
            Google Ads Management in{" "}
            <span tw="text-primary-500">DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are a Google Ads management agency in DIFC,
            dedicated to helping businesses grow and succeed online. From
            campaign setup to ongoing optimization, we provide expert Google Ads
            management services tailored to your specific needs in DIFC.
          </Description>
        }
        imageSrcs={[
          "https://t4.ftcdn.net/jpg/04/01/35/09/240_F_401350970_t84xuYbwO2zJC0JmYdaFOo513UqrkwAT.jpg", // Modern office with laptops
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://media.istockphoto.com/id/2098359215/photo/digital-marketing-concept-businessman-using-laptop-with-ads-dashboard-digital-marketing.jpg?s=612x612&w=0&k=20&c=OdQP1rq-YcNN2nIuC8slL1BJKEwdYb7rT5mqTUNSTZQ=", // Team working on a project
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Google Ads Campaign"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Expert Google Ads Management Services"
        description="We offer a full range of Google Ads management services to help your business thrive online in DIFC:"
        cards={[
          {
            icon: <FaGoogle color="#ff5464" size={40} />,
            title: "Campaign Setup",
            description:
              "Setting up and launching effective Google Ads campaigns in DIFC.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Monitoring",
            description:
              "Monitoring and analyzing campaign performance to optimize results in DIFC.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Research",
            description:
              "Conducting thorough audience research to target the right customers in DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Process",
            description:
              "Working closely with you to understand your business goals in DIFC.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Ad Copywriting",
            description:
              "Creating compelling ad copy to drive clicks and conversions in DIFC.",
          },
          {
            icon: <FaWrench color="#ff5464" size={40} />,
            title: "Continuous Optimization",
            description:
              "Regularly optimizing campaigns to ensure maximum ROI in DIFC.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Google Ads Management Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Google Ads Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Launched a successful Google Ads campaign for an e-commerce business in DIFC</li>
              <li>Increased website traffic by 70%</li>
              <li>Boosted sales by 50%</li>
            </ul>
            <strong tw="mt-8 block">Corporate Google Ads Strategy:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Developed a comprehensive Google Ads strategy for a corporate business in DIFC</li>
              <li>Enhanced brand visibility and engagement in DIFC</li>
              <li>Generated 40% more leads</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://t4.ftcdn.net/jpg/05/50/93/81/240_F_550938118_La5RxWX5GPERKO7F9lpR3nuhJWumgLOP.jpg", // Server room
          "https://images.unsplash.com/photo-1552960394-c81add8de6b8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzV8fGNsaWVudHxlbnwwfHwwfHx8MA%3D%3D", // Dubai skyline
          "https://t3.ftcdn.net/jpg/02/13/96/54/240_F_213965477_OaezCPYWjMdoN2JIq5MrtZuKuDsW3mKH.jpg", // Data center
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Google Ads Management Experts in DIFC"
        description="Here’s why businesses in DIFC trust us for their Google Ads management needs:"
        cards={[
          {
            icon: <FaGoogle color="#ff5464" size={40} />,
            title: "Expert Knowledge",
            description:
              "Our team has extensive experience in Google Ads management and understands the latest trends in DIFC.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We deliver measurable improvements in campaign performance and ROI in DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description: "Your goals and success are our top priority in DIFC.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

