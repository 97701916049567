import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaLaptopCode,
  FaCode,
  FaServer,
  FaUsers,
  FaHandshake,
  FaBullseye,
  FaChartLine,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const CustomWebsiteDevelopmentAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Custom Website Development in Al Barsha" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Custom Website Partner in Al Barsha</Subheading>}
        heading={
          <Heading>
            Custom Website Development in{" "}
            <span tw="text-primary-500">Al Barsha</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in custom website development in Al Barsha
            to create unique and tailored digital experiences for your business.
            From e-commerce platforms to corporate websites, we ensure your online
            presence stands out and drives growth in Al Barsha.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1515955656352-a1fa388f9d9d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Modern office with laptops
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.unsplash.com/photo-1526374965328-7f67d2d33d8d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Team working on a project
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Custom Website Project"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Tailored Website Development Solutions"
        description="We offer a full range of custom website development services to help your business thrive online in Al Barsha:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Custom Website Design",
            description:
              "Creating unique and visually appealing designs tailored for your business in Al Barsha.",
          },
          {
            icon: <FaCode color="#ff5464" size={40} />,
            title: "Responsive Web Design",
            description:
              "Ensuring your website looks great on all devices in Al Barsha.",
          },
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "Backend Development",
            description:
              "Building robust backend systems to support your website's functionality in Al Barsha.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "User Experience Design",
            description:
              "Designing intuitive and user-friendly interfaces for your website in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Project Management",
            description:
              "Managing your project from start to finish to ensure timely delivery in Al Barsha.",
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "SEO Integration",
            description:
              "Incorporating SEO best practices to improve your website's search engine rankings in Al Barsha.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Custom Website Development Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Platform Development:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Developed a user-friendly e-commerce platform in Al Barsha</li>
              <li>Increased sales by 50% within the first year</li>
              <li>Received positive feedback from customers in Al Barsha</li>
            </ul>
            <strong tw="mt-8 block">Corporate Website Development:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Designed a professional and engaging corporate website in Al Barsha</li>
              <li>Enhanced brand visibility and engagement in Al Barsha</li>
              <li>Boosted lead generation by 40%</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1547658719-da2b51169166?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdlYiUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww", // Server room
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8d2ViJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D", // Data center
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Custom Website Development Experts in Al Barsha"
        description="Here’s why businesses in Al Barsha trust us for their custom website development needs:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Tailored Solutions",
            description:
              "We provide custom solutions that perfectly fit your business needs in Al Barsha.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We have a track record of delivering successful custom website projects in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description: "Your vision and goals are at the heart of everything we do in Al Barsha.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
