import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaBullhorn,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaLightbulb,
  FaGlobe,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const MarketingCompanyAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Marketing Company in Al Barsha" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Marketing Partner in Al Barsha</Subheading>
        }
        heading={
          <Heading>
            Marketing Company in <span tw="text-primary-500">Al Barsha</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are a marketing company in Al Barsha dedicated
            to helping businesses grow and succeed. From digital marketing to
            traditional advertising, we provide comprehensive marketing
            solutions tailored to your specific needs in Al Barsha.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/7710082/pexels-photo-7710082.jpeg?auto=compress&cs=tinysrgb&w=600", // Dubai skyline
          "https://images.pexels.com/photos/6476808/pexels-photo-6476808.jpeg?auto=compress&cs=tinysrgb&w=600", // Team working on a project
          "https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=600", // Modern office with laptops
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["65%", "70%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Marketing Campaign"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Marketing Solutions"
        description="We offer a full range of marketing services to help your business thrive in Al Barsha:"
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Digital Marketing",
            description:
              "Strategic digital marketing campaigns to reach your target audience in Al Barsha.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Market Research",
            description:
              "In-depth market research to understand your audience and competition in Al Barsha.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Social Media Marketing",
            description:
              "Effective social media strategies to engage and grow your brand in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Content Marketing",
            description:
              "High-quality content creation to drive traffic and conversions in Al Barsha.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Branding & Identity",
            description:
              "Strong branding and identity development to establish your brand in Al Barsha.",
          },
          {
            icon: <FaGlobe color="#ff5464" size={40} />,
            title: "Traditional Advertising",
            description:
              "Effective traditional advertising solutions to complement your digital efforts in Al Barsha.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Marketing Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Marketing Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Lunched a successful digital marketing campaign for an
                e-commerce business in Al Barsha
              </li>
              <li>Increased website traffic by 50%</li>
              <li>Boosted sales by 30%</li>
            </ul>
            <strong tw="mt-8 block">Corporate Marketing Strategy:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Developed a comprehensive marketing strategy for a corporate
                business in Al Barsha
              </li>
              <li>Enhanced brand awareness by 40%</li>
              <li>Generated 25% more leads</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/3194519/pexels-photo-3194519.jpeg?auto=compress&cs=tinysrgb&w=600", // Dubai skyline
          "https://images.pexels.com/photos/590045/pexels-photo-590045.jpeg?auto=compress&cs=tinysrgb&w=600", // Team working on a project
          "https://images.pexels.com/photos/6229/marketing-board-strategy.jpg?auto=compress&cs=tinysrgb&w=600", // Modern office with laptops
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Marketing Experts in Al Barsha"
        description="Here’s why businesses in Al Barsha trust us for their marketing needs:"
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Local Expertise",
            description:
              "We understand the unique marketing challenges and opportunities in Al Barsha.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We deliver measurable improvements in brand awareness and sales in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the heart of everything we do in Al Barsha.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
