import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaServer,
  FaCloud,
  FaShieldAlt,
  FaUsers,
  FaHandshake,
  FaGlobe,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const WebHostingSolutionsBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Web Hosting Solutions in Bur Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Reliable Web Hosting Partner in Bur Dubai</Subheading>}
        heading={
          <Heading>
            Reliable Web Hosting Solutions in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we offer reliable and scalable web hosting solutions
            in Bur Dubai to ensure your website performs optimally. From shared
            hosting to dedicated servers, we provide the best hosting options to
            meet your business needs.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress&cs=tinysrgb&w=600", // Server room
          "https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg?ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Programmer working
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Choose Your Hosting Plan"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Web Hosting Solutions"
        description="We offer a variety of web hosting services to help your business thrive online in Bur Dubai:"
        cards={[
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "Shared Hosting",
            description:
              "Cost-effective hosting solution suitable for small to medium-sized websites in Bur Dubai.",
          },
          {
            icon: <FaCloud color="#ff5464" size={40} />,
            title: "Cloud Hosting",
            description:
              "Scalable and flexible hosting solution for growing businesses in Bur Dubai.",
          },
          {
            icon: <FaShieldAlt color="#ff5464" size={40} />,
            title: "Security Features",
            description:
              "Advanced security measures to protect your website from cyber threats in Bur Dubai.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Customer Support",
            description:
              "24/7 customer support to assist you with any hosting-related issues in Bur Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Custom Solutions",
            description:
              "Tailored hosting solutions to fit the unique needs of your business in Bur Dubai.",
          },
          {
            icon: <FaGlobe color="#ff5464" size={40} />,
            title: "Global Reach",
            description:
              "High-performance hosting with global server locations to ensure fast load times in Bur Dubai.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Web Hosting Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Website Hosting:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Provided reliable hosting for an e-commerce platform in Bur Dubai</li>
              <li>Ensured 99.9% uptime and fast load times</li>
              <li>Supported during peak traffic periods</li>
            </ul>
            <strong tw="mt-8 block">Corporate Website Hosting:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Offered scalable cloud hosting for a corporate website in Bur Dubai</li>
              <li>Enhanced website performance and user experience</li>
              <li>Provided 24/7 support and regular backups</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1547658719-da2b51169166?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdlYiUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww", // Server room
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8d2ViJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D", // Data center
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Web Hosting Experts in Bur Dubai"
        description="Here’s why businesses in Bur Dubai trust us for their web hosting needs:"
        cards={[
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "Reliability",
            description:
              "We provide highly reliable hosting services to ensure your website is always up and running in Bur Dubai.",
          },
          {
            icon: <FaShieldAlt color="#ff5464" size={40} />,
            title: "Security",
            description:
              "Our advanced security features protect your website from cyber threats in Bur Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Customer Support",
            description: "24/7 customer support to assist you with any hosting-related issues in Bur Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

