import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaLaptopCode,
  FaServer,
  FaUsers,
  FaHandshake,
  FaChartLine,
  FaWrench,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const WebManagementServicesDifc = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Web Management Services in DIFC" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Web Management Partner in DIFC</Subheading>
        }
        heading={
          <Heading>
            Reliable Web Management Services in{" "}
            <span tw="text-primary-500">DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we offer comprehensive web management services in
            DIFC to ensure your website runs smoothly and effectively. From
            regular updates to technical support, we help maintain your online
            presence and drive growth in DIFC.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress&cs=tinysrgb&w=600",
          "https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg?ga=GA1.1.1964859193.1725122772&semt=ais_hybrid",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Web Management Journey"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Web Management Solutions"
        description="We offer a full range of web management services to help your business thrive online in DIFC:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Website Maintenance",
            description:
              "Regular updates and maintenance to keep your website running smoothly in DIFC.",
          },
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "Technical Support",
            description:
              "24/7 technical support to resolve any issues quickly in DIFC.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Content Management",
            description:
              "Managing and updating your website content to keep it fresh and engaging in DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "SEO Integration",
            description:
              "Incorporating SEO best practices to improve your website's search engine rankings in DIFC.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Monitoring",
            description:
              "Monitoring website performance and providing regular reports in DIFC.",
          },
          {
            icon: <FaWrench color="#ff5464" size={40} />,
            title: "Security Enhancements",
            description:
              "Enhancing website security to protect your business in DIFC.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Web Management Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Platform Management:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Maintained a user-friendly e-commerce platform in DIFC</li>
              <li>
                Ensured smooth transactions and high customer satisfaction
              </li>
              <li>Reduced downtime by 80%</li>
            </ul>
            <strong tw="mt-8 block">Corporate Website Management:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Managed a corporate website to improve user experience</li>
              <li>Enhanced brand visibility and engagement in DIFC</li>
              <li>Boosted lead generation by 35%</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1547658719-da2b51169166?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdlYiUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww",
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8d2ViJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The DIFC Web Management Experts"
        description="Here’s why businesses in DIFC trust us for their web management needs:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Expert Knowledge",
            description:
              "Our team has extensive experience in web management and understands the latest trends in DIFC.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We have a track record of delivering reliable web management services in DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the heart of everything we do in DIFC.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
