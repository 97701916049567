// src/components/Features.jsx
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { gsap } from "gsap";
import { SectionHeading, SubheadingPara } from "Components/misc/Headings";
import ArrowRightIcon from "images/arrow-right-2-icon.avif"; // Update with your actual path

import defaultCardImage from "../../images/shield-icon.svg";
import DigitalMarketingImage from "images/DigitalMarketingIcon.png";
import BrandIdentityImage from "images/BrandIdentityIcon.png";
import WebDesignImage from "images/WebDevelopmentIcon.png";
import SeoImage from "images/SeoIcon.png";
import ECommerceImage from "images/EcommerceIcon.png";
import UiUxImage from "images/UIUXicon.png";
import SocialMediaImage from "images/SocialMediaManagementIcon.png";
import PaidMediaImage from "images/PaidMediaCampaign.png";
import VideoAdImage from "images/VideoAdProductionIcon.png";

// Styled Components
const Container = styled.div`
  ${tw`relative`}

  overflow: hidden;

  &:before {
    content: "";
    ${tw`absolute inset-0 bg-black`}
    z-index: -2;
  }
`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center rounded-4xl bg-black shadow-cxl md:flex-row md:justify-center flex-wrap w-[90%] mx-auto py-10 md:py-24 font-poppins`}
  z-index: 1;
`;

export const Heading = tw(SectionHeading)`w-full text-white`;
const Text = tw(SubheadingPara)`w-full text-white`;

const Column = styled.div`
  ${tw`px-4 z-10 w-[90%] md:w-[550px] xl:w-[485px] lg:w-[450px]`}
`;

const ArrowIconContainer = styled.div`
  ${tw`absolute justify-end bg-black p-2 rounded-full flex bottom-[2rem] right-[2rem] cursor-pointer`}
  background-color: ${({ isBlack }) => (isBlack ? "#ff5464" : "white")};

  img {
    ${tw`w-6 h-6`}
    transform: rotate(0deg);
  }

  transition: transform 0.3s ease;
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto text-white items-center justify-center bg-customPurple py-3 px-2 lg:px-6 md:px-6 border-2 border-primary-500 rounded-3xl mt-12 shadow-cxl relative`}
  height: 390px;
  z-index: 2;

  @media (max-width: 768px) {
    height: 290px;
  }

  ${({ isBlack }) =>
    isBlack &&
    `
    background-color: #000;
    color: white;
    border: 2px solid #ff5464;

    .title {
      color: white;
    }

    .description {
      color: white;
    }
  `}

  .imageContainer {
    ${tw`flex-shrink-0`}
    @media (max-width: 768px) {
      ${tw`hidden`}
    }
  }

  .textContainer {
    ${tw`mt-2 text-center w-full py-2 px-4`}
  }

  .title {
    ${tw`mt-0 font-bold text-lg sm:text-xl leading-none`}
  }

  .description {
    ${tw`mt-3 font-medium leading-relaxed text-sm`}
  }

  &:hover ${ArrowIconContainer} {
    transform: scale(1.1);
  }
`;

export const Span = styled.span`
  ${tw`text-customPurple`}
`;

const servicesData = [
  {
    title: "Brand Identity Development",
    description:
      "Craft a unique brand identity that resonates with your audience and sets you apart from the competition.",
    imageSrc: BrandIdentityImage,
  },
  {
    title: "Web Design and Development",
    description:
      "Build stunning, user-friendly websites tailored to your business needs and goals.",
    imageSrc: WebDesignImage,
  },
  {
    title: "Social Media Management",
    description:
      "Grow your online presence with engaging content and strategic social media campaigns.",
    imageSrc: SocialMediaImage,
  },
  {
    title: "Paid Media Campaigns",
    description:
      "Drive traffic and conversions with data-driven ad campaigns across Google, Facebook, and more.",
    imageSrc: PaidMediaImage,
  },
  {
    title: "UI/UX Design",
    description:
      "Design seamless and visually appealing interfaces that enhance user experiences.",
    imageSrc: UiUxImage,
  },
  {
    title: "Digital Marketing",
    description:
      "Reach your target audience with integrated marketing strategies that deliver measurable results.",
    imageSrc: DigitalMarketingImage,
  },
  {
    title: "E-commerce Solutions",
    description:
      "Create powerful online stores that drive sales and provide an exceptional shopping experience.",
    imageSrc: ECommerceImage,
  },
  {
    title: "Search Engine Optimization (SEO)",
    description:
      "Boost your website’s visibility and rank higher on search engines to attract organic traffic.",
    imageSrc: SeoImage,
  },
  {
    title: "Video Ad Production",
    description:
      "Produce captivating video ads that tell your story and engage your audience effectively.",
    imageSrc: VideoAdImage,
  },
];

const Features = ({ onServiceClick, id }) => {
  const cardRefs = useRef([]);

  useEffect(() => {
    const handleMouseEnter = (card) => {
      gsap.to(card, {
        scale: 1.05,
        duration: 0.3,
        ease: "power1.out",
        boxShadow: "0 15px 30px rgba(0, 0, 0, 0.2)",
      });
    };

    const handleMouseLeave = (card) => {
      gsap.to(card, {
        scale: 1,
        duration: 0.3,
        ease: "power1.out",
        boxShadow: "0 15px 30px rgba(0, 0, 0, 0.1)",
      });
    };

    const currentCardRefs = cardRefs.current; // Copy cardRefs.current to a variable

    currentCardRefs.forEach((card) => {
      if (card) {
        card.addEventListener("mouseenter", () => handleMouseEnter(card));
        card.addEventListener("mouseleave", () => handleMouseLeave(card));
      }
    });

    return () => {
      currentCardRefs.forEach((card) => {
        if (card) {
          card.removeEventListener("mouseenter", () => handleMouseEnter(card));
          card.removeEventListener("mouseleave", () => handleMouseLeave(card));
        }
      });
    };
  }, []); // No dependencies needed

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          Our <Span>Services</Span>, Your <Span>Success</Span>
        </Heading>
        <Text>Empowering Your Brand with Expert Services</Text>

        {servicesData.map((service, i) => (
          <Column key={i}>
            <Card
              ref={(el) => (cardRefs.current[i] = el)}
              isBlack={i % 2 === 0}
              onClick={() => onServiceClick(service)}
              style={{ cursor: "pointer" }}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  onServiceClick(service);
                }
              }}
            >
              <span className="imageContainer">
                <img
                  src={service.imageSrc || defaultCardImage}
                  alt={`${service.title} Icon`}
                  className="hidden md:block"
                />
              </span>
              <span className="textContainer">
                <span className="title">{service.title}</span>
                <p className="description">{service.description}</p>
              </span>
              <ArrowIconContainer isBlack={i % 2 === 0}>
                <img src={ArrowRightIcon} alt="Arrow Icon" />
              </ArrowIconContainer>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};

export default Features;