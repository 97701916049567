import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "Components/misc/Headings";
import { SectionDescription } from "Components/misc/Typography";
import { Container, ContentWithPaddingXl } from "Components/misc/Layouts";
import ArrowRightIcon from "images/arrow-right-icon.avif"; // Ensure this path is correct
import { Link } from "react-router-dom";
import { Span } from "./DashedBorderSixFeatures";

const Heading = tw(SectionHeading)`text-black`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 border-2 border-black p-0 items-center md:mx-4 w-full mx-auto py-24 font-poppins`}
  grid-auto-rows: minmax(0, 1fr); 
`;

const Column = styled.div`
  ${tw`flex flex-col `} 
`;

const Card = styled.div`
  ${tw`flex flex-col 
    lg:py-12
    lg:px-8
    items-center 
    justify-between
    border-2 border-primary-500 
    rounded-4xl
    mt-4 
    p-0
    shadow-cxl relative sm:py-10 md:w-[80%] h-[300px]`} // Set a fixed height for the card

  .imageContainer {
    ${tw`flex-shrink-0 mb-10 mt-5`} /* Ensure the image doesn't shrink */
    img {
      ${tw`w-[138px] h-[122px]`} /* Set the desired image size */
    }
  }

  .textContainer {
    ${tw`mt-2 text-center w-[290px] no-underline `} // Set a fixed width for the text container
  }

  .title {
    ${tw`mt-0 font-bold text-xl leading-none text-black no-underline`}
    text-decoration: none !important;
  }

  .description {
    ${tw`mt-3 font-medium text-secondary-100 text-center leading-loose no-underline`}
  }

  &:hover {
    ${tw`transform scale-105`} /* Scale on hover */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2); /* Add shadow effect on hover */
    transition: all 0.3s ease; /* Smooth transition */
  }
  .link {
    ${tw`mt-4 flex items-center text-primary-500 font-semibold hover:text-secondary-100 transition duration-300`}
  }

  .link img.icon {
    ${tw`ml-2 w-4 h-4`}
  }
`;

const StyledLink = styled(Link)`
  ${tw`no-underline`}
`;

const SubheadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  text-center
  width: 100%;
  margin: 10px 0;
`;

const Line = styled.div`
  width:30px;
  height: 1px;
  width:50px;
  background-color: grey; // Adjust color as needed
`;

const Text = styled.h2`
  padding: 0 5px;
  color: #ff5464; // Adjust color as needed
  font-size: 20px; // Adjust size as needed
  white-space: nowrap;
`;


export default ({
  cards = [],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <SubheadingWrapper><Line /><Text>{subheading}</Text><Line /></SubheadingWrapper>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <StyledLink to={card.url}>
                <Card>
                  <span className="imageContainer" css={imageContainerCss}>
                    <img src={card.imageSrc} alt="" css={imageCss} />
                  </span>
                  <span className="title">{card.title}</span>
                  <p className="description">{card.description}</p>
                  {linkText && (
                    <Span className="link">
                      <Span>{linkText}</Span>
                      <img src={ArrowRightIcon} alt="arrow right" className="icon" />
                    </Span>
                  )}
                </Card>
              </StyledLink>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};