import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaBullhorn,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// DIFC Instagram and Facebook Ads Experts Page Component
export const DIFCInstagramFacebookAdsExperts = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="DIFC Instagram and Facebook Ads Experts" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Maximize Your ROI with Expert Ad Campaigns</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Brand with{" "}
            <span tw="text-primary-500">
              DIFC Instagram and Facebook Ads Experts
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in crafting high-performing
            Instagram and Facebook ad campaigns that drive traffic, generate
            leads, and boost conversions for businesses in DIFC. As a startup,
            we bring fresh ideas, data-driven strategies, and a client-centric
            approach to every project.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1491951931722-5a446214b4e2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZmFjZWJvb2slMjBtYXJrZXRpbmd8ZW58MHx8MHx8fDA%3D", // Social media dashboard
          "https://plus.unsplash.com/premium_photo-1684179641331-e89c6320b6a9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c29jaWFsJTIwbWVkaWElMjBtYXJrZXRpbmd8ZW58MHx8MHx8fDA%3D", // Team analyzing data
          "https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aW5zdGFncmFtJTIwbWFya2V0aW5nfGVufDB8fDB8fHww", // Writers working on laptops
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Instagram and Facebook Ads Experts"
        description="We combine innovation, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Targeted Campaigns",
            description:
              "Reach your ideal audience with precision targeting across platforms.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Backed by analytics to ensure maximum ROI and campaign performance.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Engagement",
            description:
              "Create ads that resonate with your audience and drive action.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience crafting successful Instagram and Facebook campaigns.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every campaign.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Ad Campaign Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>Campaign Strategy: Develop tailored plans for your goals.</li>
              <li>Ad Creation: Design engaging, high-converting ads.</li>
              <li>
                Performance Tracking: Monitor results and optimize campaigns.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://img.freepik.com/free-photo/social-media-marketing-concept-marketing-with-applications_23-2150063134.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Social media team
          "https://img.freepik.com/free-photo/hand-holding-smartphone-social-media-concept_23-2150208245.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Dubai skyline
          "https://img.freepik.com/free-photo/medium-shot-men-holding-smartphone_23-2150208243.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Team working
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how we’ve helped businesses grow with our Instagram and Facebook ad campaigns."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Startup Growth",
            description:
              "A local startup saw a 300% increase in leads within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a retail client’s sales by 50% with targeted ad campaigns.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Elevate Your Brand?</Subheading>}
        heading="Let’s Drive Results Together"
        description={
          <Description>
            Partner with our Instagram and Facebook ads experts to unlock the
            full potential of your campaigns. Whether you’re looking to boost
            your online presence or generate leads, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjB8fGNsaWVudHxlbnwwfHwwfHx8MA%3D%3D", // Happy team celebrating
        ]}
        widths={["70%"]}
        heights={["70%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={["left: 15%; top: 15%;"]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
