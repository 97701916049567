import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "Components/misc/Buttons.js";

const Container = tw.div`relative bg-white rounded-4xl p-8 py-16 mt-12 w-[85%] mx-auto mb-24 transition-all duration-300 ease-in-out`;
const StyledContainer = styled(Container)`
  min-height: 600px; /* Set a minimum height */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

const Heading = tw.h2`text-3xl font-bold mb-6 text-black text-left`;
const Form = tw.form`flex flex-col items-center space-y-6 mt-12 transition-all duration-300 ease-in-out w-full`;
const InputRow = styled.div`
  ${tw`w-full flex flex-col space-y-2 items-start`}
  @media (min-width: 1024px) {
    ${tw`flex-row space-y-0 space-x-4 items-start`}
  }
`;
const Input = styled.input`
  ${tw`h-16 border rounded-4xl pt-4 pr-6 pl-6 focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-300 w-full shadow-md`}
  box-sizing: border-box; /* Include padding and border in width calculation */
  border: 1px solid #e2e8f0; /* Light gray border for better aesthetics */
  background-color: #f9fafb; /* Slightly off-white background for contrast */
  &:focus {
    background-color: #ffffff; /* Make background pure white on focus */
  }
  &::placeholder {
    ${tw`text-gray-500 text-base`} /* Style the placeholder text */
  }
  @media (max-width: 640px) {
    ${tw`mx-auto`} /* Center input on smaller screens */
  }
`;
const StyledInput = styled(Input)`
  max-width: 100%; /* Make input width responsive */
`;

const Label = styled.label`
margin-left:10px;
`

const StyledLabel = styled(Label)`
  max-width: 100%; /* Make input width responsive */
  text-align:left;
`;

const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-32 w-full pr-6 pl-6`} // Make it responsive with padding for placeholder spacing
`;

const SubmitButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 bg-customPurple  text-white rounded-4xl p-4  items-center hover:bg-customPinkLight`}
  width: 100%; /* Make button responsive */
  margin: auto; /* Centering the button */
`;

const ContactRightColum = () => {
  return (
    <StyledContainer>
      <Heading>Send a message</Heading>
      <Form>
        <InputRow>
        <StyledLabel>Name</StyledLabel>
        </InputRow>
        <InputRow>
          <StyledInput type="text" placeholder="Full Name" />
        </InputRow>

        <InputRow>
        <StyledLabel>Name</StyledLabel>
        </InputRow>
        <InputRow>
          <StyledInput type="email" placeholder="Your Email Address" />
        </InputRow>


        <InputRow>
        <StyledLabel>Name</StyledLabel>
        </InputRow>
        <InputRow>
          <StyledInput type="text" placeholder="Your Subject" />
        </InputRow>


        <InputRow>
        <StyledLabel>Name</StyledLabel>
        </InputRow>
        <InputRow>
          <StyledInput type="tel" placeholder="Phone Number" />
        </InputRow>

        <InputRow>
        <StyledLabel>Name</StyledLabel>
        </InputRow>
        <InputRow>
          <Textarea placeholder="Your Message Here" />
        </InputRow>
        <SubmitButton type="submit">Send Message</SubmitButton>
      </Form>
    </StyledContainer>
  );
};

export default ContactRightColum;