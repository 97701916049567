import { CreativeAgencyAlBarsha } from "Pages/SeoPages/VideoAdProduction/CreativeAgencyAlBarsha";
import { Helmet } from "react-helmet";

export const CreativeAgencyAlBarshaPage = () => (
    <>
      <Helmet>
        <title>Creative Agency in Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a creative agency in Al Barsha dedicated to bringing your ideas to life. From graphic design to video production, we provide innovative and high-quality creative solutions to help your business stand out and engage your audience in Al Barsha."
        />
        <meta
          name="keywords"
          content="creative agency in Al Barsha, Al Barsha creative, graphic design Al Barsha, video production Al Barsha"
        />
        <meta
          property="og:title"
          content="Creative Agency in Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is a creative agency in Al Barsha dedicated to bringing your ideas to life. From graphic design to video production, we provide innovative and high-quality creative solutions to help your business stand out and engage your audience in Al Barsha."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset is a creative agency in Al Barsha dedicated to bringing your ideas to life.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <CreativeAgencyAlBarsha />
    </>
  );
  
