import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaPaintBrush,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Professional Branding Agencies Page Component
export const ProfessionalBrandingAgenciesInDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Professional Branding Agencies in Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Branding Partner in Dubai</Subheading>}
        heading={
          <Heading>
            Leading Professional Branding Agencies in{" "}
            <span tw="text-primary-500">Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are one of the top professional branding agencies in Dubai, offering innovative solutions to help businesses build strong, memorable brands. From brand identity to strategy, we deliver tailored solutions that align with your vision.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1613579918100-bbf25d3691d0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTE4fHxicmFuZGluZyUyMGFnZW5jeXxlbnwwfHwwfHx8MA%3D%3D", // Branding team
          "https://images.unsplash.com/photo-1561070791-36c11767b26a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YnJhbmRpbmd8ZW58MHx8MHx8fDA%3D", // Dubai skyline
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
      />

      {/* Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Branding Solutions"
        description="Explore our range of professional branding services designed to help your business thrive in Dubai:"
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Brand Identity Design",
            description:
              "Craft a unique and memorable visual identity for your business.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Brand Strategy",
            description:
              "Develop a clear roadmap to position your brand effectively in the market.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "User Experience (UX) Design",
            description:
              "Design intuitive interfaces that enhance customer satisfaction.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Workshops",
            description:
              "Engage in interactive sessions to align your vision with actionable plans.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Creative Content Creation",
            description:
              "Produce engaging content to captivate your audience and strengthen your brand.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Brand Launch Campaigns",
            description:
              "Roll out your brand with impactful campaigns to generate buzz.",
          },
        ]}
      />

      {/* Success Stories Section */}
      <MainFeature1
        subheading={<Subheading>Our Impact</Subheading>}
        heading={<Heading>Success Stories in Dubai</Heading>}
        description={
          <Description>
            <strong>Corporate Branding:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Revamped a corporate brand to appeal to a younger audience in Dubai.</li>
              <li>Achieved a 25% boost in brand loyalty and trust.</li>
              <li>Enhanced online presence across platforms.</li>
            </ul>
            <strong tw="mt-8 block">Startup Brand Awareness:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Built a strong brand identity for a new startup in Dubai.</li>
              <li>Grew Instagram followers by 300% in six months.</li>
              <li>Generated leads at a low cost-per-acquisition rate.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1556761175-b413da4baf72?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDR8fGFnZW5jeXxlbnwwfHwwfHx8MA%3D%3D", // Branding team
          "https://images.unsplash.com/photo-1617695744007-68ef55752789?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fGFnZW5jeXxlbnwwfHwwfHx8MA%3D%3D", // Dubai skyline
          "https://plus.unsplash.com/premium_photo-1677087122601-ddf112401f01?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjV8fGJyYW5kaW5nJTIwYWdlbmN5fGVufDB8fDB8fHww", // Team working
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Discover More Success Stories"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Professional Branding Experts in Dubai"
        description="Here’s why businesses in Dubai trust us as their branding partner:"
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "We bring fresh, innovative ideas to every branding project in Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "Our track record speaks for itself—delivering impactful branding solutions in Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the core of everything we do in Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

