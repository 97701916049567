// src/pages/PaidMediaCampaigns.js

import React from "react";
import { FaBullseye, FaMoneyBillWave, FaChartPie } from "react-icons/fa";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import ContactRightColum from "Components/Forms/ContactFormCol";

// Styled Components
const HighlightedText = tw.span`text-customPurple`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const LeftColumn = tw.div`w-[95%] md:w-3/5 px-4 flex flex-col space-y-8`;
const RightColumn = styled.div`
  ${tw`w-full md:w-2/5 flex items-center justify-center mt-12 md:mt-0`}
`;

// Adjust TwoColumn to include overflow-hidden and ensure centering
const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-center items-stretch gap-10 max-w-screen-4xl mx-auto py-12 md:py-16 lg:py-16 overflow-hidden`}
`;

const PaidMediaCampaigns = () => {
  return (
    <AnimationRevealPage>
      <Header from={"Paid Media Campaigns"} />
      <TwoColumn>
        <LeftColumn>
          {/* Main Feature Section */}
          <MainFeature1
            subheading={<Subheading>Paid Media Campaigns</Subheading>}
            heading={
              <>
                Drive Targeted <HighlightedText>Traffic & Maximize ROI</HighlightedText>
              </>
            }
            buttonRounded={false}
            primaryButtonText="Get Started"
            primaryButtonUrl="/get-started" // URL for the button
            // imageSrcs can be uncommented and updated with relevant images if available
            // imageSrcs={[
            //   "https://images.unsplash.com/photo-1581091215367-4c9aaaf60c6b?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            //   "https://images.unsplash.com/photo-1556740749-887f6717d7e4?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            //   "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            // ]}
            description={
              <Description>
                Drive targeted traffic and maximize ROI with our data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently. Our expert team leverages advanced targeting techniques and continuous optimization to deliver measurable results and enhance your online presence.
              </Description>
            }
            widths={["55%", "60%", "70%"]}
            heights={["45%", "60%", "35%"]}
            imageColumnStyle={`height: 400px;`} // Adjusting height as required
            imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
            imagePositions={[
              "left: 35%; top: 20%;",
              "right: 30%; top: 10%;",
              "left: 32%; bottom: 20%;",
            ]} // Custom positions for each image
            textOnLeft={false}
          />

          {/* Our Approach Section */}
          <MainFeature1
            subheading={<Subheading>Our Approach</Subheading>}
            heading={<Heading>Comprehensive Paid Media Strategies</Heading>}
            buttonRounded={false}
            primaryButtonText="Learn More"
            primaryButtonUrl="/our-approach" // URL for the button
            // imageSrcs can be uncommented and updated with relevant images if available
            // imageSrcs={[
            //   "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            //   "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            //   "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            // ]}
            description={
              <Description>
                Our approach is meticulous and client-focused. We begin by understanding your business goals, target audience, and unique requirements. From there, we design a strategic plan that encompasses precision targeting, cost optimization, comprehensive tracking, and creative ad designs. Our team collaborates closely with you throughout the campaign process, providing regular updates and incorporating your feedback to achieve the best possible outcomes.
              </Description>
            }
            textOnLeft={true}
            widths={["50%", "55%", "70%"]}
            heights={["60%", "70%", "40%"]}
            imageColumnStyle={`height: 400px;`} // Adjusting height as required
            imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
            imagePositions={[
              "left: 13%; top: 20%;",
              "right: 0; top: 10%;",
              "left: 10%; bottom: 0;",
            ]} // Custom positions for each image
          />
        </LeftColumn>
        <RightColumn>
          <ContactRightColum />
        </RightColumn>
      </TwoColumn>

      {/* Our Paid Media Campaign Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Paid Media Services</Subheading>}
        heading={
          <>
            Comprehensive <HighlightedText>Paid Media Solutions</HighlightedText>
          </>
        }
        description="MilaajBrandset offers a full suite of Paid Media Campaign services designed to elevate your online presence and engage your audience effectively. Our services are tailored to meet your unique needs, ensuring a cohesive and impactful media strategy."
        cards={[
          {
            icon: <FaBullseye size={50} color="#ff5464" aria-label="Precision Targeting" />,
            title: "Precision Targeting",
            description: "Reach your ideal audience with advanced methods.",
            // url: "/paid-media-campaigns/precision-targeting",
          },
          {
            icon: <FaMoneyBillWave size={50} color="#ff5464" aria-label="Cost Optimization" />,
            title: "Cost Optimization",
            description: "Maximize effectiveness while minimizing spend.",
            // url: "/paid-media-campaigns/cost-optimization",
          },
          {
            icon: <FaChartPie size={50} color="#ff5464" aria-label="Comprehensive Tracking" />,
            title: "Comprehensive Tracking",
            description: "Monitor metrics to enhance performance.",
            // url: "/paid-media-campaigns/comprehensive-tracking",
          },
          // {
          //   icon: <FaAd size={50} color="#ff5464" aria-label="Creative Designs" />,
          //   title: "Creative Designs",
          //   description: "Engaging visuals and messaging that convert.",
          //   // url: "/paid-media-campaigns/creative-designs",
          // },
          // Add more services if needed
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};

export default PaidMediaCampaigns;
