import React from "react";
import { FaChartBar, FaBullhorn, FaUsers } from "react-icons/fa";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

const HighlightedText = tw.span`text-customPurple`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const AffordableAdvertising = () => {
  return (
    <AnimationRevealPage>
      <Header from={"Affordable Advertising Solutions"} />

      {/* Main Feature Section */}
      <MainFeature1
        subheading={<Subheading>Affordable Advertising Solutions</Subheading>}
        heading={
          <Heading>
            Boost Your <span tw="text-primary-500">Brand</span> with Affordable Advertising
          </Heading>
        }
        buttonRounded={false}
        primaryButtonText="Start Your Project"
        primaryButtonUrl="/start-your-project"
        imageSrcs={[
          "https://images.unsplash.com/photo-1559028012-481c04fa702d?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80", // Image of a modern office
          "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80", // Image of a team brainstorming
          "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80", // Image of a marketing meeting
        ]}
        description={
          <Description>
            At MilaajBrandset, we offer affordable advertising solutions tailored to your budget. Our strategic approach ensures maximum ROI and brand visibility.
          </Description>
        }
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle={`height: 400px;`}
        imageStyle={`width: 45%; height: 60%;`}
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        textOnLeft={false}
      />

      {/* Our Approach Section */}
      <MainFeature1
        subheading={<Subheading>Our Approach</Subheading>}
        heading={<Heading>Comprehensive Advertising Services</Heading>}
        buttonRounded={false}
        primaryButtonText="Learn More"
        primaryButtonUrl="/our-approach"
        imageSrcs={[
          "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80", // Image of a team brainstorming
          "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80", // Image of a marketing meeting
          "https://images.unsplash.com/photo-1559028012-481c04fa702d?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80", // Image of a modern office
        ]}
        description={
          <Description>
            Our approach is client-focused and results-driven. We understand your business goals and target audience to design a strategic plan that maximizes your advertising efforts.
          </Description>
        }
        textOnLeft={true}
        widths={["50%", "55%", "70%"]}
        heights={["60%", "70%", "40%"]}
        imageColumnStyle={`height: 400px;`}
        imageStyle={`width: 45%; height: 60%;`}
        imagePositions={[
          "left: 13%; top: 20%;",
          "right: 0; top: 10%;",
          "left: 10%; bottom: 0;",
        ]}
      />

      {/* Our Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading={
          <>
            Effective <HighlightedText>Affordable Advertising</HighlightedText> Services
          </>
        }
        description="We provide comprehensive advertising services to boost your brand's visibility and drive results."
        cards={[
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Market Analysis",
            description:
              "We conduct thorough market analysis to understand your target audience and competition.",
            url: "/contact",
          },
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Campaign Management",
            description:
              "We manage your advertising campaigns to ensure maximum reach and engagement.",
            url: "/contact",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Targeted Advertising",
            description:
              "We create targeted ads to reach the right audience and drive conversions.",
            url: "/contact",
          },
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};

export default AffordableAdvertising;