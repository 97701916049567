// src/pages/AdvertisingAgency.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {  FaChartLine, FaPaintBrush, FaRegChartBar } from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const AdvertisingAgency = () => {
  return (
    <AnimationRevealPage>
      <Header from="Advertising Agency in Bur Dubai" />

      {/* Main Feature Section - SEO Optimized */}
      <MainFeature1
        subheading={<Subheading>Advertising Agency in Bur Dubai</Subheading>}
        heading={
          <Heading>
            Elevate Your Brand with <span tw="text-primary-500">Creative Advertising</span> in Bur Dubai
          </Heading>
        }
        buttonRounded={false}
        primaryButtonText="Get Started"
        imageSrcs={[
          "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        description={
          <Description>
            At our <strong>Advertising Agency in Bur Dubai</strong>, we specialize in creating innovative and impactful advertising campaigns that resonate with your target audience. Our team of experts is dedicated to helping you build a strong brand presence and achieve your marketing goals.
          </Description>
        }
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        textOnLeft={false}
      />

      {/* Our Approach Section */}
      <MainFeature1
        subheading={<Subheading>Our Approach</Subheading>}
        heading={<Heading>Tailored Advertising Solutions</Heading>}
        buttonRounded={false}
        primaryButtonText="Learn More"
        imageSrcs={[
          "https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        description={
          <Description>
            Our approach is centered around understanding your brand, audience, and objectives. We craft customized advertising strategies that leverage the latest trends and technologies to deliver measurable results. From concept development to execution, we ensure every campaign aligns with your brand identity and business goals.
          </Description>
        }
        textOnLeft={true}
        widths={["50%", "55%", "70%"]}
        heights={["60%", "70%", "40%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 13%; top: 20%;",
          "right: 0; top: 10%;",
          "left: 10%; bottom: 0;",
        ]}
      />

      {/* Our Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Advertising Solutions"
        description="We offer a wide range of advertising services to help your brand stand out in the competitive market."
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,  // Creative icon for creative campaigns
            title: "Creative Campaigns",
            description:
              "We design and execute creative advertising campaigns that capture attention and drive engagement.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,  // Chart icon for strategic planning
            title: "Brand Strategy",
            description:
              "We develop comprehensive brand strategies that align with your business objectives and resonate with your audience.",
          },
          {
            icon: <FaRegChartBar color="#ff5464" size={40} />,  // Target icon for focused digital marketing
            title: "Digital Marketing",
            description:
              "We leverage digital channels to amplify your brand's reach and connect with your target audience effectively.",
          },
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};

export default AdvertisingAgency;