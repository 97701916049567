import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPaintBrush,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Digital Branding Agency Page Component
export const DigitalBrandingAgencyInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Digital Branding Agency in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Trusted Digital Branding Partner</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">
              Top Digital Branding Agency in Bur Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in crafting impactful digital
            branding solutions that drive engagement, visibility, and business
            growth. As a startup, we bring fresh ideas, data-driven insights,
            and a client-centric approach to every project—ensuring your brand
            thrives in the digital space.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3", // Marketing analytics dashboard
          "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3", // Professional team meeting
          "https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?ixlib=rb-4.0.3", // Creative workspace
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Digital Branding Experts"
        description="We combine innovation, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Impactful Online Identities",
            description:
              "Our designs create a strong and memorable digital presence.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description: "Backed by research to ensure maximum impact and ROI.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience crafting successful digital brands.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Digital Branding Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Strategy Development: Create tailored plans for your goals.
              </li>
              <li>
                Design Development: Craft visually appealing and cohesive
                digital elements.
              </li>
              <li>
                Implementation: Roll out your brand identity across digital
                platforms.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3", // Analysis dashboard
          "https://images.unsplash.com/photo-1558403194-611308249627?ixlib=rb-4.0.3", // Strategy planning
          "https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3", // Content creation
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our digital branding services have helped businesses grow."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Brand Growth",
            description:
              "A local e-commerce brand saw a 350% increase in online visibility within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a tech client’s lead generation by 70% with a rebranded digital strategy.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={
          <Subheading>Ready to Elevate Your Digital Brand?</Subheading>
        }
        heading="Let’s Build Your Digital Brand Together"
        description={
          <Description>
            Partner with our expert digital branding agency in Bur Dubai to
            unlock the full potential of your online presence. Whether you’re
            looking to refresh your digital identity or launch a new brand
            strategy, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3", // Team collaboration
          "https://images.unsplash.com/photo-1512486130939-2c4f79935e4f?ixlib=rb-4.0.3", // Modern workspace
          "https://images.unsplash.com/photo-1557425493-6f90ae4659fc?ixlib=rb-4.0.3", // Handshake
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
