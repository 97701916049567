import { BestSocialMediaMarketingTeamInBurDubai } from "Pages/SeoPages/SocialMediaManagement/BestSocialMediaMarketingTeamInBurDubai";
import { DIFCInstagramFacebookAdsExperts } from "Pages/SeoPages/SocialMediaManagement/DIFCInstagramFacebookAdsExperts";
import { ProfessionalSocialMediaManagementInAlBarsha } from "Pages/SeoPages/SocialMediaManagement/ProfessionalSocialMediaManagementInAlBarsha";
import { SocialMediaAdvertisingServicesInAlBarsha } from "Pages/SeoPages/SocialMediaManagement/SocialMediaAdvertisingServicesInAlBarsha";
import { SocialMediaManagersInAlBarsha } from "Pages/SeoPages/SocialMediaManagement/SocialMediaManagersInAlBarsha";
import { SocialMediaMarketersInBurDubai } from "Pages/SeoPages/SocialMediaManagement/SocialMediaMarketersInBurDubai";
import { SocialMediaMarketingNearAlBarsha } from "Pages/SeoPages/SocialMediaManagement/SocialMediaMarketingNearAlBarsha";
import { SocialMediaMarketingServicesInBurDubai } from "Pages/SeoPages/SocialMediaManagement/SocialMediaMarketingServicesInBurDubai";
import { SocialMediaMarketingTeamInBurDubai } from "Pages/SeoPages/SocialMediaManagement/SocialMediaMarketingTeamInBurDubai";
import { Helmet } from "react-helmet";

export const ProfessionalSocialMediaManagementSEO = () => (
    <>
      <Helmet>
        <title>Professional Social Media Management in Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers professional social media management in Al Barsha, including Instagram, Facebook, and Twitter campaigns. Grow your online presence with expert strategies."
        />
        <meta
          name="keywords"
          content="social media management Al Barsha, Instagram growth Al Barsha, Facebook ads Al Barsha, Twitter branding Al Barsha"
        />
        <meta
          property="og:title"
          content="Professional Social Media Management in Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers professional social media management in Al Barsha, including Instagram, Facebook, and Twitter campaigns. Grow your online presence with expert strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides professional social media management in Al Barsha, helping businesses grow their online presence with expert strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <ProfessionalSocialMediaManagementInAlBarsha />
    </>
  );
  
  export const SocialMediaMarketingSEO = () => (
    <>
      <Helmet>
        <title>Social Media Marketing Companies Near Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is one of the top social media marketing companies near Al Barsha, offering Instagram, Facebook, and Twitter campaigns. Grow your online presence with expert strategies."
        />
        <meta
          name="keywords"
          content="social media marketing near Al Barsha, Instagram growth Al Barsha, Facebook ads Al Barsha, Twitter branding Al Barsha"
        />
        <meta
          property="og:title"
          content="Social Media Marketing Companies Near Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is one of the top social media marketing companies near Al Barsha, offering Instagram, Facebook, and Twitter campaigns. Grow your online presence with expert strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides professional social media marketing services near Al Barsha, helping businesses grow their online presence with expert strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Near Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaMarketingNearAlBarsha />
    </>
  );
 

  export const SocialMediaAdvertisingServicesSEO = () => (
    <>
      <Helmet>
        <title>Social Media Advertising Services in Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers professional social media advertising services in Al Barsha, including Facebook Ads, Instagram Ads, and LinkedIn Ads. Drive traffic, generate leads, and boost conversions with our data-driven strategies."
        />
        <meta
          name="keywords"
          content="social media advertising Al Barsha, Facebook Ads Al Barsha, Instagram Ads Al Barsha, LinkedIn Ads Al Barsha"
        />
        <meta
          property="og:title"
          content="Social Media Advertising Services in Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers professional social media advertising services in Al Barsha, including Facebook Ads, Instagram Ads, and LinkedIn Ads. Drive traffic, generate leads, and boost conversions with our data-driven strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides social media advertising services in Al Barsha, helping businesses grow with targeted and data-driven ad campaigns.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaAdvertisingServicesInAlBarsha />
    </>
  );
  
  export const SocialMediaMarketingServicesSEO = () => (
    <>
      <Helmet>
        <title>Social Media Marketing Services in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers professional social media marketing services in Bur Dubai, including Facebook, Instagram, LinkedIn, and Twitter strategies. Build brand awareness, engage your audience, and drive growth with our data-driven approach."
        />
        <meta
          name="keywords"
          content="social media marketing Bur Dubai, Facebook marketing Bur Dubai, Instagram marketing Bur Dubai, LinkedIn marketing Bur Dubai"
        />
        <meta
          property="og:title"
          content="Social Media Marketing Services in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers professional social media marketing services in Bur Dubai, including Facebook, Instagram, LinkedIn, and Twitter strategies. Build brand awareness, engage your audience, and drive growth with our data-driven approach."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides social media marketing services in Bur Dubai, helping businesses grow with targeted and data-driven strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaMarketingServicesInBurDubai />
    </>
  );
  
 
  export const SocialMediaManagersSEO = () => (
    <>
      <Helmet>
        <title>Social Media Managers in Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers professional social media managers in Al Barsha who specialize in managing Facebook, Instagram, LinkedIn, and Twitter campaigns. Build brand awareness, engage your audience, and drive growth with our data-driven approach."
        />
        <meta
          name="keywords"
          content="social media managers Al Barsha, Facebook managers Al Barsha, Instagram managers Al Barsha, LinkedIn managers Al Barsha"
        />
        <meta
          property="og:title"
          content="Social Media Managers in Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers professional social media managers in Al Barsha who specialize in managing Facebook, Instagram, LinkedIn, and Twitter campaigns. Build brand awareness, engage your audience, and drive growth with our data-driven approach."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides expert social media managers in Al Barsha, helping businesses grow with targeted and data-driven strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaManagersInAlBarsha />
    </>
  );
  
 
  export const DIFCInstagramFacebookAdsSEO = () => (
    <>
      <Helmet>
        <title>DIFC Instagram and Facebook Ads Experts | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert Instagram and Facebook ad services in DIFC, helping businesses drive traffic, generate leads, and boost conversions. Our data-driven strategies ensure maximum ROI for your campaigns."
        />
        <meta
          name="keywords"
          content="Instagram ads DIFC, Facebook ads DIFC, social media ads DIFC, DIFC ad experts"
        />
        <meta
          property="og:title"
          content="DIFC Instagram and Facebook Ads Experts | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert Instagram and Facebook ad services in DIFC, helping businesses drive traffic, generate leads, and boost conversions. Our data-driven strategies ensure maximum ROI for your campaigns."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides expert Instagram and Facebook ad services in DIFC, helping businesses grow with targeted and data-driven strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "DIFC",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2211,
                "longitude": 55.2778
              }
            }
          `}
        </script>
      </Helmet>
      <DIFCInstagramFacebookAdsExperts />
    </>
  );
  

  export const SocialMediaMarketingTeamSEO = () => (
    <>
      <Helmet>
        <title>Social Media Marketing Team in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers a professional social media marketing team in Bur Dubai, specializing in Facebook, Instagram, LinkedIn, and Twitter campaigns. Build brand awareness, engage your audience, and drive growth with our data-driven approach."
        />
        <meta
          name="keywords"
          content="social media marketing team Bur Dubai, Facebook marketing Bur Dubai, Instagram marketing Bur Dubai, LinkedIn marketing Bur Dubai"
        />
        <meta
          property="og:title"
          content="Social Media Marketing Team in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers a professional social media marketing team in Bur Dubai, specializing in Facebook, Instagram, LinkedIn, and Twitter campaigns. Build brand awareness, engage your audience, and drive growth with our data-driven approach."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides a skilled social media marketing team in Bur Dubai, helping businesses grow with targeted and data-driven strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaMarketingTeamInBurDubai />
    </>
  );
  
  export const BestSocialMediaMarketingTeamSEO = () => (
    <>
      <Helmet>
        <title>Best Social Media Marketing Team in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is the best social media marketing team in Bur Dubai, specializing in Facebook, Instagram, LinkedIn, and Twitter campaigns. Build brand awareness, engage your audience, and drive growth with our data-driven approach."
        />
        <meta
          name="keywords"
          content="best social media marketing team Bur Dubai, Facebook marketing Bur Dubai, Instagram marketing Bur Dubai, LinkedIn marketing Bur Dubai"
        />
        <meta
          property="og:title"
          content="Best Social Media Marketing Team in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is the best social media marketing team in Bur Dubai, specializing in Facebook, Instagram, LinkedIn, and Twitter campaigns. Build brand awareness, engage your audience, and drive growth with our data-driven approach."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset is the best social media marketing team in Bur Dubai, helping businesses grow with targeted and data-driven strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <BestSocialMediaMarketingTeamInBurDubai />
    </>
  );
  
  export const SocialMediaMarketersSEO = () => (
    <>
      <Helmet>
        <title>Social Media Marketers in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers skilled social media marketers in Bur Dubai, specializing in Facebook, Instagram, LinkedIn, and Twitter campaigns. Build brand awareness, engage your audience, and drive growth with our data-driven approach."
        />
        <meta
          name="keywords"
          content="social media marketers Bur Dubai, Facebook marketing Bur Dubai, Instagram marketing Bur Dubai, LinkedIn marketing Bur Dubai"
        />
        <meta
          property="og:title"
          content="Social Media Marketers in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers skilled social media marketers in Bur Dubai, specializing in Facebook, Instagram, LinkedIn, and Twitter campaigns. Build brand awareness, engage your audience, and drive growth with our data-driven approach."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides skilled social media marketers in Bur Dubai, helping businesses grow with targeted and data-driven strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaMarketersInBurDubai />
    </>
  );
  
 