import { CreativeBrandingAgencyNearDifc } from "Pages/SeoPages/Branding/CreativeBrandingAgencyNearDifc";
import { AffordableMarketingServicesInBurDubai } from "Pages/SeoPages/DigitalMarketing/AffordableMarketingServicesInBurDubai";
import { DigitalMarketingAgencyNearDifc } from "Pages/SeoPages/DigitalMarketing/DigitalMarketingAgencyNearDifc";
import { FullServiceDigitalAgencyInBurDubai } from "Pages/SeoPages/DigitalMarketing/FullServiceDigitalAgencyInBurDubai";
import { MarketingCompanyAlBarsha } from "Pages/SeoPages/DigitalMarketing/MarketingCompanyAlBarsha";
import { ReliableMarketingAgenciesNearMe } from "Pages/SeoPages/DigitalMarketing/ReliableMarketingAgenciesNearMe";
import { TopDigitalMarketingAgencyAlBarsha } from "Pages/SeoPages/DigitalMarketing/TopDigitalMarketingAgencyAlBarsha";
import { Helmet } from "react-helmet";

export const MarketingCompanyAlBarshaPage = () => (
    <>
      <Helmet>
        <title>Marketing Company in Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a marketing company in Al Barsha dedicated to helping businesses grow and succeed. From digital marketing to traditional advertising, we provide comprehensive marketing solutions tailored to your specific needs in Al Barsha."
        />
        <meta
          name="keywords"
          content="marketing company in Al Barsha, Al Barsha marketing, digital marketing Al Barsha, traditional advertising Al Barsha"
        />
        <meta
          property="og:title"
          content="Marketing Company in Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is a marketing company in Al Barsha dedicated to helping businesses grow and succeed. From digital marketing to traditional advertising, we provide comprehensive marketing solutions tailored to your specific needs in Al Barsha."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset is a marketing company in Al Barsha dedicated to helping businesses grow and succeed.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <MarketingCompanyAlBarsha />
    </>
  );



  
export const DigitalMarketingAgencyNearDifcPage = () => (
    <>
      <Helmet>
        <title>Digital Marketing Agency Near DIFC | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a digital marketing agency located near DIFC, dedicated to helping businesses grow and succeed. From SEO to social media marketing, we provide comprehensive digital marketing solutions tailored to your specific needs in the heart of Dubai."
        />
        <meta
          name="keywords"
          content="digital marketing agency near DIFC, DIFC digital marketing, SEO near DIFC, social media marketing near DIFC"
        />
        <meta
          property="og:title"
          content="Digital Marketing Agency Near DIFC | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is a digital marketing agency located near DIFC, dedicated to helping businesses grow and succeed. From SEO to social media marketing, we provide comprehensive digital marketing solutions tailored to your specific needs in the heart of Dubai."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset is a digital marketing agency located near DIFC, dedicated to helping businesses grow and succeed.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Near DIFC",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <DigitalMarketingAgencyNearDifc />
    </>
  );
  

  
  export const TopDigitalMarketingAgencyAlBarshaPage = () => (
    <>
      <Helmet>
        <title>Top Digital Marketing Agency in Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is the top digital marketing agency in Al Barsha, dedicated to helping businesses grow and succeed online. From SEO to social media marketing, we provide cutting-edge digital solutions tailored to your specific needs in Al Barsha."
        />
        <meta
          name="keywords"
          content="top digital marketing agency in Al Barsha, Al Barsha digital marketing, SEO Al Barsha, social media marketing Al Barsha"
        />
        <meta
          property="og:title"
          content="Top Digital Marketing Agency in Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is the top digital marketing agency in Al Barsha, dedicated to helping businesses grow and succeed online. From SEO to social media marketing, we provide cutting-edge digital solutions tailored to your specific needs in Al Barsha."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset is the top digital marketing agency in Al Barsha, dedicated to helping businesses grow and succeed online.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <TopDigitalMarketingAgencyAlBarsha />
    </>
  );




  export const CreativeBrandingAgencyNearDifcPage = () => (
    <>
      <Helmet>
        <title>Creative Branding Agency Near DIFC | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a creative branding agency located near DIFC, dedicated to helping businesses grow and succeed. From brand identity to marketing strategy, we provide innovative and high-quality branding solutions tailored to your specific needs in the heart of Dubai."
        />
        <meta
          name="keywords"
          content="creative branding agency near DIFC, DIFC branding, brand identity DIFC, marketing strategy DIFC"
        />
        <meta
          property="og:title"
          content="Creative Branding Agency Near DIFC | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is a creative branding agency located near DIFC, dedicated to helping businesses grow and succeed. From brand identity to marketing strategy, we provide innovative and high-quality branding solutions tailored to your specific needs in the heart of Dubai."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset is a creative branding agency located near DIFC, dedicated to helping businesses grow and succeed.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Near DIFC",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <CreativeBrandingAgencyNearDifc />
    </>
  );
  



  export const AffordableMarketingServicesSEO = () => (
    <>
      <Helmet>
        <title>Affordable Marketing Services in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers affordable marketing services in Bur Dubai, including digital marketing, SEO, and social media management. Grow your business with cost-effective solutions."
        />
        <meta
          name="keywords"
          content="affordable marketing services Bur Dubai, digital marketing Bur Dubai, SEO services Bur Dubai, social media marketing Bur Dubai"
        />
        <meta
          property="og:title"
          content="Affordable Marketing Services in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers affordable marketing services in Bur Dubai, including digital marketing, SEO, and social media management. Grow your business with cost-effective solutions."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides affordable marketing services in Bur Dubai, helping businesses grow with cost-effective solutions.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <AffordableMarketingServicesInBurDubai />
    </>
  );
  export const FullServiceDigitalAgencySEO = () => (
    <>
      <Helmet>
        <title>Full-Service Digital Agency in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a full-service digital agency in Bur Dubai, offering website development, digital marketing, and brand identity solutions. Elevate your business with our innovative strategies."
        />
        <meta
          name="keywords"
          content="full-service digital agency Bur Dubai, digital marketing Bur Dubai, website development Bur Dubai, brand identity Bur Dubai"
        />
        <meta
          property="og:title"
          content="Full-Service Digital Agency in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is a full-service digital agency in Bur Dubai, offering website development, digital marketing, and brand identity solutions. Elevate your business with our innovative strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides full-service digital solutions in Bur Dubai, helping businesses grow with innovative strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <FullServiceDigitalAgencyInBurDubai />
    </>
  );
  
  export const ReliableMarketingAgenciesSEO = () => (
    <>
      <Helmet>
        <title>Reliable Marketing Agencies Near Me | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is one of the most reliable marketing agencies near you, offering digital marketing, social media management, and branding solutions. Grow your business with expert strategies."
        />
        <meta
          name="keywords"
          content="reliable marketing agencies near me, digital marketing near me, social media management near me, branding solutions near me"
        />
        <meta
          property="og:title"
          content="Reliable Marketing Agencies Near Me | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is one of the most reliable marketing agencies near you, offering digital marketing, social media management, and branding solutions. Grow your business with expert strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides reliable marketing services near you, helping businesses grow with innovative strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Near You",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <ReliableMarketingAgenciesNearMe />
    </>
  );
  
 