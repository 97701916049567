import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";
import Header from "Components/headers/PageHeader";
import { Container, ContentForServices } from "Components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "Components/misc/Headings.js";
import { SectionDescription } from "Components/misc/Typography.js";
import ArrowRightIcon from "images/arrow-right-2-icon.avif";

// Import image assets
import digitalMarketingImage from "images/digital_marketing.webp";
import webDevelopmentImage from "images/web_development.webp";
import eComImage from "images/e_commerce.webp";
import uiUxImage from "images/ui_ux.webp";
import brandIdentityImage from "images/Brand_Identity.webp"
import socialMediaImage from "images/socialMedia.webp"
import paidMediaImage from "images/paidMedia.webp"
import seo from "images/seoImage.webp"
import videoAdvertisingImage from "images/videoAdvertising.webp"

const HeadingContainer = tw.div`text-center`;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;

// Adjust CarouselContainer for responsive design
const CarouselContainer = styled.div`
  ${tw`mt-16 relative flex justify-center items-center`}
  overflow: hidden; /* Hide overflowing slides */
`;

// Wrapper for slides to handle transform
const SlidesWrapper = styled.div`
  ${tw`flex transition-transform duration-500 ease-in-out`}
  width: ${({ isMobile, totalSlides }) =>
    isMobile ? `${totalSlides * 100}%` : "auto"};
  transform: ${({ isMobile, currentSlide }) =>
    isMobile ? `translateX(-${currentSlide * 100}%)` : "none"};
`;

// Slide styling
const Slide = styled.div`
  ${tw`flex flex-col items-center relative transition-all duration-500`}
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  border-radius: 40px;
  width: ${({ isMobile, isActive }) =>
    isMobile ? "80px" : isActive ? "350px" : "130px"};
  height: 400px;
  opacity: ${({ isMobile, isActive }) => (isMobile ? 1 : isActive ? 1 : 0.8)};
  box-shadow: ${({ isMobile, isActive }) =>
    isMobile
      ? "0px 10px 20px rgba(0, 0, 0, 0.2)"
      : isActive
      ? "0px 10px 20px rgba(0, 0, 0, 0.2)"
      : "none"};
  cursor: pointer;
  margin-right: ${({ isMobile }) => (isMobile ? "" : "1rem")};

  @media (max-width: 768px) {
    flex: 0 0 100%; /* Each slide takes full width on mobile */
    margin-right: 0; /* Remove right margin on mobile */
  }
`;

// Enhanced Dark overlay for better text readability
const DarkOverlay = styled.div`
  ${tw`absolute inset-0 rounded-4xl`}
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
`;

// Slide content with text shadow for better readability
const SlideContent = styled.div`
  ${tw`relative z-10 text-white text-center p-4 flex flex-col h-full justify-center`}
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7); /* Adds shadow to text */
`;

const SlideTitle = tw.h3`text-xl font-bold`;
const SlideDescription = tw.p`mt-2 text-sm text-gray-200`;

// Arrow icon container
const ArrowIconContainer = styled.div`
  ${tw`absolute justify-end p-2 rounded-full flex bottom-[2rem] right-[2rem] cursor-pointer`}
  background-color: ${({ isBlack }) => (isBlack ? "#fff" : "rgba(255, 84, 100, 0.2)")};

  img {
    ${tw`w-6 h-4`}
    filter: ${({ isBlack }) =>
      isBlack
        ? "brightness(0) invert(0)" // Use original arrow color for white bg
        : "brightness(0) invert(1)"}; // Make arrow icon white for dark bg
    transform: rotate(-40deg);
  }

  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
`;



// Pagination dots
const PaginationDots = tw.div`flex justify-center my-8`;
const Dot = styled.div`
  ${tw`w-3 h-3 rounded-full mx-4 cursor-pointer transition-all`}
  background-color: ${({ isActive }) => (isActive ? "white" : "gray")};
  width: ${({ isActive }) => (isActive ? "10px" : "8px")};
  height: ${({ isActive }) => (isActive ? "10px" : "8px")};
`;

// Service data
const services = [
  {
    title: "Brand Identity Development",
    description:
      "Establish a unique and memorable identity for your brand with comprehensive branding solutions.",
    image: brandIdentityImage,
    link: "/brand-identity-development",
  },
  {
    title: "Web Design & Development",
    description:
      "Build high-performing, user-friendly websites tailored to your business needs.",
    image: webDevelopmentImage,
    link: "/web-development",
  },
  {
    title: "Social Media Management",
    description:
      "Engage your audience and grow your online presence with tailored strategies.",
    image: socialMediaImage,
    link: "/social-media-branding",
  },
  {
    title: "Paid Media Campaigns",
    description:
      "Drive targeted traffic and achieve measurable results with expertly managed ad campaigns.",
    image: paidMediaImage,
    link: "/paid-media-campaigns",
  },
  {
    title: "UI/UX Design",
    description:
      "Design intuitive and engaging user interfaces for exceptional user experiences.",
    image: uiUxImage,
    link: "/ui-ux-design",
  },
  {
    title: "Digital Marketing",
    description:
      "Boost your online presence and reach your target audience effectively.",
    image: digitalMarketingImage,
    link: "/digital-marketing",
  },
  {
    title: "E-Commerce Solutions",
    description:
      "Develop powerful online stores to grow your business in the digital marketplace.",
    image: eComImage,
    link: "/e-commerce",
  },
  {
    title: "Search Engine Optimization (SEO)",
    description:
      "Improve your website’s search engine rankings and visibility with expert SEO strategies.",
    image: seo,
    link: "/search-engine-optimization",
  },
  {
    title: "Video Advertising Production",
    description:
      "Create captivating video content to tell your brand’s story and engage your audience.",
    image: videoAdvertisingImage,
    link: "/video-ad-production",
  },
  // {
  //   title: "API Development",
  //   description:
  //     "Create robust and scalable APIs to power your applications and integrate systems.",
  //   image: apiDevelopmentImage,
  //   link: "/api-development",
  // },
];

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle window resize to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle swipe events
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentSlide < services.length - 1) {
        setCurrentSlide(currentSlide + 1);
      }
    },
    onSwipedRight: () => {
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // Ensure currentSlide is within bounds if the number of slides changes
  useEffect(() => {
    if (currentSlide >= services.length) {
      setCurrentSlide(services.length - 1);
    }
  }, [ currentSlide]);

  return (
    <Container>
      <Header from={"Our Services, Your Success"} />
      <ContentForServices>
        <HeadingContainer>
          <Subheading>Our Expertise</Subheading>
          <Heading>Empowering Your Brand with Expert Services</Heading>
          <Description>
            MilaajBrandset offers a wide range of services to help your business
            thrive in the digital world. From marketing to development, we've
            got you covered.
          </Description>
        </HeadingContainer>
        {/* Apply swipe handlers conditionally for mobile */}
        <CarouselContainer {...(isMobile ? handlers : {})}>
          <SlidesWrapper
            isMobile={isMobile}
            currentSlide={currentSlide}
            totalSlides={services.length}
          >
            {services.map((service, index) => (
              <Slide
                key={index}
                bgImage={service.image}
                isActive={currentSlide === index}
                isMobile={isMobile}
                onClick={() => setCurrentSlide(index)} // Optional: Change the selected slide on click
              >
                <DarkOverlay />
                <SlideContent>
                  <SlideTitle>{service.title}</SlideTitle>
                  <SlideDescription>{service.description}</SlideDescription>
                  {currentSlide === index && (
                    <ArrowIconContainer>
                      <Link to={service.link}>
                        <img alt="milaajbrandset-services" src={ArrowRightIcon} />
                      </Link>
                    </ArrowIconContainer>
                  )}
                </SlideContent>
              </Slide>
            ))}
          </SlidesWrapper>
        </CarouselContainer>
        {/* Pagination Dots */}
        <PaginationDots>
          {services.map((_, index) => (
            <Dot
              key={index}
              isActive={currentSlide === index}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </PaginationDots>
      </ContentForServices>
    </Container>
  );
};

export default Carousel;
