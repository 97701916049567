import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaBullhorn,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaLightbulb,
  FaAward,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const BestAdvertisingAgencyBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Best Advertising Agency in Bur Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Advertising Partner in Bur Dubai</Subheading>
        }
        heading={
          <Heading>
            Best Advertising Agency in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are the best advertising agency in Bur Dubai,
            dedicated to helping businesses grow and succeed. From traditional
            advertising to digital campaigns, we provide innovative and
            high-quality solutions tailored to your specific needs in Bur Dubai.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Advertising Campaign"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Advertising Solutions"
        description="We offer a full range of advertising services to help your business thrive in Bur Dubai:"
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Digital Advertising",
            description:
              "Effective digital advertising campaigns to reach your target audience in Bur Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Market Research",
            description:
              "In-depth market research to understand your audience and competition in Bur Dubai.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Social Media Advertising",
            description:
              "Targeted social media advertising to engage and grow your brand in Bur Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Content Creation",
            description:
              "High-quality content creation to drive traffic and conversions in Bur Dubai.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Advertising Strategy",
            description:
              "Developing a comprehensive advertising strategy to achieve your business goals in Bur Dubai.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Traditional Advertising",
            description:
              "Effective traditional advertising solutions to complement your digital efforts in Bur Dubai.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Advertising Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Advertising Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Implemented a successful digital advertising campaign for an
                e-commerce business in Bur Dubai
              </li>
              <li>Increased website traffic by 60%</li>
              <li>Boosted sales by 45%</li>
            </ul>
            <strong tw="mt-8 block">Corporate Advertising Strategy:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Developed a comprehensive advertising strategy for a corporate
                business in Bur Dubai
              </li>
              <li>Enhanced brand awareness by 50%</li>
              <li>Generated 30% more leads</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Best Advertising Experts in Bur Dubai"
        description="Here’s why businesses in Bur Dubai trust us for their advertising needs:"
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Local Expertise",
            description:
              "We understand the unique advertising challenges and opportunities in Bur Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We deliver measurable improvements in brand awareness and sales in Bur Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the heart of everything we do in Bur Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
