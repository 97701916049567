import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import { FaTrophy, FaChartBar, FaLightbulb } from "react-icons/fa"; // Import icons from react-icons/fa

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const No1AdvertisingAgency = () => {
  return (
    <AnimationRevealPage>
      <Header from={"No.1 Advertising agency in Bur Dubai"} />

      {/* Main Feature Section */}
      <MainFeature1
        subheading={<Subheading>No.1 Advertising agency in Bur Dubai</Subheading>}
        heading={
          <Heading>
            Discover <span tw="text-primary-500">Top Advertising Services</span>
          </Heading>
        }
        buttonRounded={false}
        primaryButtonText="Learn More"
        primaryButtonUrl="/about-us" // URL for the button
        imageSrcs={[
          "https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
        ]}
        description={
          <Description>
            As the No.1 Advertising agency in Bur Dubai, we specialize in delivering top-tier advertising solutions tailored to meet your business needs. Our expertise spans across various industries, ensuring that your brand stands out in a competitive market.
          </Description>
        }
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]} // Custom positions for each image
        textOnLeft={false}
      />

      {/* Our Services Section */}
      <MainFeature1
        subheading={<Subheading>Our Services</Subheading>}
        heading={<Heading>Comprehensive Advertising Services</Heading>}
        buttonRounded={false}
        primaryButtonText="Explore Services"
        primaryButtonUrl="/services" // URL for the button
        imageSrcs={[
          "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          "https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
        ]}
        description={
          <Description>
            At No.1 Advertising agency in Bur Dubai, we offer a wide range of services including digital marketing, brand strategy, and creative campaigns. Our team of experts works closely with you to develop a customized plan that drives results and grows your business.
          </Description>
        }
        textOnLeft={true}
        widths={["50%", "55%", "70%"]}
        heights={["60%", "70%", "40%"]}
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 13%; top: 20%;",
          "right: 0; top: 10%;",
          "left: 10%; bottom: 0;",
        ]} // Custom positions for each image
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading={
          <Heading>
            Why Choose <span tw="text-primary-500">No.1 Advertising agency in Bur Dubai</span>
          </Heading>
        }
        description="We stand out as the No.1 Advertising agency in Bur Dubai due to our commitment to excellence, innovative strategies, and client satisfaction."
        cards={[
          {
            icon: <FaTrophy color="#ff5464"  size={48} />, // Use react-icons/fa icon
            title: "Top-Rated Agency",
            description:
              "Recognized as the leading advertising agency in Bur Dubai, we deliver exceptional results.",
            url: "/contact",
          },
          {
            icon: <FaChartBar color="#ff5464"  size={48} />, // Use react-icons/fa icon
            title: "Data-Driven Strategies",
            description:
              "Our campaigns are backed by thorough market research and data analysis to ensure success.",
            url: "/contact",
          },
          {
            icon: <FaLightbulb color="#ff5464"  size={48} />, // Use react-icons/fa icon
            title: "Innovative Solutions",
            description:
              "We continuously innovate to provide fresh and effective advertising solutions.",
            url: "/contact",
          },
          // Add more services if needed
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};

export default No1AdvertisingAgency;