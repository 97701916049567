// src/components/CustomCursor.jsx
import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";
import throttle from "lodash.throttle";
import { CursorContext } from "../../context/CursorContext";

// Define keyframes for the glowing animation
const glow = keyframes`
  0% {
    box-shadow: 0 0 8px rgba(255, 84, 100, 0.6);
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 84, 100, 1);
    transform: translate(-50%, -50%) scale(1.3);
  }
  100% {
    box-shadow: 0 0 8px rgba(255, 84, 100, 0.6);
    transform: translate(-50%, -50%) scale(1);
  }
`;

// Styled component for the main cursor
const CursorWrapper = styled.div`
  ${tw`fixed top-0 left-0 flex items-center justify-center`}
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 84, 100, 1); /* Fully opaque solid color */
  box-shadow: 0 0 12px rgba(255, 84, 100, 0.8); /* Glow effect */
  transform: translate(-50%, -50%);
  transition: transform 0.15s ease, background 0.3s ease, box-shadow 0.3s ease;
  z-index: 9999; /* Very high z-index to stay on top */
  mix-blend-mode: difference;
  will-change: transform, background, box-shadow;
  pointer-events: none; /* Allow clicks to pass through */
`;

// Animated cursor with glowing effect
const AnimatedCursor = styled(CursorWrapper)`
  animation: ${glow} 1.9s infinite;
  transform: translate(-50%, -50%) scale(1.5);
`;

// Styled component for overlay content (e.g., structural info)
const CursorContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 12px;
  font-family: 'Courier New', Courier, monospace;
  pointer-events: none; /* Ensure content doesn't block interactions */
  white-space: nowrap;
  padding: 5px;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for readability */
  border-radius: 4px;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomCursor = () => {
  const { isActive, info } = useContext(CursorContext);
  const [position, setPosition] = useState({ x: -100, y: -100 });

  useEffect(() => {
    const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;

    if (prefersReducedMotion) {
      return; // Do not initialize custom cursor for users who prefer reduced motion
    }

    const isTouchDevice = () => {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    };

    if (isTouchDevice()) {
      return; // Do not initialize custom cursor on touch devices
    }

    // Throttled mouse move handler for performance
    const handleMouseMove = throttle((e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    }, 20); // Throttle to every 20ms

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // Create a portal root if it doesn't exist
  let portalRoot = document.getElementById("custom-cursor-root");
  if (!portalRoot) {
    portalRoot = document.createElement("div");
    portalRoot.setAttribute("id", "custom-cursor-root");
    document.body.appendChild(portalRoot);
  }

  const cursorElement = (
    isActive && (
      <AnimatedCursor
        style={{
          left: `${position.x}px`,
          top: `${position.y}px`,
        }}
      >
        {/* Overlay Content */}
        <CursorContent>
          <code>{info}</code>
        </CursorContent>
      </AnimatedCursor>
    )
  );

  return ReactDOM.createPortal(cursorElement, portalRoot);
};

export default CustomCursor;
