// src/pages/AffordableAdvertisingAgencyDubai.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import { 
  FaWallet, 
  FaChartLine, 
  FaUsers, 
  FaHandshake, 
  FaPaintBrush, 
  FaBullseye
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const AffordableAdvertisingAgencyDubai = () => {
  return (
    <AnimationRevealPage>
      <Header from="Affordable Advertising Agency in Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Quality Advertising, Affordable Prices</Subheading>}
        heading={
          <Heading>
            Affordable Advertising Solutions for <span tw="text-primary-500">Dubai Businesses</span>
          </Heading>
        }
        description={
          <Description>
            As Dubai's most affordable advertising agency, we deliver high-quality marketing solutions tailored to your budget. Whether you're a startup or a small business, we help you grow without breaking the bank.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get a Free Quote"
      />

      {/* Why Affordable Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="Affordable Doesn't Mean Compromising Quality"
        description="We deliver exceptional value at competitive prices:"
        cards={[
          {
            icon: <FaWallet color="#ff5464" size={40} />,
            title: "Transparent Pricing",
            description: "No hidden fees—clear, upfront costs for all services."
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Cost-Effective Strategies",
            description: "Maximize your ROI with budget-friendly campaigns."
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Flexible Packages",
            description: "Customizable plans to fit your budget and goals."
          }
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Proven Results</Subheading>}
        heading={<Heading>Affordable Campaigns, Exceptional Results</Heading>}
        description={
          <Description>
            <strong>Device Repair Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Increased customer inquiries by 40%</li>
              <li>Targeted ads for Bur Dubai residents</li>
              <li>Delivered within a small business budget</li>
            </ul>

            <strong tw="mt-8 block">Education Provider Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Boosted enrollment inquiries by 35%</li>
              <li>Ads targeting parents and students in Deira</li>
              <li>Cost-effective strategy with measurable results</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Service Areas */}
      <Features
        subheading={<Subheading>Dubai Coverage</Subheading>}
        heading="Affordable Advertising Across Dubai"
        description="We serve businesses in key areas:"
        cards={[
          {
            title: "Bur Dubai",
            description: "Budget-friendly campaigns for local businesses"
          },
          {
            title: "Deira",
            description: "Affordable ads for education providers and retail"
          },
          {
            title: "Dubai Marina",
            description: "Cost-effective promotions for lifestyle brands"
          },
          {
            title: "Business Bay",
            description: "Affordable marketing for corporate offices and real estate"
          },
          {
            title: "Jumeirah",
            description: "Budget campaigns for luxury hospitality and tourism"
          },
          {
            title: "Jebel Ali",
            description: "Cost-effective advertising for industrial and logistics companies"
          }
        ]}
      />

      {/* Pricing Section */}
      <StyledFeatures
        subheading={<Subheading>Our Pricing</Subheading>}
        heading="Affordable Packages for Every Business"
        description="Choose a plan that fits your budget:"
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Starter Package",
            description: "Perfect for small businesses and startups. Includes basic ad creation and social media management.",
            url:'/contact'
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Growth Package",
            description: "Ideal for growing businesses. Includes targeted campaigns and performance tracking.",
            url:'/contact'
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Premium Package",
            description: "For established businesses. Includes full-service advertising and advanced analytics.",
            url:'/contact'
          }
        ]}
      />
    </AnimationRevealPage>
  );
};


