import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from 'Components/headers/PageHeader';
import FAQ from "Components/Faqs/SingleCol.js";
import FAQ1 from 'images/FAQ-1.avif'
import FAQ2 from 'images/FAQ-2.avif'
import FAQ3 from 'images/FAQ-3.avif'

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header from="FAQ's"/>
      <MainFeature1
        subheading={<Subheading>FAQ</Subheading>}
        heading="Frequently Asked Questions"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrcs={[
        FAQ1,
        FAQ2,
        FAQ3
        ]}
        description="Find answers to common questions about our web services. If you can't find what you're looking for, don't hesitate to reach out to our support team."
        widths={["70%", "70%", "70%"]} 
        heights={["50%", "50%", "50%"]} 
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 0%; top: 0%;",
          "right: 30%; top: 55%;",
          "left: 30%; bottom: 20%;",
        ]} // Custom positions for each image
      />

      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        
        faqs={[
          {
            question: "What services does MilaajBrandset offer?",
            answer:
              "MilaajBrandset offers a wide range of web services including web design, web development, e-commerce solutions, digital marketing, API development, and UI/UX design. We specialize in creating custom digital solutions tailored to each client's unique needs."
          },
          {
            question: "How long does it typically take to complete a web development project?",
            answer:
              "The timeline for a web development project can vary depending on its complexity and scope. A simple website might take 4-6 weeks, while a more complex web application could take 3-6 months. We provide a detailed timeline estimate at the beginning of each project."
          },
          {
            question: "Do you offer ongoing support and maintenance after the website is launched?",
            answer:
              "Yes, we offer ongoing support and maintenance packages to ensure your website continues to perform optimally after launch. This includes regular updates, security checks, and technical support."
          },
          {
            question: "Can you help with migrating my existing website to a new platform?",
            answer:
              "Absolutely! We have extensive experience in website migrations. We ensure a smooth transition of your content, databases, and functionality to the new platform while minimizing downtime."
          },
          {
            question: "What is your approach to ensuring website security?",
            answer:
              "We take website security very seriously. Our approach includes implementing SSL certificates, regular security audits, using secure coding practices, and setting up firewalls and malware scanning. We also educate our clients on best practices for maintaining website security."
          },
          {
            question: "Do you provide SEO services along with web development?",
            answer:
              "Yes, we offer SEO services as part of our digital marketing package. This includes on-page optimization, content strategy, keyword research, and technical SEO to improve your website's search engine rankings."
          }
        ]}
      />
    </AnimationRevealPage>
  );
};