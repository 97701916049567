// src/pages/AdvertisingAgencyDubai.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import { FaGlobe, FaChartPie, FaRocket } from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const AdvertisingAgencyDubai = () => {
  return (
    <AnimationRevealPage>
      <Header from="Advertising Agency in Dubai" />

      {/* Main Feature Section - Dubai Focus */}
      <MainFeature1
        subheading={<Subheading>Premier Dubai Advertising Agency</Subheading>}
        heading={
          <Heading>
            City-Wide Marketing Solutions for <span tw="text-primary-500">Dubai Businesses</span>
          </Heading>
        }
        buttonRounded={false}
        primaryButtonText="Explore Services"
        imageSrcs={[
          "https://images.unsplash.com/photo-1512453979798-5ea266f8880c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1616464916356-3a777f2c983d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1611605698335-8b1569810432?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        description={
          <Description>
            As Dubai's leading <strong>full-service advertising agency</strong>, we deliver impactful marketing strategies across all Emirates. Our expertise spans from Downtown Dubai to JBR, helping businesses establish dominance in one of the world's most competitive markets.
          </Description>
        }
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        textOnLeft={false}
      />

      {/* City-Wide Coverage Section */}
      <MainFeature1
        subheading={<Subheading>Dubai-Wide Reach</Subheading>}
        heading={<Heading>Strategic Presence Across Key Dubai Locations</Heading>}
        buttonRounded={false}
        primaryButtonText="View Coverage Map"
        imageSrcs={[
          "https://images.unsplash.com/photo-1611605698335-8b1569810432?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        description={
          <Description>
            We serve businesses across all Dubai neighborhoods:
            <ul tw="mt-4 list-disc pl-6">
              <li>Downtown & Business Bay</li>
              <li>Dubai Marina & JLT</li>
              <li>Deira & Bur Dubai</li>
              <li>JBR & Palm Jumeirah</li>
              <li>Al Barsha & Media City</li>
            </ul>
          </Description>
        }
        textOnLeft={true}
        widths={["50%", "55%", "70%"]}
        heights={["60%", "70%", "40%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 13%; top: 20%;",
          "right: 0; top: 10%;",
          "left: 10%; bottom: 0;",
        ]}
      />

      {/* Dubai-Specific Services */}
      <StyledFeatures
        subheading={<Subheading>Dubai-Centric Services</Subheading>}
        heading="Tailored for Dubai's Market Dynamics"
        description="Solutions designed for Dubai's unique business ecosystem:"
        cards={[
          {
            icon: <FaGlobe color="#ff5464" size={40} />,
            title: "Global-Local Strategy",
            description: "Bridge international appeal with local market needs across Emirates",
          },
          {
            icon: <FaChartPie color="#ff5464" size={40} />,
            title: "Market Dominance",
            description: "Strategies to outperform competitors in Dubai's saturated markets",
          },
          {
            icon: <FaRocket color="#ff5464" size={40} />,
            title: "Rapid Scaling",
            description: "Accelerated growth plans for Dubai's fast-paced environment",
          },
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};