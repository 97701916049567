import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaPaintBrush,
  FaLaptopCode,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Branding and Digital Solutions Page Component
export const BrandingAndDigitalSolutionsInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Branding and Digital Solutions in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Your Trusted Partner for Branding and Digital Solutions in DIFC
          </Subheading>
        }
        heading={
          <Heading>
            Expert Branding and Digital Solutions in{" "}
            <span tw="text-primary-500">DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering comprehensive
            branding and digital solutions tailored to businesses in DIFC. From
            brand identity to digital marketing, we help you stand out in the
            competitive market and achieve your business goals.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3",
          "https://images.unsplash.com/photo-1521737711867-e3b97375f902?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3",
          "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
      />

      {/* Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Branding and Digital Solutions"
        description="Explore our professional services designed to help your business thrive in DIFC:"
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Brand Identity Design",
            description:
              "Craft a unique and memorable brand identity that resonates with your audience.",
          },
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Website Development",
            description:
              "Build high-performance websites that engage and convert visitors.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Digital Marketing",
            description:
              "Drive traffic and generate leads with SEO, PPC, and social media campaigns.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "User Experience (UX) Design",
            description:
              "Design intuitive interfaces that enhance customer satisfaction.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Strategy",
            description:
              "Work closely with our team to align your vision with actionable plans.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Performance Optimization",
            description:
              "Optimize your digital assets for maximum performance and ROI.",
          },
        ]}
      />

      {/* Success Stories Section */}
      <MainFeature1
        subheading={<Subheading>Our Impact</Subheading>}
        heading={<Heading>Success Stories in DIFC</Heading>}
        description={
          <Description>
            <strong>Corporate Branding:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Revamped a corporate brand to appeal to a younger audience in
                DIFC.
              </li>
              <li>Achieved a 25% boost in brand loyalty and trust.</li>
              <li>Enhanced online presence across platforms.</li>
            </ul>
            <strong tw="mt-8 block">E-commerce Growth:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Built a fully functional e-commerce platform for a retail
                client.
              </li>
              <li>Increased online sales by 60% within six months.</li>
              <li>Improved website speed and search engine rankings.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1581091870623-4c4e1b6d1cae?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3",
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3",
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Discover More Success Stories"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Branding and Digital Experts in DIFC"
        description="Here’s why businesses in DIFC trust us as their branding and digital partner:"
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "We bring fresh, innovative ideas to every project in DIFC.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "Our track record speaks for itself—delivering impactful solutions in DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the core of everything we do in DIFC.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
