import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; // Import HashLink
import tw, { styled } from "twin.macro";
import { Menu, X } from "lucide-react";
import { LogoImg } from "Components/Footer/MiniCenteredFooter";

const Header = styled.header`
  ${tw`flex justify-around items-center pt-6 py-6 mt-16 w-full fixed top-0 left-0 right-0 z-50 transition-all duration-300`}
  max-width: 100%;
  background-color: ${({ isscrolled, contact }) =>
    isscrolled
      ? "rgba(101, 102, 101,0.65)"
      : contact
      ? "rgba(101, 102, 101,0.65)"
      : "rgba(255, 255, 240, 0.19)"};
  backdrop-filter: blur(10px);
  box-shadow: inset 0 -5px 10px rgba(255, 84, 100, 0.9),
    inset 0 0px rgba(255, 84, 100, 0.8), 0 8px 24px rgba(0, 0, 0, 0.3),
    0 4px 12px rgba(0, 0, 0, 0.2);

  border-radius: 55px;

  @media (min-width: 1024px) {
    border-radius: 55px;
    max-width: 1500px;
    ${tw`mx-auto my-4`}
  }

  @media (max-width: 678px) {
    border-radius: 55px;
  }
`;

const NavLinks = styled.div`
  ${tw`hidden lg:flex items-center space-x-6`}
`;

const MobileNavLinks = styled.div`
  ${tw`fixed inset-0 mt-24 flex flex-col justify-center items-center`}
  background-color: black;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? "translateY(0)" : "translateY(-100%)"};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  z-index: 50;
`;

const NavLinkStyled = styled(Link)`
  ${tw`text-lg hover:text-gray-300 font-semibold no-underline transition duration-300`};
  ${({ isMobile }) => isMobile && tw`text-2xl my-4`};

  color: ${({ contact, isscrolled }) =>
    isscrolled ? "white" : contact ? "black" : "white"};
`;

const HashLinkStyled = styled(HashLink)`
  ${tw`text-lg hover:text-gray-300 font-semibold no-underline transition duration-300`};
  ${({ isMobile }) => isMobile && tw`text-2xl my-4`};

  color: ${({ contact, isscrolled }) =>
    isscrolled ? "white" : contact ? "black" : "white"};
`;

const LogoLink = styled(Link)`
  ${tw`text-xl lg:text-2xl font-bold no-underline`}
  font-family: 'Inter', sans-serif;
  color: ${({ contact, isscrolled }) =>
    isscrolled ? "white" : contact ? "black" : "white"};
`;

const HireButton = styled.button`
  ${tw`text-sm text-black hover:bg-opacity-25 font-semibold py-2 px-4 lg:py-3 lg:px-8 transition duration-300`}
  box-shadow:
    inset 0 -5px 10px rgba(255, 84, 100, 0.6),
    inset 0 0px rgba(255, 84, 100, 0.8),
    0 8px 24px rgba(0, 0, 0, 0.3),
    0 4px 12px rgba(0, 0, 0, 0.2);

  border-radius: 30px;
  border: none;
  color: black;
`;

const HireButtonLink = tw.a`text-customPurple no-underline`;

const MenuToggle = styled.button`
  ${tw`lg:hidden z-50`}
  background: transparent;
  border: 0;
  color: white;
  transition: all 0.3s ease;

  &:active {
    transform: scale(0.95);
  }
`;

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80; // Adjust based on your header height
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const HeaderComponent = ({ contact }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isscrolled, setisscrolled] = useState(false);
  // const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setisscrolled(true);
      } else {
        setisscrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
      if (window.innerWidth >= 1024) {
        setIsOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => setIsOpen(!isOpen);

  const NavLinksComponent = ({ isMobile, contact, isscrolled }) => (
    <>
      <NavLinkStyled
        to="/"
        onClick={toggleMenu}
        isMobile={isMobile}
        contact={contact}
        isscrolled={isscrolled}
      >
        Home
      </NavLinkStyled>
      <NavLinkStyled
        to="/about"
        onClick={toggleMenu}
        isMobile={isMobile}
        contact={contact}
        isscrolled={isscrolled}
      >
        About Us
      </NavLinkStyled>
      {/* Use HashLinkStyled for Services */}
      <HashLinkStyled
        smooth
        to="/#features"
        onClick={toggleMenu}
        isMobile={isMobile}
        contact={contact}
        isscrolled={isscrolled}
        scroll={scrollWithOffset} // Custom scroll function
      >
        Services
      </HashLinkStyled>
      <NavLinkStyled
        to="/contact"
        onClick={toggleMenu}
        isMobile={isMobile}
        contact={contact}
        isscrolled={isscrolled}
      >
        Contact Us
      </NavLinkStyled>
    </>
  );

  return (
    <Header isscrolled={isscrolled} contact={contact}>
      <LogoLink contact={contact} isscrolled={isscrolled} to="/">
        <LogoImg
          small={true}
          src="https://res.cloudinary.com/dhjdz1kjb/image/upload/v1735310611/MjBrandset_logo_vemnya_c_crop_w_331_h_85_qdhdbl.png"
        />
      </LogoLink>
      {isMobile ? (
        <>
          <MenuToggle isscrolled={isscrolled} onClick={toggleMenu}>
            {isOpen ? <X size={34} /> : <Menu size={34} />}
          </MenuToggle>
          <MobileNavLinks isOpen={isOpen}>
            <NavLinksComponent
              isMobile={true}
              contact={contact}
              isscrolled={isscrolled}
            />
            <HireButton onClick={toggleMenu} isscrolled={isscrolled}>
              Hire us
            </HireButton>
          </MobileNavLinks>
        </>
      ) : (
        <>
          <NavLinks>
            <NavLinksComponent
              isMobile={false}
              contact={contact}
              isscrolled={isscrolled}
            />
          </NavLinks>
          <HireButton contact={contact} isscrolled={isscrolled}>
            <HireButtonLink href="/grow">Grow With Us</HireButtonLink>
          </HireButton>
        </>
      )}
    </Header>
  );
};

export default HeaderComponent;
