import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPaintBrush,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// UX Design Company Page Component
export const UXDesignCompanyInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="UX Design Company in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted UX Design Partner</Subheading>}
        heading={
          <Heading>
            Elevate Your User Experience with{" "}
            <span tw="text-primary-500">
              Top UX Design Company in Al Barsha
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in creating user-centered designs
            that enhance usability, engagement, and business outcomes. As a
            startup, we bring fresh ideas, data-driven insights, and a
            client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?ixlib=rb-4.0.3", // UX wireframing process
          "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3", // Design team collaboration
          "https://images.unsplash.com/photo-1561070791-2526d30994b5?ixlib=rb-4.0.3", // UI/UX design workspace
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted UX Design Experts"
        description="We combine innovation, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "User-Centered Designs",
            description:
              "Our designs prioritize the needs and behaviors of your users.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Backed by research and analytics to ensure maximum usability.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Engaging Experiences",
            description:
              "We create designs that captivate and retain your audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description: "Years of experience crafting successful UX designs.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined UX Design Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your users and business goals.
              </li>
              <li>
                Wireframing & Prototyping: Create interactive mockups for
                testing.
              </li>
              <li>
                Design Development: Craft visually appealing and functional
                designs.
              </li>
              <li>
                User Testing: Gather feedback and refine designs for optimal
                performance.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixlib=rb-4.0.3", // UX research and wireframing
          "https://images.unsplash.com/photo-1559028012-481c04fa702d?ixlib=rb-4.0.3", // Interactive prototyping
          "https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?ixlib=rb-4.0.3", // User testing session
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our UX design services have helped businesses grow."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Startup Growth",
            description:
              "A local startup saw a 200% increase in user retention after redesigning their app.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a retail client’s conversion rate by 40% with improved UX design.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={
          <Subheading>Ready to Elevate Your User Experience?</Subheading>
        }
        heading="Let’s Create Impactful Designs Together"
        description={
          <Description>
            Partner with our expert UX design team in Al Barsha to unlock the
            full potential of your digital products. Whether you’re looking to
            improve usability or engage your audience effectively, we’re here to
            help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3", // Design team collaboration
          "https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?ixlib=rb-4.0.3", // UX design presentation
          "https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3", // Modern UX workspace
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
