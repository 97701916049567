import { UXDesignCompanyInAlBarsha } from "Pages/SeoPages/UIUXDesign/UXDesignCompanyInAlBarsha";
import { Helmet } from "react-helmet";

export const UXDesignCompanySEO = () => (
    <>
      <Helmet>
        <title>UX Design Company in Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a leading UX design company in Al Barsha, specializing in user-centered designs that enhance usability, engagement, and business outcomes. Partner with us to elevate your digital products."
        />
        <meta
          name="keywords"
          content="UX design company Al Barsha, user experience design Al Barsha, UI/UX design Al Barsha, web design Al Barsha"
        />
        <meta
          property="og:title"
          content="UX Design Company in Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is a leading UX design company in Al Barsha, specializing in user-centered designs that enhance usability, engagement, and business outcomes. Partner with us to elevate your digital products."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides expert UX design services in Al Barsha, helping businesses create user-centered designs that drive engagement and growth.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <UXDesignCompanyInAlBarsha />
    </>
  );
  
