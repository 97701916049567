import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPenNib,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Blog Writing Services Page Component
export const BlogWritingServicesInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Blog Writing Services in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Blog Writing Partner</Subheading>}
        heading={
          <Heading>
            Elevate Your Brand with{" "}
            <span tw="text-primary-500">Professional Blog Writing</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we craft high-quality blog content that drives traffic, boosts SEO, and engages your audience. As a startup, we bring fresh ideas, agility, and a client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1516387938699-a93567ec168e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // Writers working on laptops
          "https://plus.unsplash.com/premium_photo-1714674732174-8cfd834755d4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjF8fGNyZWF0aXZlJTIwYWdlbmN5fGVufDB8fDB8fHww", // Creative brainstorming session
          "https://media.istockphoto.com/id/1173451287/photo/indoor-shot-of-creative-team-discussing-ideas-in-business-meeting-multi-ethnic-business.webp?a=1&b=1&s=612x612&w=0&k=20&c=GdGwdVLKWO68uoRIu4v_H6pxFsuVnuQSl8_vQvs2q58=", // Team collaboration
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Blog Writing Experts"
        description="As a startup, we bring innovation, agility, and measurable results to every project."
        cards={[
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description: "Years of experience crafting impactful blog content.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description: "Backed by analytics for maximum ROI.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Engaging Storytelling",
            description: "Content that captivates and converts.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Blog Writing Process"
        description={
          <Description>
            From research to publishing, our process ensures high-quality, timely delivery:
            <ul tw="mt-4 list-disc pl-6">
              <li>Keyword Research & Strategy</li>
              <li>Content Creation & Editing</li>
              <li>SEO Optimization</li>
              <li>Performance Tracking</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1523837157348-ffbdaccfc7de?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NzJ8fGNyZWF0aXZlJTIwYWdlbmN5fGVufDB8fDB8fHww", // Workflow diagram
          "https://images.unsplash.com/photo-1512295767273-ac109ac3acfa?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNyZWF0aXZlJTIwYWdlbmN5fGVufDB8fDB8fHww", // Team brainstorming
          "https://images.unsplash.com/photo-1516387938699-a93567ec168e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // Writers working
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how we’ve helped businesses grow with our blog writing services."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Startup Growth",
            description: "Increased website traffic by 150% in 6 months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description: "Achieved 95% client retention rate.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description: "Delivered innovative content strategies for diverse industries.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Elevate Your Brand?</Subheading>}
        heading="Let’s Create Impactful Content Together"
        description={
          <Description>
            Partner with us to unlock the full potential of your blog and engage your audience effectively. As a startup, we’re committed to delivering exceptional value and building long-term relationships.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1664575262619-b28fef7a40a4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8d3JpdGVyfGVufDB8fDB8fHww", // Happy team celebrating
          "https://plus.unsplash.com/premium_photo-1714674731172-f42923983817?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjA1fHxjcmVhdGl2ZSUyMGFnZW5jeXxlbnwwfHwwfHx8MA%3D%3D", // Writers working
          "https://images.unsplash.com/photo-1552581234-26160f608093?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Y3JlYXRpdmUlMjBhZ2VuY3l8ZW58MHx8MHx8fDA%3D", // Handshake symbolizing partnership
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
}