// src/pages/BestAdvertisingAgencyDubai.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaTools,
  FaChartLine,
  FaGraduationCap,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const BestAdvertisingAgencyDubai = () => {
  return (
    <AnimationRevealPage>
      <Header from="Best Advertising Agency in Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Proven Results in Dubai</Subheading>}
        heading={
          <Heading>
            Delivering Real Impact for{" "}
            <span tw="text-primary-500">Dubai Businesses</span>
          </Heading>
        }
        description={
          <Description>
            We specialize in creating targeted advertising campaigns that drive
            measurable results for Dubai-based businesses. From education
            providers to device repair shops, we help brands connect with their
            ideal customers.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="See Our Work"
      />

      {/* Proven Results Section */}
      <StyledFeatures
        subheading={<Subheading>What We've Achieved</Subheading>}
        heading="Real Campaigns, Real Results"
        description="Here's how we've helped Dubai businesses grow:"
        cards={[
          {
            icon: <FaTools color="#ff5464" size={40} />,
            title: "Device Repair Campaign",
            description:
              "Created targeted ads for a Dubai device repair company, increasing their customer inquiries by 40%.",
          },
          {
            icon: <FaGraduationCap color="#ff5464" size={40} />,
            title: "Education Provider Ads",
            description:
              "Developed ad campaigns for a Dubai-based education company, boosting enrollment inquiries by 35%.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Tracking",
            description:
              "Implemented analytics to measure campaign success and optimize ad spend for clients.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work in Action</Subheading>}
        heading={<Heading>Campaigns That Delivered</Heading>}
        description={
          <Description>
            <strong>Device Repair Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Targeted ads for phone and laptop repair services</li>
              <li>Focused on key Dubai neighborhoods</li>
              <li>Increased customer inquiries by 40%</li>
            </ul>

            <strong tw="mt-8 block">Education Provider Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Ads promoting courses and enrollment opportunities</li>
              <li>Targeted parents and students in Dubai</li>
              <li>Boosted enrollment inquiries by 35%</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Learn More"
      />

      {/* Service Areas */}
      <Features
        subheading={<Subheading>Dubai Coverage</Subheading>}
        heading="Serving Dubai Businesses"
        description="We've worked with clients across key areas:"
        cards={[
          {
            title: "Bur Dubai",
            description: "Device repair campaigns targeting local residents",
            url:'/advertising-agency-bur-dubai'
          },
          {
            title: "Deira",
            description: "Ads for education providers and service businesses",
            url:'/advertising-agency-deira'

          },
          {
            title: "Dubai Marina",
            description: "Targeted campaigns for local businesses",
            url:'/advertising-agency-dubai-marina'
          },
          {
            title: "Business Bay",
            description:
              "Marketing for luxury real estate and corporate offices",
            url:'/advertising-agency-business-bay'
          },
          {
            title: "Jumeirah",
            description: "Promotions for high-end hotels and resorts",
            url:'/advertising-agency-jumeirah'
          },
          {
            title: "Jebel Ali",
            description: "Campaigns for industrial and logistics companies",
            url:'/advertising-agency-jebel-ali'
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
