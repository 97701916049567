// src/pages/BrandingAgencyBurDubai.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaPalette,
  FaLightbulb,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaBullseye,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const BrandingAgencyBurDubai = () => {
  return (
    <AnimationRevealPage>
      <Header from="Branding Agency in Bur Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Local Branding Partner</Subheading>}
        heading={
          <Heading>
            Building Strong Brands in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            As a branding agency in Bur Dubai, we specialize in creating unique
            and impactful brand identities that resonate with your target
            audience. From startups to established businesses, we help you stand
            out in Dubai's competitive market.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3",
            "https://images.unsplash.com/photo-1521737711867-e3b97375f902?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3",
            "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3",
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Brand Journey"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Branding Solutions"
        description="We offer a full range of branding services to help your business thrive:"
        cards={[
          {
            icon: <FaPalette color="#ff5464" size={40} />,
            title: "Brand Identity",
            description:
              "Crafting unique logos, color schemes, and visual styles.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Brand Strategy",
            description:
              "Developing a clear vision and positioning for your brand.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Brand Messaging",
            description: "Creating compelling taglines and brand stories.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Research",
            description:
              "Understanding your target market for better engagement.",
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Brand Launch",
            description: "Rolling out your brand with impactful campaigns.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Brand Management",
            description: "Ensuring consistency and growth for your brand.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Branding Success Stories</Heading>}
        description={
          <Description>
            <strong>Device Repair Branding:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Created a modern, trustworthy brand identity</li>
              <li>Increased customer trust and recognition</li>
              <li>Delivered within a small business budget</li>
            </ul>

            <strong tw="mt-8 block">Education Provider Branding:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Developed a professional and approachable brand</li>
              <li>Boosted enrollment inquiries by 35%</li>
              <li>Enhanced brand visibility across Bur Dubai</li>
            </ul>
          </Description>
        }
        
          imageSrcs={[
            "https://images.unsplash.com/photo-1581091870623-4c4e1b6d1cae?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3",
            "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3",
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3",
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Bur Dubai Branding Experts"
        description="Here’s why businesses in Bur Dubai trust us:"
        cards={[
          {
            icon: <FaPalette color="#ff5464" size={40} />,
            title: "Local Expertise",
            description:
              "We understand the unique needs of Bur Dubai businesses.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We deliver measurable outcomes for every branding project.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description: "Your vision is at the heart of everything we do.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
