import { BestAdvertisingAgencyBurDubai } from "Pages/SeoPages/Advertising/BestAdvertisingAgencyBurDubai";
import { Helmet } from "react-helmet";

export const BestAdvertisingAgencyBurDubaiPage = () => (
    <>
      <Helmet>
        <title>Best Advertising Agency in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is the best advertising agency in Bur Dubai, dedicated to helping businesses grow and succeed. From digital advertising to traditional campaigns, we provide innovative and high-quality solutions tailored to your specific needs in Bur Dubai."
        />
        <meta
          name="keywords"
          content="best advertising agency in Bur Dubai, Bur Dubai advertising, digital advertising Bur Dubai, social media advertising Bur Dubai"
        />
        <meta
          property="og:title"
          content="Best Advertising Agency in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is the best advertising agency in Bur Dubai, dedicated to helping businesses grow and succeed. From digital advertising to traditional campaigns, we provide innovative and high-quality solutions tailored to your specific needs in Bur Dubai."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset is the best advertising agency in Bur Dubai, dedicated to helping businesses grow and succeed.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <BestAdvertisingAgencyBurDubai />
    </>
  );
  
