import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaCreativeCommons,
  FaPaintBrush,
  FaVideo,
  FaUsers,
  FaHandshake,
  FaLightbulb,
  FaChartLine
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const CreativeAgencyAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Creative Agency in Al Barsha" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Creative Partner in Al Barsha</Subheading>}
        heading={
          <Heading>
            Creative Agency in{" "}
            <span tw="text-primary-500">Al Barsha</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are a creative agency in Al Barsha dedicated to
            bringing your ideas to life. From graphic design to video production,
            we provide innovative and high-quality creative solutions to help your
            business stand out and engage your audience in Al Barsha.
          </Description>
        }
        imageSrcs={[
            "https://images.pexels.com/photos/7490882/pexels-photo-7490882.jpeg?auto=compress&cs=tinysrgb&w=600", // Dubai skyline
          "https://images.pexels.com/photos/1762851/pexels-photo-1762851.jpeg?auto=compress&cs=tinysrgb&w=600", // Modern office with laptops
          "https://images.pexels.com/photos/1058100/pexels-photo-1058100.jpeg?auto=compress&cs=tinysrgb&w=600", // Team working on a project
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["65%", "60%", "45%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Creative Project"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Innovative Creative Solutions"
        description="We offer a wide range of creative services to help your business thrive in Al Barsha:"
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Graphic Design",
            description:
              "Creating stunning graphics and visuals to enhance your brand in Al Barsha.",
          },
          {
            icon: <FaVideo color="#ff5464" size={40} />,
            title: "Video Production",
            description:
              "Producing high-quality videos to promote your business in Al Barsha.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Strategy",
            description:
              "Developing a creative strategy to achieve your business goals in Al Barsha.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "User Experience Design",
            description:
              "Designing intuitive and user-friendly interfaces for your projects in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Process",
            description:
              "Working closely with you to understand your vision and needs in Al Barsha.",
          },
          {
            icon: <FaCreativeCommons color="#ff5464" size={40} />,
            title: "Creative Content Creation",
            description:
              "Generating engaging and creative content to captivate your audience in Al Barsha.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Creative Agency Success Stories</Heading>}
        description={
          <Description>
            <strong>Advertising Campaign Design:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Designed a captivating advertising campaign for a local business in Al Barsha</li>
              <li>Increased brand awareness by 50%</li>
              <li>Generated 40% more leads</li>
            </ul>
            <strong tw="mt-8 block">Corporate Branding:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Created a professional and engaging corporate branding campaign in Al Barsha</li>
              <li>Enhanced brand recognition and loyalty in Al Barsha</li>
              <li>Boosted sales by 35%</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1547658719-da2b51169166?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdlYiUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww", // Server room
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress&cs=tinysrgb&w=600", // Dubai skyline
          "https://images.pexels.com/photos/2860810/pexels-photo-2860810.jpeg?auto=compress&cs=tinysrgb&w=600", // Data center
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Creative Experts in Al Barsha"
        description="Here’s why businesses in Al Barsha trust us for their creative needs:"
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Innovative Ideas",
            description:
              "Our team generates innovative and creative ideas to set your business apart in Al Barsha.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Success",
            description:
              "We have a track record of delivering successful creative projects in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Focus",
            description: "Your goals and vision are at the heart of everything we do in Al Barsha.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

