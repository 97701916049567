import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaBullhorn,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Social Media Managers Page Component
export const SocialMediaManagersInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Social Media Managers in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Social Media Managers</Subheading>}
        heading={
          <Heading>
            Elevate Your Brand with{" "}
            <span tw="text-primary-500">
              Expert Social Media Managers in Al Barsha
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, our skilled social media managers craft tailored
            strategies to grow your brand, engage your audience, and drive
            measurable results. As a startup, we bring fresh ideas, data-driven
            insights, and a client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
          "https://img.freepik.com/free-photo/social-media-marketing-concept-marketing-with-applications_23-2150063164.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Social media team
          "https://img.freepik.com/premium-photo/bangkok-thailand-august-06-2021-people-hold-smartphones-different-brands-operating-systems-with-various-logos-social-applications-twitter-instagram-tiktok-linkedin-line-youtube_102814-4626.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Dubai skyline
          "https://img.freepik.com/premium-vector/realistic-social-media-background_52683-166524.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Team working
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={
          <Subheading>Why Choose Our Social Media Managers?</Subheading>
        }
        heading="Your Trusted Social Media Experts"
        description="Our team combines innovation, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Targeted Campaigns",
            description:
              "Our managers create campaigns that reach your ideal audience across platforms.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Backed by analytics to ensure maximum ROI and campaign performance.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Engagement",
            description:
              "We foster meaningful connections with your audience through engaging content.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience managing successful social media campaigns.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every campaign.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How Our Social Media Managers Work</Subheading>}
        heading="Our Streamlined Social Media Management Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Strategy Development: Create tailored plans for your goals.
              </li>
              <li>
                Content Creation: Design engaging, high-quality posts and
                campaigns.
              </li>
              <li>
                Performance Tracking: Monitor results and optimize strategies.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://img.freepik.com/free-photo/social-media-marketing-concept-marketing-with-applications_23-2150063134.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Social media team
          "https://img.freepik.com/free-photo/hand-holding-smartphone-social-media-concept_23-2150208245.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Dubai skyline
          "https://img.freepik.com/free-photo/medium-shot-men-holding-smartphone_23-2150208243.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Team working
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our social media managers have helped businesses grow."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Startup Growth",
            description:
              "A local startup saw a 500% increase in social media engagement within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a retail client’s sales by 70% with targeted social media campaigns.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Elevate Your Brand?</Subheading>}
        heading="Let’s Drive Results Together"
        description={
          <Description>
            Partner with our expert social media managers to unlock the full
            potential of your social media strategy. Whether you’re looking to
            boost your online presence or engage your audience effectively,
            we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjB8fGNsaWVudHxlbnwwfHwwfHx8MA%3D%3D", // Happy team celebrating
        ]}
        widths={["70%"]}
        heights={["70%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={["left: 15%; top: 15%;"]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
