// src/pages/ExpertAdvertisingAgency.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import { FaLightbulb, FaChartLine, FaTrophy } from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const ExpertAdvertisingAgency = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from={"Expert Advertising Agency in Bur Dubai"} />

      {/* Main Feature Section */}
      <MainFeature1
        subheading={<Subheading>Expert Advertising Agency in Bur Dubai</Subheading>}
        heading={
          <Heading>
            Transform Your Brand with <span tw="text-primary-500">MilaajBrandset</span>
          </Heading>
        }
        buttonRounded={false}
        primaryButtonText="Get Started"
        primaryButtonUrl="/get-started"
        imageSrcs={[
          "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Team collaboration
          "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Office meeting
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Business strategy
        ]}
        description={
          <Description>
            MilaajBrandset is a leading expert advertising agency in Bur Dubai. We specialize in crafting innovative and results-driven campaigns that elevate your brand. Our team of professionals combines creativity and expertise to deliver exceptional results for your business.
          </Description>
        }
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle={`height: 400px;`}
        imageStyle={`width: 45%; height: 60%;`}
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        textOnLeft={false}
      />

      {/* Our Approach Section */}
      <MainFeature1
        subheading={<Subheading>Our Approach</Subheading>}
        heading={<Heading>Innovative Strategies for Success</Heading>}
        buttonRounded={false}
        primaryButtonText="Learn More"
        primaryButtonUrl="/our-approach"
        imageSrcs={[
          "https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Data analysis
          "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Creative brainstorming
          "https://images.unsplash.com/photo-1556761175-b413ae4e686c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Business growth
        ]}
        description={
          <Description>
            At MilaajBrandset, we take a data-driven and creative approach to advertising. By analyzing market trends and consumer behavior, we develop tailored strategies that ensure your brand stands out. Our goal is to deliver measurable success and drive your business forward.
          </Description>
        }
        textOnLeft={true}
        widths={["50%", "55%", "70%"]}
        heights={["60%", "70%", "40%"]}
        imageColumnStyle={`height: 400px;`}
        imageStyle={`width: 45%; height: 60%;`}
        imagePositions={[
          "left: 13%; top: 20%;",
          "right: 0; top: 10%;",
          "left: 10%; bottom: 0;",
        ]}
      />

      {/* Our Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Services for Your Brand"
        description="As an expert advertising agency in Bur Dubai, MilaajBrandset offers a wide range of services to help your brand grow and succeed in a competitive market."
        cards={[
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Campaigns",
            description:
              "We design and execute creative campaigns that resonate with your audience and drive engagement.",
            url: "/contact",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Our data-driven approach ensures your campaigns are optimized for maximum ROI and impact.",
            url: "/contact",
          },
          {
            icon: <FaTrophy color="#ff5464" size={40} />,
            title: "Brand Excellence",
            description:
              "We focus on delivering excellence in branding, ensuring your business stands out in the market.",
            url: "/contact",
          },
        ]}
        linkText="View All Services"
      />
    </AnimationRevealPage>
  );
};

export default ExpertAdvertisingAgency;