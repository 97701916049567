import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Card = styled.div`
  ${tw`flex flex-col items-center bg-white py-4 rounded-xl cursor-pointer transition-all duration-300 border-2`} /* Add border-2 to ensure the border is visible */
  ${({ isSelected }) => 
    isSelected
      ? tw`bg-customPurple border-customPurple text-black`
      : tw`shadow-outlineCustom `} 
  &:hover {
    ${tw`bg-customPinkLight border-customPurple`} /* Ensure hover state also defines the border */
  }


  .textContainer {
    ${tw`text-center `}
  }

  .title {
    ${tw`mt-2 font-semibold text-sm`}
  }

  .description {
    ${tw`mt-1 text-sm`}
    ${({ isSelected }) => 
    isSelected
      ? tw`text-black`
      : tw`text-gray-600`}
  }
`;

const ServiceCard = ({ title, isSelected, onClick }) => (
  <Card isSelected={isSelected} onClick={onClick}>
    <div className="imageContainer">
    </div>
    <div className="textContainer">
      <h4 className="title">{title}</h4>
    </div>
  </Card>
);

export default ServiceCard;
