// src/pages/ProfessionalAdvertisingAgency.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import { FaLightbulb, FaChartLine, FaTrophy } from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const ProfessionalAdvertisingAgency = () => {
  return (
    <AnimationRevealPage>
     

      <Header from={"Professional Advertising Agency in Bur Dubai"} />

      {/* Main Feature Section */}
      <MainFeature1
        subheading={<Subheading>Professional Advertising Agency in Bur Dubai</Subheading>}
        heading={
          <Heading>
            Elevate Your Brand with <span tw="text-primary-500">MilaajBrandset</span>
          </Heading>
        }
        buttonRounded={false}
        primaryButtonText="Get Started"
        primaryButtonUrl="/get-started" // URL for the button
        imageSrcs={[
          "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Team collaboration
          "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Office meeting
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Business strategy
        ]}
        description={
          <Description>
            MilaajBrandset is recognized as a professional advertising agency in Bur Dubai. We specialize in creating impactful campaigns that drive results. Our team of experts combines creative strategies with data-driven insights to deliver exceptional value to our clients.
          </Description>
        }
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]} // Custom positions for each image
        textOnLeft={false}
      />

      {/* Our Approach Section */}
      <MainFeature1
        subheading={<Subheading>Our Approach</Subheading>}
        heading={<Heading>Data-Driven Creative Strategies</Heading>}
        buttonRounded={false}
        primaryButtonText="Learn More"
        primaryButtonUrl="/our-approach" // URL for the button
        imageSrcs={[
          "https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Data analysis
          "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Creative brainstorming
          "https://images.unsplash.com/photo-1556761175-b413ae4e686c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80", // Business growth
        ]}
        description={
          <Description>
            At MilaajBrandset, our approach is rooted in data and focused on creativity. We analyze market trends, customer behavior, and competitive landscapes to develop tailored advertising strategies. Our goal is to deliver measurable results and drive your brand’s success.
          </Description>
        }
        textOnLeft={true}
        widths={["50%", "55%", "70%"]}
        heights={["60%", "70%", "40%"]}
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 13%; top: 20%;",
          "right: 0; top: 10%;",
          "left: 10%; bottom: 0;",
        ]} // Custom positions for each image
      />

      {/* Our Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Exceptional Services for Exceptional Results"
        description="As a professional advertising agency in Bur Dubai, MilaajBrandset offers a comprehensive suite of services designed to meet your unique needs and drive your brand forward."
        cards={[
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Strategies",
            description:
              "We develop innovative and engaging campaigns that capture attention and drive action.",
            url: "/contact",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Results",
            description:
              "Our data-driven approach ensures that every campaign is optimized for maximum impact and ROI.",
            url: "/contact",
          },
          {
            icon: <FaTrophy color="#ff5464" size={40} />,
            title: "Client Success",
            description:
              "We prioritize client satisfaction and success, delivering measurable results and exceptional service.",
            url: "/contact",
          },
        ]}
        linkText="View All Services"
      />
    </AnimationRevealPage>
  );
};

export default ProfessionalAdvertisingAgency;