import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from "Components/headers/PageHeader";

import BoostBrandImage from '../images/BoostBrandImage.png'


const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header from={"Your Brand Deserve's More!"}/>
      <MainFeature1 from ={'boost'}
        subheading={<Subheading>About MilaajBrandset</Subheading>}
        heading="Your Brand Deserves to Shine"
        primaryButtonText="See Portfolio"
        primaryButtonUrl="/contact" // Add the URL for the button
        imageSrcs={[
            BoostBrandImage
        ]}    
        description="With cutting-edge Social Media Management, Strategic Brand Positioning, Creative Content Creation, Video Production, Paid Media Campaigns, and more, we have everything your brand needs to shine and stand out from the competition. Let us take your brand to new heights!"
        textOnLeft={false} // Text on left for this one
      
      />

    
      {/* <TeamCardGrid 
        subheading={<Subheading>Meet Our Team</Subheading>}
      /> */}
    </AnimationRevealPage>
  );
};
