import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaAward,
  FaCode,
  FaUsers,
  FaChartLine,
  FaBullseye,
  FaHandshake,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const BestWebsiteDesignCompanyBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Best Website Design Company in Bur Dubai" />
      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Web Design Partner</Subheading>}
        heading={
          <Heading>
            The Best Website Design Company in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we pride ourselves on being the best website design company in Bur Dubai. As a startup, we bring innovation, creativity, and technical expertise to every project. Whether you need a sleek corporate website, an e-commerce platform, or a custom solution, we deliver designs that captivate audiences and drive results.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Modern web design
          "https://images.unsplash.com/photo-1585834275571-442109c918d2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // E-commerce design
          "https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Creative process
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Discover Our Services"
      />
      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Expertise</Subheading>}
        heading="Comprehensive Website Design Solutions in Bur Dubai"
        description="We offer a wide range of services to meet all your website design needs:"
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Award-Winning Designs",
            description:
              "Creating visually stunning and functional websites that stand out in Bur Dubai.",
          },
          {
            icon: <FaCode color="#ff5464" size={40} />,
            title: "Custom Development",
            description:
              "Building tailor-made solutions to match your business goals in Bur Dubai.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "User-Centric Approach",
            description:
              "Designing intuitive interfaces that prioritize user experience in Bur Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Optimization",
            description:
              "Ensuring fast load times and seamless performance for better engagement in Bur Dubai.",
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "SEO Integration",
            description:
              "Incorporating SEO strategies to boost your website's visibility in search engines in Bur Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Process",
            description:
              "Working closely with you to understand your vision and deliver exceptional results in Bur Dubai.",
          },
        ]}
      />
      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Success Stories</Subheading>}
        heading={<Heading>Why We’re the Best in Bur Dubai</Heading>}
        description={
          <Description>
            <strong>Corporate Website Redesign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Redesigned a corporate website for a local business in Bur Dubai.</li>
              <li>Achieved a 40% increase in lead generation within six months.</li>
              <li>Improved user engagement with modern design and functionality.</li>
            </ul>
            <strong tw="mt-8 block">E-commerce Platform Development:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Built a fully functional e-commerce platform for a retail client in Bur Dubai.</li>
              <li>Increased online sales by 30% through optimized design and UX.</li>
              <li>Enhanced customer trust with secure payment gateways and responsive design.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Corporate website
          "https://images.unsplash.com/photo-1585834275571-442109c918d2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // E-commerce platform
          "https://images.unsplash.com/photo-1587614387466-0a72ca909e16?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Analytics dashboard
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />
      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Best Website Design Company in Bur Dubai"
        description="Here’s why businesses in Bur Dubai trust us for their web design needs:"
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "We have a history of delivering successful projects for clients in Bur Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Using analytics to create websites that perform exceptionally well in Bur Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your satisfaction and success are our top priorities in Bur Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

