import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import { FaTrophy, FaChartBar, FaLightbulb } from "react-icons/fa"; // Import icons from react-icons/fa

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const LocalAdvertisingAgency = () => {
  return (
    <AnimationRevealPage>
      <Header from={"Local advertising agency near Bur Dubai"} />

      {/* Main Feature Section */}
      <MainFeature1
        subheading={<Subheading>Local advertising agency near Bur Dubai</Subheading>}
        heading={
          <Heading>
            Discover <span tw="text-primary-500">Local Advertising Solutions</span>
          </Heading>
        }
        buttonRounded={false}
        primaryButtonText="Learn More"
        primaryButtonUrl="/about-us" // URL for the button
        imageSrcs={[
          "https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
        ]}
        description={
          <Description>
            MilaajBrandset is a prominent Local advertising agency near Bur Dubai, known for delivering effective and tailored advertising solutions. Our local insights and expertise ensure that your brand resonates deeply with the local audience.
          </Description>
        }
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]} // Custom positions for each image
        textOnLeft={false}
      />

      {/* Our Services Section */}
      <MainFeature1
        subheading={<Subheading>Our Services</Subheading>}
        heading={<Heading>Comprehensive Advertising Services</Heading>}
        buttonRounded={false}
        primaryButtonText="Explore Services"
        primaryButtonUrl="/services" // URL for the button
        imageSrcs={[
          "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          "https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
        ]}
        description={
          <Description>
            At MilaajBrandset, we offer a wide range of services including digital marketing, brand strategy, and creative campaigns. Our local presence and deep understanding of the Bur Dubai market ensure that your campaigns are highly effective and relevant.
          </Description>
        }
        textOnLeft={true}
        widths={["50%", "55%", "70%"]}
        heights={["60%", "70%", "40%"]}
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 13%; top: 20%;",
          "right: 0; top: 10%;",
          "left: 10%; bottom: 0;",
        ]} // Custom positions for each image
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading={
          <Heading>
            Why Choose <span tw="text-primary-500">MilaajBrandset</span>
          </Heading>
        }
        description="MilaajBrandset stands out as a Local advertising agency near Bur Dubai due to our commitment to excellence, innovative strategies, and client satisfaction."
        cards={[
          {
            icon: <FaTrophy color="#ff5464" size={48} />, // Use react-icons/fa icon
            title: "Top-Rated Agency",
            description:
              "Recognized as a leading local advertising agency in Bur Dubai, MilaajBrandset delivers exceptional results.",
            url: "/contact",
          },
          {
            icon: <FaChartBar color="#ff5464" size={48} />, // Use react-icons/fa icon
            title: "Data-Driven Strategies",
            description:
              "Our campaigns are backed by thorough market research and data analysis to ensure success.",
            url: "/contact",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={48} />, // Use react-icons/fa icon
            title: "Innovative Solutions",
            description:
              "We continuously innovate to provide fresh and effective advertising solutions.",
            url: "/contact",
          },
          // Add more services if needed
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};

export default LocalAdvertisingAgency;