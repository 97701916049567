import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaLaptopCode,
  FaBullhorn,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Full-Service Digital Agency Page Component
export const FullServiceDigitalAgencyInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Full-Service Digital Agency in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Your Trusted Full-Service Digital Partner in Bur Dubai
          </Subheading>
        }
        heading={
          <Heading>
            Leading Full-Service Digital Agency in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are a full-service digital agency in Bur
            Dubai, offering end-to-end solutions to help businesses grow and
            succeed. From branding to digital marketing, we deliver innovative
            strategies tailored to your unique needs.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/7710082/pexels-photo-7710082.jpeg?auto=compress&cs=tinysrgb&w=600", // Dubai skyline
          "https://images.pexels.com/photos/6476808/pexels-photo-6476808.jpeg?auto=compress&cs=tinysrgb&w=600", // Team working on a project
          "https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=600", // Modern office with laptops
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
      />

      {/* Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Digital Solutions for Your Business"
        description="Explore our full range of services designed to help your business thrive in Bur Dubai:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Website Development",
            description:
              "Build high-performance websites that engage and convert visitors.",
          },
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Digital Marketing",
            description:
              "Drive traffic and generate leads with SEO, PPC, and social media campaigns.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Analytics",
            description:
              "Track and optimize your digital performance with data-driven insights.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "User Experience (UX) Design",
            description:
              "Design intuitive interfaces that enhance customer satisfaction.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Strategy",
            description:
              "Work closely with our team to align your vision with actionable plans.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Brand Identity",
            description:
              "Craft a unique and memorable brand identity that resonates with your audience.",
          },
        ]}
      />

      {/* Success Stories Section */}
      <MainFeature1
        subheading={<Subheading>Our Impact</Subheading>}
        heading={<Heading>Success Stories in Bur Dubai</Heading>}
        description={
          <Description>
            <strong>Local Retailer Growth:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Built a fully functional e-commerce platform for a retail
                client.
              </li>
              <li>Increased online sales by 60% within six months.</li>
              <li>Improved website speed and search engine rankings.</li>
            </ul>
            <strong tw="mt-8 block">Startup Brand Awareness:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Revamped a startup's brand to appeal to a younger audience in
                Bur Dubai.
              </li>
              <li>Achieved a 25% boost in brand loyalty and trust.</li>
              <li>Enhanced online presence across platforms.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/3194519/pexels-photo-3194519.jpeg?auto=compress&cs=tinysrgb&w=600", // Dubai skyline
          "https://images.pexels.com/photos/590045/pexels-photo-590045.jpeg?auto=compress&cs=tinysrgb&w=600", // Team working on a project
          "https://images.pexels.com/photos/6229/marketing-board-strategy.jpg?auto=compress&cs=tinysrgb&w=600", // Modern office with laptops
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Discover More Success Stories"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Full-Service Digital Experts in Bur Dubai"
        description="Here’s why businesses in Bur Dubai trust us as their digital partner:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "End-to-End Solutions",
            description:
              "We offer comprehensive services under one roof, saving you time and effort.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "Our track record speaks for itself—delivering impactful solutions in Bur Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the core of everything we do in Bur Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
