import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaSearch,
  FaChartBar,
  FaBullseye,
  FaUsers,
  FaHandshake,
  FaCog,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const SeoAgencyDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="SEO Agency in Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your SEO Partner in Dubai</Subheading>}
        heading={
          <Heading>
            Boost Your Online Presence in{" "}
            <span tw="text-primary-500">Dubai</span>
          </Heading>
        }
        description={
          <Description>
            As an SEO agency in Dubai, we specialize in driving organic traffic
            and improving search engine rankings for businesses like yours. From
            keyword research to technical optimization, we ensure your website
            ranks higher and attracts more customers in the competitive Dubai
            market.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://plus.unsplash.com/premium_photo-1683402693577-035defb30159?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTAxfHxzZW98ZW58MHx8MHx8fDA%3D",
          "https://images.unsplash.com/photo-1686061594225-3e92c0cd51b0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTAwfHxzZW98ZW58MHx8MHx8fDA%3D",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your SEO Journey"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive SEO Solutions"
        description="We offer a full range of SEO services to help your business thrive online:"
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Keyword Research",
            description:
              "Identifying high-value keywords to target your audience in Dubai.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "On-Page Optimization",
            description:
              "Optimizing content, meta tags, and URLs for better rankings in Dubai search engines.",
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Technical SEO",
            description:
              "Improving site speed, mobile-friendliness, and crawlability in Dubai.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Content Strategy",
            description:
              "Creating engaging and SEO-friendly content tailored for the Dubai market.",
          },
          {
            icon: <FaCog color="#ff5464" size={40} />,
            title: "Link Building",
            description:
              "Building high-quality backlinks to boost domain authority in Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Performance Tracking",
            description:
              "Monitoring and reporting on key SEO metrics for continuous improvement in Dubai.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>SEO Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Store Optimization:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Increased organic traffic by 60%</li>
              <li>Achieved top rankings for competitive keywords in Dubai</li>
              <li>Generated 40% more leads within 3 months</li>
            </ul>
            <strong tw="mt-8 block">Local Business SEO:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Improved local search visibility significantly in Dubai</li>
              <li>Grew Google My Business clicks by 50%</li>
              <li>
                Enhanced customer engagement through targeted campaigns in Dubai
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://plus.unsplash.com/premium_photo-1661678263122-e443e90f320c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTA5fHxzZW98ZW58MHx8MHx8fDA%3D",
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8aGFwcHklMjBjdXN0b21lcnxlbnwwfHwwfHx8MA%3D%3D",
          "https://plus.unsplash.com/premium_photo-1684356818924-7141a5503266?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTE3fHxzZW98ZW58MHx8MHx8fDA%3D",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Dubai SEO Experts"
        description="Here’s why businesses in Dubai trust us for their SEO needs:"
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Local Expertise",
            description:
              "We understand the unique SEO challenges of Dubai businesses.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We deliver measurable improvements in rankings and traffic in Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your success is our priority in every project in Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
