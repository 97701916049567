// src/pages/BrandIdentityDevelopment.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import ContactRightColum from "Components/Forms/ContactFormCol";

import { FaPaintBrush, FaBullhorn, FaBook } from "react-icons/fa";

// If you have specific icons as images, import them here
// import CustomLogoIcon from "images/custom-logo-icon.svg";
// import ConsistentMessagingIcon from "images/consistent-messaging-icon.svg";
// import StyleGuidesIcon from "images/style-guides-icon.svg";
// import InnovativeConceptsIcon from "images/innovative-concepts-icon.svg";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const LeftColumn = tw.div`w-[95%] md:w-3/5 px-4 flex flex-col space-y-8`;
const RightColumn = styled.div`
  ${tw`w-full md:w-2/5 flex items-center justify-center mt-12 md:mt-0`}
`;

// Adjust TwoColumn to include overflow-hidden and ensure centering
const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-center items-stretch gap-10 max-w-screen-4xl mx-auto py-12 md:py-16 lg:py-16 overflow-hidden`}
`;

// Main Component
const BrandIdentityDevelopment = () => {
  return (
    <AnimationRevealPage>
      <Header from={"Brand Identity Development"} />
      <TwoColumn>
        <LeftColumn>
          {/* Main Feature Section */}
          <MainFeature1
            subheading={<Subheading>Brand Identity Services</Subheading>}
            heading={
              <Heading>
                Craft Your <span tw="text-primary-500">Unique Brand Identity</span>
              </Heading>
            }
            buttonRounded={false}
            primaryButtonText="Get Started"
            primaryButtonUrl="/get-started" // URL for the button
            description={
              <Description>
                At MilaajBrandset, we specialize in crafting distinctive brand identities that tell your story and resonate with your audience. Our comprehensive services are designed to create a cohesive brand image that reflects your values and stands out in the market. Whether you're launching a new brand or rebranding an existing one, our team of experts is here to help.
              </Description>
            }
            widths={["55%", "60%", "70%"]}
            heights={["45%", "60%", "35%"]}
            imageColumnStyle={{ height: "400px" }} // Adjusting height as required
            imageStyle={{ width: "45%", height: "60%" }} // Custom styles for images
            imagePositions={[
              "left: 35%; top: 20%;",
              "right: 30%; top: 10%;",
              "left: 32%; bottom: 20%;",
            ]} // Custom positions for each image
            textOnLeft={true}
          />

          {/* Our Approach Section */}
          <MainFeature1
            subheading={<Subheading>Our Approach</Subheading>}
            heading={<Heading>Strategic Brand Development</Heading>}
            buttonRounded={false}
            primaryButtonText="Learn More"
            primaryButtonUrl="/our-approach" // URL for the button
            description={
              <Description>
                Our approach is rooted in understanding your business and your audience. We conduct thorough market research, analyze your competitors, and assess your current brand positioning to develop a tailored brand strategy. From logo design and messaging to style guides and innovative concepts, we ensure every aspect of your brand identity is meticulously crafted to achieve lasting impact.
              </Description>
            }
            textOnLeft={true}
            widths={["50%", "55%", "70%"]}
            heights={["60%", "70%", "40%"]}
            imageColumnStyle={{ height: "400px" }} // Adjusting height as required
            imageStyle={{ width: "45%", height: "60%" }} // Custom styles for images
            imagePositions={[
              "left: 13%; top: 20%;",
              "right: 0; top: 10%;",
              "left: 10%; bottom: 0;",
            ]} // Custom positions for each image
          />
        </LeftColumn>
        <RightColumn>
          <ContactRightColum />
        </RightColumn>
      </TwoColumn>

      {/* Our Brand Identity Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Brand Identity Services</Subheading>}
        heading="Comprehensive Brand Solutions"
        description="MilaajBrandset offers a full suite of brand identity development services designed to create a memorable and impactful brand presence. Our services are tailored to meet your unique needs, ensuring a cohesive and effective brand strategy."
        cards={[
          {
            icon: <FaPaintBrush size={40} color="#ff5464" />, // Example with react-icons
            title: "Custom Logo Design",
            description: "Memorable logos that embody your brand’s essence.",
            url: "/brand/custom-logo-design",
          },
          {
            icon: <FaBullhorn size={40} color="#ff5464" />,
            title: "Consistent Messaging",
            description: "Unified brand voice across all platforms.",
            url: "/brand/consistent-messaging",
          },
          {
            icon: <FaBook size={40} color="#ff5464" />,
            title: "Style Guides",
            description: "Detailed manuals for typography and color schemes.",
            url: "/brand/style-guides",
          },
          // {
          //   icon: <FaLightbulb size={40} color="#ff5464" />,
          //   title: "Innovative Concepts",
          //   description: "Creative visuals and slogans that impress.",
          //   url: "/brand/innovative-concepts",
          // },
          // Add more services if needed
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};

export default BrandIdentityDevelopment;
