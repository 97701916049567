// src/pages/No1AdvertisingAgencyDubai.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import { 
  FaChartLine, 
  FaUsers, 
  FaHandshake, 
  FaBullseye,
  FaLightbulb,
  FaPaintBrush
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const No1AdvertisingAgencyDubai = () => {
  return (
    <AnimationRevealPage>
      <Header from="No.1 Advertising Agency in Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Advertising Partner</Subheading>}
        heading={
          <Heading>
            Delivering Excellence for <span tw="text-primary-500">Dubai Businesses</span>
          </Heading>
        }
        description={
          <Description>
            As a fast-growing advertising agency in Dubai, we are committed to helping businesses achieve their goals through innovative strategies, creative campaigns, and measurable results. While we may be new, our passion and dedication set us apart.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Partner With Us"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="What Makes Us Stand Out"
        description="Here’s why Dubai businesses trust us:"
        cards={[
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description: "We deliver measurable outcomes for every campaign."
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description: "Your goals are our priority—we work closely with you to achieve them."
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Precision Targeting",
            description: "We ensure your ads reach the right audience at the right time."
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Innovative Strategies",
            description: "We stay ahead of trends to keep your campaigns fresh and effective."
          },
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description: "Our designs captivate and convert your target audience."
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Transparent Communication",
            description: "We keep you informed every step of the way."
          }
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Real Campaigns, Real Results</Heading>}
        description={
          <Description>
            <strong>Device Repair Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Increased customer inquiries by 40%</li>
              <li>Targeted ads for Bur Dubai residents</li>
              <li>Delivered within a small business budget</li>
            </ul>

            <strong tw="mt-8 block">Education Provider Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Boosted enrollment inquiries by 35%</li>
              <li>Ads targeting parents and students in Deira</li>
              <li>Cost-effective strategy with measurable results</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Service Areas */}
      <Features
        subheading={<Subheading>Dubai Coverage</Subheading>}
        heading="Serving Businesses Across Dubai"
        description="We work with clients in key areas:"
        cards={[
          {
            title: "Bur Dubai",
            description: "Targeted campaigns for local residents and businesses"
          },
          {
            title: "Deira",
            description: "Ads for education providers, retail, and service businesses"
          },
          {
            title: "Dubai Marina",
            description: "Promotions for luxury brands and lifestyle businesses"
          },
          {
            title: "Business Bay",
            description: "Corporate and real estate marketing solutions"
          },
          {
            title: "Jumeirah",
            description: "Campaigns for high-end hospitality and tourism"
          },
          {
            title: "Jebel Ali",
            description: "Industrial and logistics marketing strategies"
          }
        ]}
      />
    </AnimationRevealPage>
  );
};

