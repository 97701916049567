import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "Components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "Components/headers/light.js";
import { SectionHeading } from "Components/misc/Headings";
import { PrimaryButton } from "Components/misc/Buttons";
import { Helmet } from "react-helmet";

const HeadingRow = tw.div`flex justify-center`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = styled.div`
  ${tw`mt-6 flex flex-wrap -m-4`}
`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 p-6`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = styled(Link)`
  ${tw`cursor-pointer flex flex-col bg-gray-100 rounded-lg transition duration-300 hover:shadow-lg`}
`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

const Blog = ({
  headingText = "Latest Blog Posts"
}) => {
  const [posts, setPosts] = useState([]);
  const [visible, setVisible] = useState(3);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const response = await axios.get('http://localhost:8000/blogs/api/posts/');
        console.log(response.data); // Log the response data
        setPosts(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const onLoadMoreClick = () => {
    setVisible(v => v + 3);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading posts.</div>;
  }

  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Blog | MilaajBrandset</title>
        <meta name="description" content="Read our latest blog posts on web development, SEO, digital marketing, e-commerce, and more. Stay updated with industry trends and insights from MilaajBrandset." />
        <meta name="keywords" content="MilaajBrandset, blog, web development, SEO, digital marketing, e-commerce, industry trends" />
      </Helmet>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post to={`/blog/${post.id}/${post.title}`} className="group">
                  <Image imageSrc={post.image_url} />
                  <Info>
                    <Category>{post.category.name}</Category>
                    <CreationDate>{new Date(post.pub_date).toLocaleDateString()}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.content && <Description>{post.content.substring(0, 150)}...</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};

export default Blog;