import { BrandingAgencyBurDubai } from "Pages/SeoPages/Branding/bur_dubai/BrandingAgencyBurDubai";
import { BrandingAgencyDubai } from "Pages/SeoPages/Branding/dubai/BrandingAgencyDubai";


const { Helmet } = require("react-helmet");

export const BrandingAgencyBurDubaiPage = () => (
    <>
      <Helmet>
        <title>Branding Agency in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a branding agency in Bur Dubai, offering creative and strategic branding solutions for businesses. From brand identity to messaging, we help you stand out."
        />
        <meta
          name="keywords"
          content="branding agency in Bur Dubai, Bur Dubai branding, brand identity Dubai, creative branding Dubai"
        />
        <meta
          property="og:title"
          content="Branding Agency in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="A branding agency in Bur Dubai offering creative and strategic branding solutions for businesses. From brand identity to messaging, we help you stand out."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "A branding agency in Bur Dubai offering creative and strategic branding solutions for businesses.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              },
              
            }
          `}
        </script>
      </Helmet>
      <BrandingAgencyBurDubai />
    </>
  );









export const BrandingAgencyDubaiPage = () => (
    <>
      <Helmet>
        <title>Branding Agency in Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a branding agency in Dubai, offering creative and strategic branding solutions for businesses. From brand identity to messaging, we help you stand out in Dubai's competitive market."
        />
        <meta
          name="keywords"
          content="branding agency in Dubai, Dubai branding, brand identity Dubai, creative branding Dubai"
        />
        <meta
          property="og:title"
          content="Branding Agency in Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="A branding agency in Dubai offering creative and strategic branding solutions for businesses. From brand identity to messaging, we help you stand out in Dubai's competitive market."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "A branding agency in Dubai offering creative and strategic branding solutions for businesses.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <BrandingAgencyDubai />
    </>
  );
  
