import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaLaptopCode,
  FaServer,
  FaUsers,
  FaHandshake,
  FaChartLine,
  FaWrench,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const WebsiteMaintenanceAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Website Maintenance in Al Barsha" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Website Maintenance Partner in Al Barsha</Subheading>}
        heading={
          <Heading>
            Reliable Website Maintenance in{" "}
            <span tw="text-primary-500">Al Barsha</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we offer comprehensive website maintenance services
            in Al Barsha to ensure your website remains up-to-date and performs
            optimally. From regular updates to technical support, we help maintain
            your online presence and drive growth in Al Barsha.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
            "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress&cs=tinysrgb&w=600", // Server room
            "https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg?ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Programmer working
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Website Maintenance"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Website Maintenance Solutions"
        description="We offer a full range of website maintenance services to help your business thrive online in Al Barsha:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Regular Updates",
            description:
              "Keeping your website up-to-date with the latest content and features in Al Barsha.",
          },
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "Technical Support",
            description:
              "24/7 technical support to resolve any issues quickly in Al Barsha.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Content Management",
            description:
              "Managing and updating your website content to keep it fresh and engaging in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "SEO Integration",
            description:
              "Incorporating SEO best practices to improve your website's search engine rankings in Al Barsha.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Monitoring",
            description:
              "Monitoring website performance and providing regular reports in Al Barsha.",
          },
          {
            icon: <FaWrench color="#ff5464" size={40} />,
            title: "Security Enhancements",
            description:
              "Enhancing website security to protect your business in Al Barsha.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Website Maintenance Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Platform Maintenance:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Maintained a user-friendly e-commerce platform in Al Barsha</li>
              <li>Ensured smooth transactions and high customer satisfaction</li>
              <li>Reduced downtime by 80%</li>
            </ul>
            <strong tw="mt-8 block">Corporate Website Maintenance:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Managed a corporate website to improve user experience</li>
              <li>Enhanced brand visibility and engagement in Al Barsha</li>
              <li>Boosted lead generation by 35%</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1547658719-da2b51169166?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdlYiUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww", // Server room
            "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8d2ViJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D", // Data center
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Website Maintenance Experts in Al Barsha"
        description="Here’s why businesses in Al Barsha trust us for their website maintenance needs:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Expert Knowledge",
            description:
              "Our team has extensive experience in website maintenance and understands the latest trends in Al Barsha.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We have a track record of delivering reliable website maintenance services in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description: "Your goals and vision are at the heart of everything we do in Al Barsha.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

