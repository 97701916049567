import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPaintBrush,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Creative Branding Agency Page Component
export const CreativeBrandingAgencyInDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Creative Branding Agency in Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Creative Branding Partner</Subheading>}
        heading={
          <Heading>
            Elevate Your Brand with{" "}
            <span tw="text-primary-500">Creative Branding Agency in Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in crafting innovative and visually stunning branding solutions that resonate with modern audiences. As a creative branding agency in Dubai, we bring fresh ideas, data-driven insights, and a client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1626785774573-4b799315345d?w=1000", // Creative workspace with colorful design elements
            "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
            "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3",
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Creative Branding Experts"
        description="We combine innovation, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Innovative Brand Identities",
            description:
              "Our designs stand out with fresh and creative concepts.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Backed by research to ensure maximum impact and ROI.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience crafting successful creative brands.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Creative Branding Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business and target audience.</li>
              <li>Strategy Development: Create tailored plans for your goals.</li>
              <li>Design Development: Craft visually appealing and cohesive brand elements.</li>
              <li>Implementation: Roll out your brand identity across platforms.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?w=1000", // Creative design process
            "https://images.unsplash.com/photo-1600132806370-bf17e65e942f?w=1000", // Modern creative team collaboration
            "https://images.unsplash.com/photo-1576267423048-15c0040fec78?w=1000"  // Brand identity materials showcase
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our creative branding services have helped businesses grow."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Brand Growth",
            description:
              "A tech startup saw a 400% increase in brand recognition within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a retail client’s sales by 70% with a rebranded identity.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Elevate Your Brand?</Subheading>}
        heading="Let’s Build Your Brand Together"
        description={
          <Description>
            Partner with our expert creative branding agency in Dubai to unlock the full potential of your brand. Whether you’re looking to refresh your identity or launch a new brand strategy, we’re here to help.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1573164713988-8665fc963095?w=1000", // Creative team in action
            "https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=1000", // Creative office space with design elements
            "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?w=1000"  // Modern Dubai creative workspace
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

