// src/components/Footer.jsx
import React, { useEffect, useState } from "react";
import tw, { styled } from "twin.macro"; // Import 'styled' from twin.macro
import { ReactComponent as EmailIcon } from "images/EmailBS.svg";
import { ReactComponent as PhoneIcon } from "images/PhoneBS.svg"; // Import your phone icon
import { useLocation } from "react-router-dom";
import { BsFacebook, BsInstagram, BsWhatsapp } from "react-icons/bs";



// Removed the import for contactBg as per your request
// import contactBg from "images/contact_bg.webp";

const Container = styled.div(({ contact }) => [
  tw`relative pt-10 font-poppins`,
  // Conditionally apply background and text colors based on 'contact' prop
  contact
    ? tw`bg-white text-black` // When contact is true, background is white and text is black
    : tw`bg-[rgba(0,0,0,0.8)] text-white`, // Otherwise, semi-transparent black background with white text
  // Removed background image application
]);

const Content = tw.div`w-3/4 mx-auto relative z-10`;
const FourColumns = tw.div`flex flex-wrap text-center sm:text-center justify-center sm:justify-center md:justify-between -mt-12`;

const Column = tw.div`sm:px-0 w-full sm:w-1/2 md:w-auto mt-20`;
const ColumnHeading = tw.h5`uppercase text-left font-semibold mb-4`;

/* Option 1: Single Column using CSS Grid */
const ServicesLinkList = styled.ul`
  ${tw`mt-6 text-sm font-normal`}
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Single column */
  gap: 1rem;
`;

/* Option 2: Single Column using Flexbox */
/*
const ServicesLinkList = styled.ul`
  ${tw`mt-6 text-sm font-medium flex flex-col space-y-4`}
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
*/

const LinkList = styled.ul`
  ${tw`mt-6 text-base text-left font-normal`}
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

// Updated LinkListItem to accept 'contact' prop for conditional styling
const LinkListItem = styled.li(({ contact }) => [
  tw`relative text-left`,
  `
    &:not(:last-child)::after {
      content: "";
      ${tw`absolute bottom-0 left-0 w-full border-b`}
      border-color: ${contact ? '#000000' : '#D1D5DB'}; /* Black when contact is true, gray otherwise */
      transform: translateY(10px);
    }
  `,
]);

const Link = styled.a(({ contact }) => [
  contact
    ? tw`text-black hover:text-customPurple` // Black text when contact is true
    : tw`text-white hover:text-customPurple`, // White text otherwise
  tw`block pb-3 transition duration-300 hover:border-customPurple`,
  tw`border-b-2 border-transparent`,
  tw`no-underline`,
]);

const Divider = tw.div`my-16 border-b-2 border-gray-200 w-full`;
const FourColRow = tw.div`md:flex-row items-center justify-center bg-black p-6 text-left`;

const LogoContainer = tw.div`flex items-center h-full justify-start md:justify-center `;


export const LogoImg = styled.img(({ isBlack,small }) => [
  small?tw`w-3/6 border-black h-1/4`:tw`w-3/4`, // Width: 2.5rem (Tailwind's w-10)
  tw`bg-transparent`, // Ensure the background is transparent
  // Conditional styles based on the isBlack prop
  isBlack
    ? tw`filter invert` // Example: Invert colors if isBlack is true
    : tw`filter-none`, // No filter if isBlack is false
]);


const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-4 md:mt-0 font-medium text-gray-500`;
const SocialLinksContainer = tw.div`mt-12 md:mt-0 flex justify-center`;

const SocialLink = styled.a(({ contact }) => [
  contact
    ? tw`text-black hover:text-customPurple` // Black icons when contact is true
    : tw`text-white hover:text-customPurple`, // Default icon color otherwise
  tw`cursor-pointer p-2 rounded-full mt-4 transition duration-300 mr-4 last:mr-0`,
  `
    svg {
      ${tw`w-8 h-6`}
      fill: ${contact ? '#000000' : '#ff5464'}; /* Adjust SVG fill based on contact */
    }
  `,
]);

const ContactItem = tw.div`flex items-start justify-start mt-4`; // Aligning contact items to the left
const ContactLink = styled.a(({ contact }) => [
  contact
    ? tw`text-black` // Black text when contact is true
    : tw`text-white`, // White text otherwise
  tw`no-underline`,
]);
const ContactText = tw.p`ml-2 text-sm font-medium text-gray-900 text-left`; // Ensuring text is left-aligned

const ContactIcon = styled.div`
  ${tw`w-5 h-5`}
  svg {
    ${tw`w-full h-full`}
  }
`;

const Footer = () => {
  const [contact, setContact] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/contact") {
      setContact(true);
    } else {
      setContact(false);
    }
  }, [pathname]);

  return (
    <Container contact={contact}>
      <Content>
        <FourColumns>
          {/* Logo Column */}
          <Column>
            <LogoContainer>
              <LogoImg src="https://res.cloudinary.com/dhjdz1kjb/image/upload/v1735188414/MjBrandset_logo_min_mkukhq.png" alt="MilaajBrandset Logo" />
              {/* <LogoText>
                Milaaj<Span className="text-customPurple">Brandset </Span>
              </LogoText> */}
            </LogoContainer>
          </Column>

          {/* Quick Links Column */}
          <Column>
            <ColumnHeading>Quick Links</ColumnHeading>
            <LinkList>
              {/* Uncomment and add links as needed */}
              {/* <LinkListItem contact={contact}>
                <Link href="/blogs" contact={contact}>Blog</Link>
              </LinkListItem> */}
              <LinkListItem contact={contact}>
                <Link href="/faq" contact={contact}>FAQ</Link>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <Link href="/contact" contact={contact}>Support</Link>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <Link href="/about" contact={contact}>About Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          {/* Services Column */}
          <Column>
            <ColumnHeading>Services</ColumnHeading>
            <ServicesLinkList>
              <LinkListItem contact={contact}>
                <Link href="/brand-identity-development" contact={contact}>Brand Identity Development</Link>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <Link href="/web-development" contact={contact}>Web Development</Link>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <Link href="/social-media-branding" contact={contact}>Social Media Branding</Link>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <Link href="/paid-media-campaigns" contact={contact}>Paid Media Campaigns</Link>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <Link href="/ui-ux-design" contact={contact}>UI/UX Design</Link>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <Link href="/digital-marketing" contact={contact}>Digital Marketing</Link>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <Link href="/e-commerce" contact={contact}>E-commerce Solutions</Link>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <Link href="/search-engine-optimization" contact={contact}>SEO</Link>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <Link href="/video-ad-production" contact={contact}>Video Ad Production</Link>
              </LinkListItem>
            </ServicesLinkList>
          </Column>

          {/* Contact Column */}
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem contact={contact}>
                <ContactItem>
                  <ContactIcon>
                    <PhoneIcon color={contact ? "#000000" : "#ff5464"} /> {/* Adjust color based on contact */}
                  </ContactIcon>
                  <ContactText>
                    <ContactLink href="tel:+971525244884" contact={contact}>+971 52 524 4884</ContactLink>
                  </ContactText>
                </ContactItem>
              </LinkListItem>
              <LinkListItem contact={contact}>
                <ContactItem>
                  <ContactIcon>
                    <EmailIcon fill={contact ? "#000000" : "#ff5464"} /> {/* Adjust color based on contact */}
                  </ContactIcon>
                  <ContactText>
                    <ContactLink href="mailto:support@milaajbrandset.com" contact={contact}>support@milaajbrandset.com</ContactLink>
                  </ContactText>
                </ContactItem>
              </LinkListItem>
            </LinkList>
            <SocialLinksContainer>
              <SocialLink href="https://wa.me/+971525244884" contact={contact}>
                <BsWhatsapp size={28} />
              </SocialLink>
              <SocialLink href="https://www.facebook.com/profile.php?id=61562903405669" contact={contact}>
                <BsFacebook size={28} />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/milaajbrandset/" contact={contact}>
                <BsInstagram size={28} />
              </SocialLink>
              {/* <SocialLink href="https://linkedin.com" contact={contact}>
                <BsLinkedin size={28} />
              </SocialLink> */}
            </SocialLinksContainer>
          </Column>
        </FourColumns>
        <Divider />
      </Content>
      <FourColRow>
        <CopywrightNotice>&copy; 2024 MilaajBrandset. All Rights Reserved.</CopywrightNotice>
      </FourColRow>
    </Container>
  );
};

export default Footer;
