import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaLaptopCode,
  FaServer,
  FaUsers,
  FaHandshake,
  FaChartLine,
  FaWrench,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const BestWebManagementServicesDifc = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Best Web Management Services in DIFC" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Web Management Partner in DIFC</Subheading>}
        heading={
          <Heading>
            Best Web Management Services in{" "}
            <span tw="text-primary-500">DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we provide top-notch web management services in DIFC
            to ensure your website is always running smoothly and effectively.
            From regular updates to advanced security, we handle everything to help
            your business grow in DIFC.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1547658719-da2b51169166?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdlYiUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww", // Server room
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8d2ViJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D", // Data center
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started with Web Management"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>What We Offer</Subheading>}
        heading="Top Web Management Services in DIFC"
        description="We provide a wide range of web management services to keep your online presence strong and effective in DIFC:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Regular Website Updates",
            description:
              "Ensure your website is always current with the latest content and features in DIFC.",
          },
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "Technical Support",
            description:
              "24/7 technical support to quickly resolve any issues in DIFC.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Content Management",
            description:
              "Manage and update your website content to keep it fresh and engaging in DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "SEO Optimization",
            description:
              "Integrate SEO best practices to improve your website's search engine rankings in DIFC.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Monitoring",
            description:
              "Monitor website performance and provide regular reports in DIFC.",
          },
          {
            icon: <FaWrench color="#ff5464" size={40} />,
            title: "Security Enhancements",
            description:
              "Enhance website security to protect your business in DIFC.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Success Stories</Subheading>}
        heading={<Heading>Best Web Management in Action</Heading>}
        description={
          <Description>
            <strong>E-commerce Platform Management:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Maintained a user-friendly e-commerce platform in DIFC</li>
              <li>Ensured smooth transactions and high customer satisfaction</li>
              <li>Reduced downtime by 80%</li>
            </ul>
            <strong tw="mt-8 block">Corporate Website Management:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Managed a corporate website to improve user experience</li>
              <li>Enhanced brand visibility and engagement in DIFC</li>
              <li>Boosted lead generation by 35%</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress&cs=tinysrgb&w=600", // Server room
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8d2ViJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D", // Data center
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="Why Trust MilaajBrandset for Web Management in DIFC?"
        description="Discover why businesses in DIFC rely on MilaajBrandset for their web management needs:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Expert Team",
            description:
              "Our team of experts has extensive experience in web management and understands the latest trends in DIFC.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "We have a proven track record of delivering top web management services in DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Focus",
            description: "Your goals and success are our top priority in DIFC.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

