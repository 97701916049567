import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import "swiper/swiper-bundle.css"; // Ensure you have the right CSS for Swiper
import "./App.css";

import Hero from "./Components/Hero/Hero.js";
import Features from "./Components/Features/DashedBorderSixFeatures.js";
import MainFeature from "./Components/Features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "./Components/Features/TwoColWithTwoFeaturesAndButtons.js";
import FAQ from "./Components/Faqs/SingleCol.js";
import ContactUsForm from "./Components/Forms/TwoColContactUsWithIllustration.js";
import ContactUs from "Pages/ContactUs.js";
import Footer from "./Components/Footer/MiniCenteredFooter.js";
import Aboutus from "Pages/Aboutus.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.webp";
import BlogIndex from "Pages/BlogIndex.js";
import DigitalMarketing from "Pages/DigitalMarketing.js";
import WebDevelopment from "Pages/WebDevelopment.js";
import ECommerce from "Pages/E_Commerce.js";
import Services from "Pages/Services.js";
import Faq from "Pages/Faq.js";
import SingleBlogPost from "Pages/BlogPostSingle.js";
import SeoPage from "Pages/seoPage.js";
import SocialMediaMarketing from "Pages/SocialMediaMarketing.js";
import SocialMediaBranding from "Pages/SocialMediaBranding.js";

import CustomWebDevelopment from "Pages/CustomWebDevelopment.js";

import UI_UX from "Pages/UI_UX.js";
import MultiStepForm from "Components/MultiStepForm/MultiStepForm";
import BoostYourBrand from "Pages/BoostYourBrand";
import TwoColumnList from "Components/Features/TwoColumnList";
import CustomCursor from "Components/Cursor/CustomCursor";
import Modal from "Components/Modal/Modal";
import BrandIdentityDevelopment from "Pages/BrandIdentityDevelopment";
import PaidMediaCampaigns from "Pages/PaidMediaCampaign";
import VideoAdProduction from "Pages/VideoAdProduction";
import {
  AdvertisingAgencyPage,
  AffordableAdvertisingPage,
  BestAdvertisingAgencyPage,
  CreativeAdvertisingAgencyPage,
  DigitalAdvertisingAgencyPage,
  ExcellentAdvertisingAgencyPage,
  ExpertAdvertisingAgencyPage,
  FullServiceAdvertisingAgencyPage,
  InnovativeAdvertisingAgencyPage,
  LeadingAdvertisingAgencyPage,
  LocalAdvertisingAgencyPage,
  No1AdvertisingAgencyPage,
  ProfessionalAdvertisingAgencyPage,
  ReliableAdvertisingAgencyPage,
  TopAdvertisingAgencyPage,
  TopRatedAdvertisingAgencyPage,
} from "helmets/AdvertisingAgency/bur_dubai";
import {
  AdvertisingAgencyDubaiPage,
  AffordableAdvertisingAgencyDubaiPage,
  BestAdvertisingAgencyDubaiPage,
  CreativeAdvertisingAgencyDubaiPage,
  ExcellentAdvertisingAgencyDubaiPage,
  ExpertAdvertisingAgencyDubaiPage,
  No1AdvertisingAgencyDubaiPage,
  ReliableAdvertisingAgencyDubaiPage,
  TopAdvertisingAgencyDubaiPage,
} from "helmets/AdvertisingAgency/dubai";
import {
  BrandingAgencyBurDubaiPage,
  BrandingAgencyDubaiPage,
} from "helmets/Branding/dubai";
import {
  AffordableSeoServicesBurDubaiPage,
  DifcSeoExpertsSmallBusinessesPage,
  ExpertSEOSEO,
  LocalSeoServicesAlBarshaPage,
  SeoAgencyBurDubaiPage,
  SeoAgencyDubaiPage,
} from "helmets/SEO";
import {
  BestWebManagementServicesDifcPage,
  BestWebsiteDesignCompanyBurDubaiPage,
  CustomWebsiteDevelopmentAlBarshaPage,
  DifcProfessionalWebDesignersPage,
  ResponsiveWebsiteDesignBurDubaiPage,
  WebDevelopmentAgencyBurDubaiPage,
  WebDevelopmentAgencyDubaiPage,
  WebHostingSolutionsBurDubaiPage,
  WebManagementServicesDifcPage,
  WebsiteDesignAndDevelopmentSEO,
  WebsiteMaintenanceAlBarshaPage,
} from "helmets/WebDevelopment";
import {
  AffordableMarketingServicesSEO,
  CreativeBrandingAgencyNearDifcPage,
  DigitalMarketingAgencyNearDifcPage,
  FullServiceDigitalAgencySEO,
  MarketingCompanyAlBarshaPage,
  ReliableMarketingAgenciesSEO,
  TopDigitalMarketingAgencyAlBarshaPage,
} from "helmets/DigitalMarketing";
import {
  AffordableBrandingAgencyDubaiSEO,
  AffordableBrandingAgencySEO,
  AffordableBrandingServicesSEO,
  BestBrandingAgencySEO,
  BestBrandingServicesBurDubaiPage,
  BestCreativeBrandingAgencyDubaiSEO,
  BestCreativeBrandingAgencySEO,
  BrandDevelopmentAgencyDubaiSEO,
  BrandDevelopmentAgencySEO,
  BrandIdentityAgencyDubaiSEO,
  BrandIdentityAgencySEO,
  BrandingAndDesignAgencyDubaiSEO,
  BrandingAndDesignAgencySEO,
  BrandingAndDigitalSolutionsSEO,
  BusinessBrandingAgencyDubaiSEO,
  BusinessBrandingAgencySEO,
  CorporateBrandingAgencyDubaiSEO,
  CorporateBrandingAgencySEO,
  CreativeBrandingAgencyBurDubaiPage,
  CreativeBrandingAgencyDubaiSEO,
  CreativeBrandingAgencySEO,
  DigitalBrandingAgencyDubaiSEO,
  DigitalBrandingAgencySEO,
  DubaiBasedBrandingAgencySEO,
  FullServiceBrandingAgencyDubaiSEO,
  FullServiceBrandingAgencySEO,
  InnovativeBrandingAgencyDubaiSEO,
  InnovativeBrandingAgencySEO,
  LeadingBrandingAgencyDubaiSEO,
  LeadingBrandingAgencySEO,
  LogoAndBrandingAgencyDubaiSEO,
  LogoAndBrandingAgencySEO,
  LogoDesignAndBrandingDubaiSEO,
  LogoDesignAndBrandingSEO,
  LuxuryBrandingAgencyDubaiSEO,
  LuxuryBrandingAgencySEO,
  MarketingAndBrandingAgencyDubaiSEO,
  MarketingAndBrandingAgencySEO,
  ProfessionalBrandingAgenciesSEO,
  ProfessionalBrandingAgencyDubaiSEO,
  ProfessionalBrandingAgencySEO,
  TopBrandingAgencyBurDubaiPage,
  TopBrandingAgencySEO,
  TopRatedBrandingAgencyDubaiSEO,
  TopRatedBrandingAgencySEO,
} from "helmets/Branding";
import { CreativeAgencyAlBarshaPage } from "helmets/VideoAdProduction";
import { BestAdvertisingAgencyBurDubaiPage } from "helmets/AdvertisingAgency";
import {
  BestSocialMediaMarketingTeamSEO,
  DIFCInstagramFacebookAdsSEO,
  ProfessionalSocialMediaManagementSEO,
  SocialMediaAdvertisingServicesSEO,
  SocialMediaManagersSEO,
  SocialMediaMarketersSEO,
  SocialMediaMarketingSEO,
  SocialMediaMarketingServicesSEO,
  SocialMediaMarketingTeamSEO,
} from "helmets/SocialMediaManagement";
import {
  AffordableContentMarketingSEO,
  BestContentCreationServicesSEO,
  BlogWritingServicesSEO,
  ContentCreationServicesSEO,
  ContentMarketingAgencyDIFCSEO,
  GoogleAdsManagementDifcPage,
  PPCAdvertisingServicesSEO,
} from "helmets/ContentMarketing";
import { UXDesignCompanySEO } from "helmets/UIUXDesign";

const HomePage = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
  };

  return (
    <>
      <Helmet>
        <title>Home | MilaajBrandset</title>
        <meta
          name="description"
          content="Welcome to MilaajBrandset. We provide top-notch digital solutions including web development, digital marketing, and more to help your business succeed online."
        />
        <meta
          name="keywords"
          content="MilaajBrandset, digital solutions, web development, digital marketing, SEO services, online business solutions"
        />
        <meta property="og:title" content="Home | MilaajBrandset" />
        <meta
          property="og:description"
          content="Welcome to MilaajBrandset. We provide top-notch digital solutions including web development, digital marketing, and more to help your business succeed online."
        />
      </Helmet>
      <Hero />
      <MainFeature />
      {/* Assigning id="features" to Features section */}
      <Features id="features" onServiceClick={handleServiceClick} />
      <MainFeature2 />
      <TwoColumnList features isBlack={true} black={false} />
      {/* <Blog /> */}
      <FAQ
        imageSrc={customerSupportIllustrationSrc}
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={
          <>
            Do you have <span tw="text-primary-500">Questions?</span>
          </>
        }
      />
      <ContactUsForm />

      {/* Render the Modal */}
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <TwoColumnList black={true} selectedService={selectedService} />
        </Modal>
      )}
    </>
  );
};

const AboutPage = () => (
  <>
    <Helmet>
      <title>About Us | MilaajBrandset</title>
      <meta
        name="description"
        content="Learn more about MilaajBrandset, our values, and our mission to deliver exceptional digital solutions tailored to your business needs."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, about us, company information, digital solutions, business values"
      />
      <meta property="og:title" content="About Us | MilaajBrandset" />
      <meta
        property="og:description"
        content="Learn more about MilaajBrandset, our values, and our mission to deliver exceptional digital solutions tailored to your business needs."
      />
    </Helmet>
    <Aboutus />
  </>
);

const ContactPage = () => (
  <>
    <Helmet>
      <title>Contact Us | MilaajBrandset</title>
      <meta
        name="description"
        content="Get in touch with MilaajBrandset. Whether you have questions about our services or need support, we're here to help you."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, contact us, customer support, digital solutions, business inquiries"
      />
      <meta property="og:title" content="Contact Us | MilaajBrandset" />
      <meta
        property="og:description"
        content="Get in touch with MilaajBrandset. Whether you have questions about our services or need support, we're here to help you."
      />
    </Helmet>
    <ContactUs />
  </>
);

const ServicesPage = () => (
  <>
    <Helmet>
      <title>Services | MilaajBrandset</title>
      <meta
        name="description"
        content="Explore the wide range of digital services offered by MilaajBrandset, including web development, SEO, and more to grow your business."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, services, digital marketing, web development, SEO, business growth"
      />
      <meta property="og:title" content="Services | MilaajBrandset" />
      <meta
        property="og:description"
        content="Explore the wide range of digital services offered by MilaajBrandset, including web development, SEO, and more to grow your business."
      />
    </Helmet>
    <Services />
  </>
);

const MultiFormService = () => (
  <>
    <Helmet>
      <title>Services | MilaajBrandset</title>
      <meta
        name="description"
        content="Explore our comprehensive services, including branding, web design, digital marketing, and more, tailored to help your business grow."
      />
      <meta
        name="keywords"
        content="MilaajBrandset services, branding, web design, digital marketing, e-commerce solutions"
      />
      <meta property="og:title" content="Services | MilaajBrandset" />
      <meta
        property="og:description"
        content="Explore our services like branding, web design, digital marketing, and e-commerce solutions tailored for your business growth."
      />
    </Helmet>

    <MultiStepForm />
  </>
);

const MultiFormContact = () => (
  <>
    <Helmet>
      <title>Contact Us | MilaajBrandset</title>
      <meta
        name="description"
        content="Get in touch with MilaajBrandset to discuss your project or learn more about our services. We’re here to help!"
      />
      <meta
        name="keywords"
        content="MilaajBrandset contact, get in touch, business inquiries, customer support"
      />
      <meta property="og:title" content="Contact Us | MilaajBrandset" />
      <meta
        property="og:description"
        content="Reach out to MilaajBrandset to discuss your project or learn more about our services."
      />
    </Helmet>
    <MultiFormContact />
  </>
);

const BlogPage = () => (
  <>
    <Helmet>
      <title>Blog | MilaajBrandset</title>
      <meta
        name="description"
        content="Read our latest blog posts on digital marketing, web development, and other topics related to enhancing your business online presence."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, blog, digital marketing, web development, business insights, industry news"
      />
      <meta property="og:title" content="Blog | MilaajBrandset" />
      <meta
        property="og:description"
        content="Read our latest blog posts on digital marketing, web development, and other topics related to enhancing your business online presence."
      />
    </Helmet>
    <BlogIndex />
  </>
);

const DigitalMarketingPage = () => (
  <>
    <Helmet>
      <title>Digital Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Discover MilaajBrandset's digital marketing services designed to enhance your online presence and drive business growth."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, digital marketing, SEO, online advertising, business growth"
      />
      <meta property="og:title" content="Digital Marketing | MilaajBrandset" />
      <meta
        property="og:description"
        content="Discover MilaajBrandset's digital marketing services designed to enhance your online presence and drive business growth."
      />
    </Helmet>
    <DigitalMarketing />
  </>
);

const WebDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Web Development | MilaajBrandset</title>
      <meta
        name="description"
        content="Our web development services offer cutting-edge solutions to build and optimize your website for better performance and user experience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, web development, website design, user experience, digital solutions"
      />
      <meta property="og:title" content="Web Development | MilaajBrandset" />
      <meta
        property="og:description"
        content="Our web development services offer cutting-edge solutions to build and optimize your website for better performance and user experience."
      />
    </Helmet>
    <WebDevelopment />
  </>
);

const ECommercePage = () => (
  <>
    <Helmet>
      <title>E-Commerce | MilaajBrandset</title>
      <meta
        name="description"
        content="Boost your online sales with MilaajBrandset's e-commerce solutions designed to enhance your store's performance and customer experience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, e-commerce solutions, online sales, store optimization, digital retail"
      />
      <meta property="og:title" content="E-Commerce | MilaajBrandset" />
      <meta
        property="og:description"
        content="Boost your online sales with MilaajBrandset's e-commerce solutions designed to enhance your store's performance and customer experience."
      />
    </Helmet>
    <ECommerce />
  </>
);

const BrandIdentityDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Brand Identity Development | MilaajBrandset</title>
      <meta
        name="description"
        content="Craft a distinctive brand identity that tells your story and resonates with your audience. MilaajBrandset focuses on creating a cohesive brand image that reflects your values and stands out in the market."
      />
      <meta
        name="keywords"
        content="Brand Identity Development, Logo Design, Brand Messaging, Style Guides, Brand Strategy, MilaajBrandset"
      />
      <meta
        property="og:title"
        content="Brand Identity Development | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Craft a distinctive brand identity that tells your story and resonates with your audience. MilaajBrandset focuses on creating a cohesive brand image that reflects your values and stands out in the market."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.milaajbrandset.com/brand-identity-development"
      />
      <meta
        property="og:image"
        content="https://www.milaajbrandset.com/images/brand-identity-og-image.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Brand Identity Development | MilaajBrandset"
      />
      <meta
        name="twitter:description"
        content="Craft a distinctive brand identity that tells your story and resonates with your audience. MilaajBrandset focuses on creating a cohesive brand image that reflects your values and stands out in the market."
      />
      <meta
        name="twitter:image"
        content="https://www.milaajbrandset.com/images/brand-identity-twitter-image.jpg"
      />
      {/* Add more meta tags as needed */}
    </Helmet>
    <BrandIdentityDevelopment />
  </>
);

const SingleBlogPostPage = () => (
  <>
    <Helmet>
      <title>Blog Post | MilaajBrandset</title>
      <meta
        name="description"
        content="Read the latest insights and updates from MilaajBrandset's blog. Explore detailed articles and expert opinions on various topics."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, blog post, insights, updates, expert opinions"
      />
      <meta property="og:title" content="Blog Post | MilaajBrandset" />
      <meta
        property="og:description"
        content="Read the latest insights and updates from MilaajBrandset's blog. Explore detailed articles and expert opinions on various topics."
      />
    </Helmet>
    <SingleBlogPost />
  </>
);

const SocialMediaMarketingPage = () => (
  <>
    <Helmet>
      <title>Social Media Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Enhance your social media presence with MilaajBrandset's social media marketing services. Drive engagement and growth across platforms."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, social media marketing, social media strategy, engagement, growth"
      />
      <meta
        property="og:title"
        content="Social Media Marketing | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Enhance your social media presence with MilaajBrandset's social media marketing services. Drive engagement and growth across platforms."
      />
    </Helmet>
    <SocialMediaMarketing />
  </>
);

const SEOpage = () => (
  <>
    <Helmet>
      <title>SEO Services | MilaajBrandset</title>
      <meta
        name="description"
        content="Boost your online visibility with MilaajBrandset's SEO services. Improve your website's search engine ranking and drive more traffic to your site."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, SEO services, search engine optimization, website ranking, online visibility"
      />
      <meta property="og:title" content="SEO Services | MilaajBrandset" />
      <meta
        property="og:description"
        content="Boost your online visibility with MilaajBrandset's SEO services. Improve your website's search engine ranking and drive more traffic to your site."
      />
    </Helmet>
    <SeoPage />
  </>
);

const VideoAdProductionPage = () => (
  <>
    <Helmet>
      <title>Video Ad Production | MilaajBrandset</title>
      <meta
        name="description"
        content="Create impactful video advertisements that tell your brand’s story. MilaajBrandset offers comprehensive video ad production services from concept to final edit, designed to engage and convert your audience."
      />
      <meta
        name="keywords"
        content="Video Ad Production, Concept Development, Scriptwriting, Professional Editing, Targeted Distribution, MilaajBrandset"
      />
      <meta
        property="og:title"
        content="Video Ad Production | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Create impactful video advertisements that tell your brand’s story. MilaajBrandset offers comprehensive video ad production services from concept to final edit, designed to engage and convert your audience."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.milaajbrandset.com/video-ad-production"
      />
      <meta
        property="og:image"
        content="https://www.milaajbrandset.com/images/video-ad-og-image.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Video Ad Production | MilaajBrandset"
      />
      <meta
        name="twitter:description"
        content="Create impactful video advertisements that tell your brand’s story. MilaajBrandset offers comprehensive video ad production services from concept to final edit, designed to engage and convert your audience."
      />
      <meta
        name="twitter:image"
        content="https://www.milaajbrandset.com/images/video-ad-twitter-image.jpg"
      />
      {/* Add more meta tags as needed */}
    </Helmet>
    <VideoAdProduction />
  </>
);

const BoostBrandPage = () => (
  <>
    <Helmet>
      <title>Branding and Digital Solutions | MilaajBrandset</title>
      <meta
        name="description"
        content="Elevate your brand with MilaajBrandset's cutting-edge social media management, strategic brand positioning, and creative solutions. Let's make your brand shine."
      />
      <meta
        name="keywords"
        content="branding, social media management, digital solutions, MilaajBrandset, brand positioning, creative content, video production, paid media campaigns"
      />
      <meta
        property="og:title"
        content="Branding and Digital Solutions | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Elevate your brand with MilaajBrandset's cutting-edge social media management, strategic brand positioning, and creative solutions. Let's make your brand shine."
      />
      <meta
        property="og:image"
        content="path-to-image" /* Replace with actual image path */
      />
    </Helmet>
    <BoostYourBrand />
  </>
);

const CustomWebDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Custom Web Development | MilaajBrandset</title>
      <meta
        name="description"
        content="Get bespoke web development services with MilaajBrandset, tailored to meet your unique business needs and goals."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, custom web development, bespoke websites, business solutions"
      />
      <meta
        property="og:title"
        content="Custom Web Development | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Get bespoke web development services with MilaajBrandset, tailored to meet your unique business needs and goals."
      />
    </Helmet>
    <CustomWebDevelopment />
  </>
);

const SocialMediaBrandingPage = () => (
  <>
    <Helmet>
      <title>Social Media Branding | MilaajBrandset</title>
      <meta
        name="description"
        content="Boost your brand's presence with MilaajBrandset's social media branding services. Create a strong and recognizable brand identity online."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, social media branding, brand identity, online presence, brand recognition"
      />
      <meta
        property="og:title"
        content="Social Media Branding | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Boost your brand's presence with MilaajBrandset's social media branding services. Create a strong and recognizable brand identity online."
      />
    </Helmet>
    <SocialMediaBranding />
  </>
);

const PaidMediaCampaignsPage = () => (
  <>
    <Helmet>
      <title>Paid Media Campaigns | MilaajBrandset</title>
      <meta
        name="description"
        content="Drive targeted traffic and maximize ROI with MilaajBrandset's data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently."
      />
      <meta
        name="keywords"
        content="Paid Media Campaigns, Precision Targeting, Cost Optimization, Comprehensive Tracking, Creative Designs, MilaajBrandset"
      />
      <meta
        property="og:title"
        content="Paid Media Campaigns | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Drive targeted traffic and maximize ROI with MilaajBrandset's data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.milaajbrandset.com/paid-media-campaigns"
      />
      <meta
        property="og:image"
        content="https://www.milaajbrandset.com/images/paid-media-og-image.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Paid Media Campaigns | MilaajBrandset"
      />
      <meta
        name="twitter:description"
        content="Drive targeted traffic and maximize ROI with MilaajBrandset's data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently."
      />
      <meta
        name="twitter:image"
        content="https://www.milaajbrandset.com/images/paid-media-twitter-image.jpg"
      />
      {/* Add more meta tags as needed */}
    </Helmet>
    <PaidMediaCampaigns />
  </>
);

const UIUXPage = () => (
  <>
    <Helmet>
      <title>UI/UX Design Solutions | MilaajBrandset</title>
      <meta
        name="description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, UI design, UX design, user experience, user interface, design solutions, interactive design"
      />
      <meta
        property="og:title"
        content="UI/UX Design Solutions | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
    </Helmet>
    <UI_UX />
  </>
);

const App = () => {
  return (
    <AnimationRevealPage>
      <CustomCursor />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/blog/:id/:title" element={<SingleBlogPostPage />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/search-engine-optimization" element={<SEOpage />} />
        <Route
          path="/social-media-marketing"
          element={<SocialMediaMarketingPage />}
        />
        <Route
          path="/social-media-branding"
          element={<SocialMediaBrandingPage />}
        />
        <Route path="/boost-your-brand" element={<BoostBrandPage />} />
        <Route
          path="/custom-web-development"
          element={<CustomWebDevelopmentPage />}
        />
        <Route
          path="/video-ad-production"
          element={<VideoAdProductionPage />}
        />{" "}
        {/* SEO-Enhanced Route */}
        <Route
          path="/brand-identity-development"
          element={<BrandIdentityDevelopmentPage />}
        />
        <Route
          path="/paid-media-campaigns"
          element={<PaidMediaCampaignsPage />}
        />
        <Route path="/digital-marketing" element={<DigitalMarketingPage />} />
        <Route path="/web-development" element={<WebDevelopmentPage />} />
        <Route path="/ui-ux-design" element={<UIUXPage />} />
        <Route path="/e-commerce" element={<ECommercePage />} />
        <Route path="/grow" element={<MultiFormService />} />
        {/* SEO PAGES-ADVERTISING-BUR-DUBAI */}
        <Route
          path="/advertising-agency-bur-dubai"
          element={<AdvertisingAgencyPage />}
        />
        <Route
          path="/no-1-advertising-agency-bur-dubai"
          element={<No1AdvertisingAgencyPage />}
        />
        <Route
          path="/affordable-advertising-bur-dubai"
          element={<AffordableAdvertisingPage />}
        />
        <Route
          path="/local-advertising-agency-dubai"
          element={<LocalAdvertisingAgencyPage />}
        />
        <Route
          path="/top-rated-advertising-agency"
          element={<TopAdvertisingAgencyPage />}
        />
        <Route
          path="/professional-advertising-bur-duai"
          element={<ProfessionalAdvertisingAgencyPage />}
        />
        <Route
          path="/reliable-advertising-agency-bur-dubai"
          element={<ReliableAdvertisingAgencyPage />}
        />
        <Route
          path="/expert-advertising-agency-bur-dubai"
          element={<ExpertAdvertisingAgencyPage />}
        />
        <Route
          path="/top-advertising-agency-bur-dubai"
          element={<TopRatedAdvertisingAgencyPage />}
        />
        <Route
          path="/full-service-advertising-agency-bur-dubai"
          element={<FullServiceAdvertisingAgencyPage />}
        />
        <Route
          path="/creative-advertising-agency-bur-dubai"
          element={<CreativeAdvertisingAgencyPage />}
        />
        <Route
          path="/innovative-advertising-agency-bur-dubai"
          element={<InnovativeAdvertisingAgencyPage />}
        />
        <Route
          path="/digital-advertising-agency-bur-dubai"
          element={<DigitalAdvertisingAgencyPage />}
        />
        <Route
          path="/best-advertising-agency-bur-dubai"
          element={<BestAdvertisingAgencyPage />}
        />
        <Route
          path="/leading-advertising-agency-bur-dubai"
          element={<LeadingAdvertisingAgencyPage />}
        />
        <Route
          path="/excellent-advertising-agency-bur-dubai"
          element={<ExcellentAdvertisingAgencyPage />}
        />
        {/* SEO-DUBAI */}
        <Route
          path="/advertising-agency-dubai"
          element={<AdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/best-advertising-agency-dubai"
          element={<BestAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/professional-advertising-agency-dubai"
          element={<ProfessionalAdvertisingAgencyPage />}
        />
        <Route
          path="/expert-advertising-agency-dubai"
          element={<ExpertAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/affordable-advertising-agency-dubai"
          element={<AffordableAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/no1-advertising-agency-dubai"
          element={<No1AdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/top-advertising-agency-dubai"
          element={<TopAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/excellent-advertising-agency-in-dubai"
          element={<ExcellentAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/reliable-advertising-agency-in-dubai"
          element={<ReliableAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/creative-advertising-agency-in-dubai"
          element={<CreativeAdvertisingAgencyDubaiPage />}
        />
        {/* SEO PAGES BRANDING BUR DUBAI */}
        <Route
          path="/branding-agency-in-bur-dubai"
          element={<BrandingAgencyBurDubaiPage />}
        />
        {/* SEO PAGES - BRANDING DUBAI */}
        <Route
          path="/branding-agency-in-dubai"
          element={<BrandingAgencyDubaiPage />}
        />
        ;{/* SEO PAGE -  SEO AGENCY BUR DUBAI */}
        <Route
          path="/seo-agency-in-bur-dubai"
          element={<SeoAgencyBurDubaiPage />}
        />
        <Route
          path="/difc-seo-experts-for-small-businesses"
          element={<DifcSeoExpertsSmallBusinessesPage />}
        />
        <Route
          path="/affordable-seo-services-in-bur-dubai"
          element={<AffordableSeoServicesBurDubaiPage />}
        />
        {/* SEO PAGES - SEO AGENCY DUBAI */}
        <Route path="/seo-agency-in-dubai" element={<SeoAgencyDubaiPage />} />;
        <Route
          path="/local-seo-services-in-al-barsha"
          element={<LocalSeoServicesAlBarshaPage />}
        />
        ;{/* SEO PAGES-Web Development dubai */}
        <Route
          path="/web-development-agency-in-dubai"
          element={<WebDevelopmentAgencyDubaiPage />}
        />
        {/* SEO PAGES - Web Development bur Dubai */}
        <Route
          path="/web-development-agency-in-bur-dubai"
          element={<WebDevelopmentAgencyBurDubaiPage />}
        />
        <Route
          path="/web-management-services-in-difc"
          element={<WebManagementServicesDifcPage />}
        />
        <Route
          path="/web-hosting-solutions-in-bur-dubai"
          element={<WebHostingSolutionsBurDubaiPage />}
        />
        <Route
          path="/website-maintenance-in-al-barsha"
          element={<WebsiteMaintenanceAlBarshaPage />}
        />
        <Route
          path="/best-web-management-services-in-difc"
          element={<BestWebManagementServicesDifcPage />}
        />
        <Route
          path="/custom-website-development-in-al-barsha"
          element={<CustomWebsiteDevelopmentAlBarshaPage />}
        />
        <Route
          path="/difc-professional-web-designers"
          element={<DifcProfessionalWebDesignersPage />}
        />
        <Route
          path="/responsive-website-design-bur-dubai"
          element={<ResponsiveWebsiteDesignBurDubaiPage />}
        />
        <Route
          path="/best-website-design-company-bur-dubai"
          element={<BestWebsiteDesignCompanyBurDubaiPage />}
        />
        <Route
          path="/marketing-company-in-al-barsha"
          element={<MarketingCompanyAlBarshaPage />}
        />
        <Route
          path="/digital-marketing-agency-near-difc"
          element={<DigitalMarketingAgencyNearDifcPage />}
        />
        <Route
          path="/best-branding-services-in-bur-dubai"
          element={<BestBrandingServicesBurDubaiPage />}
        />
        <Route
          path="/creative-agency-in-al-barsha"
          element={<CreativeAgencyAlBarshaPage />}
        />
        <Route
          path="/best-advertising-agency-in-bur-dubai"
          element={<BestAdvertisingAgencyBurDubaiPage />}
        />
        <Route
          path="/top-digital-marketing-agency-in-al-barsha"
          element={<TopDigitalMarketingAgencyAlBarshaPage />}
        />
        <Route
          path="/creative-branding-agency-near-difc"
          element={<CreativeBrandingAgencyNearDifcPage />}
        />
        <Route
          path="/creative-branding-agency-near-difc"
          element={<CreativeBrandingAgencySEO />}
        />
        <Route
          path="/affordable-marketing-services-in-bur-dubai"
          element={<AffordableMarketingServicesSEO />}
        />
        <Route
          path="/professional-social-media-management-in-al-barsha"
          element={<ProfessionalSocialMediaManagementSEO />}
        />
        <Route
          path="/website-design-and-development-in-difc"
          element={<WebsiteDesignAndDevelopmentSEO />}
        />
        <Route
          path="/expert-seo-services-in-bur-dubai"
          element={<ExpertSEOSEO />}
        />
        <Route
          path="/social-media-marketing-companies-near-al-barsha"
          element={<SocialMediaMarketingSEO />}
        />
        <Route
          path="/branding-and-digital-solutions-in-difc"
          element={<BrandingAndDigitalSolutionsSEO />}
        />
        <Route
          path="/full-service-digital-agency-in-bur-dubai"
          element={<FullServiceDigitalAgencySEO />}
        />
        <Route
          path="/reliable-marketing-agencies-near-me"
          element={<ReliableMarketingAgenciesSEO />}
        />
        <Route
          path="/affordable-content-marketing-companies-in-al-barsha"
          element={<AffordableContentMarketingSEO />}
        />
        <Route
          path="/professional-branding-agencies-in-dubai"
          element={<ProfessionalBrandingAgenciesSEO />}
        />
        <Route
          path="/content-creation-services-in-bur-dubai"
          element={<ContentCreationServicesSEO />}
        />
        <Route
          path="/content-marketing-agency-in-difc"
          element={<ContentMarketingAgencyDIFCSEO />}
        />
        <Route
          path="/blog-writing-services-in-al-barsha"
          element={<BlogWritingServicesSEO />}
        />
        <Route
          path="/best-content-creation-services-in-bur-dubai"
          element={<BestContentCreationServicesSEO />}
        />
        <Route
          path="/ppc-advertising-services-in-bur-dubai"
          element={<PPCAdvertisingServicesSEO />}
        />
        <Route
          path="/google-ads-management-in-difc"
          element={<GoogleAdsManagementDifcPage />}
        />
        <Route
          path="/social-media-advertising-services-in-al-barsha"
          element={<SocialMediaAdvertisingServicesSEO />}
        />
        <Route
          path="/social-media-marketing-services-in-bur-dubai"
          element={<SocialMediaMarketingServicesSEO />}
        />
        <Route
          path="/social-media-managers-in-al-barsha"
          element={<SocialMediaManagersSEO />}
        />
        <Route
          path="/difc-instagram-facebook-ads-experts"
          element={<DIFCInstagramFacebookAdsSEO />}
        />
        <Route
          path="/social-media-marketing-team-in-bur-dubai"
          element={<SocialMediaMarketingTeamSEO />}
        />
        <Route
          path="/best-social-media-marketing-team-in-bur-dubai"
          element={<BestSocialMediaMarketingTeamSEO />}
        />
        <Route
          path="/social-media-marketers-in-bur-dubai"
          element={<SocialMediaMarketersSEO />}
        />
        <Route
          path="/ux-design-company-in-al-barsha"
          element={<UXDesignCompanySEO />}
        />
        <Route
          path="/top-branding-agency-bur-dubai"
          element={<TopBrandingAgencyBurDubaiPage />}
        />
        <Route
          path="/creative-branding-agency-bur-dubai"
          element={<CreativeBrandingAgencyBurDubaiPage />}
        />
        <Route
          path="/professional-branding-agency-in-bur-dubai"
          element={<ProfessionalBrandingAgencySEO />}
        />
        <Route
          path="/brand-identity-agency-in-bur-dubai"
          element={<BrandIdentityAgencySEO />}
        />
        <Route
          path="/logo-and-branding-agency-in-bur-dubai"
          element={<LogoAndBrandingAgencySEO />}
        />
        <Route
          path="/marketing-and-branding-agency-in-bur-dubai"
          element={<MarketingAndBrandingAgencySEO />}
        />
        <Route
          path="/brand-development-agency-in-bur-dubai"
          element={<BrandDevelopmentAgencySEO />}
        />
        <Route
          path="/affordable-branding-agency-in-bur-dubai"
          element={<AffordableBrandingAgencySEO />}
        />
        <Route
          path="/corporate-branding-agency-in-bur-dubai"
          element={<CorporateBrandingAgencySEO />}
        />
        <Route
          path="/leading-branding-agency-in-bur-dubai"
          element={<LeadingBrandingAgencySEO />}
        />
        <Route
          path="/branding-and-design-agency-in-bur-dubai"
          element={<BrandingAndDesignAgencySEO />}
        />
        <Route
          path="/full-service-branding-agency-in-bur-dubai"
          element={<FullServiceBrandingAgencySEO />}
        />
        <Route
          path="/dubai-based-branding-agency"
          element={<DubaiBasedBrandingAgencySEO />}
        />
        <Route
          path="/luxury-branding-agency-in-bur-dubai"
          element={<LuxuryBrandingAgencySEO />}
        />
        <Route
          path="/innovative-branding-agency-in-bur-dubai"
          element={<InnovativeBrandingAgencySEO />}
        />
        <Route
          path="/business-branding-agency-in-bur-dubai"
          element={<BusinessBrandingAgencySEO />}
        />
        <Route
          path="/top-rated-branding-agency-in-bur-dubai"
          element={<TopRatedBrandingAgencySEO />}
        />
        <Route
          path="/logo-design-and-branding-in-bur-dubai"
          element={<LogoDesignAndBrandingSEO />}
        />
        <Route
          path="/best-creative-branding-agency-in-bur-dubai"
          element={<BestCreativeBrandingAgencySEO />}
        />
        <Route
          path="/digital-branding-agency-in-bur-dubai"
          element={<DigitalBrandingAgencySEO />}
        />
        <Route
          path="/best-branding-agency-in-dubai"
          element={<BestBrandingAgencySEO />}
        />
        <Route
          path="/top-branding-agency-in-dubai"
          element={<TopBrandingAgencySEO />}
        />
        <Route
          path="/creative-branding-agency-in-dubai"
          element={<CreativeBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/professional-branding-agency-in-dubai"
          element={<ProfessionalBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/brand-identity-agency-in-dubai"
          element={<BrandIdentityAgencyDubaiSEO />}
        />
        <Route
          path="/logo-and-branding-agency-in-dubai"
          element={<LogoAndBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/marketing-and-branding-agency-in-dubai"
          element={<MarketingAndBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/brand-development-agency-in-dubai"
          element={<BrandDevelopmentAgencyDubaiSEO />}
        />
        <Route
          path="/affordable-branding-agency-in-dubai"
          element={<AffordableBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/corporate-branding-agency-in-dubai"
          element={<CorporateBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/leading-branding-agency-in-dubai"
          element={<LeadingBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/branding-and-design-agency-in-dubai"
          element={<BrandingAndDesignAgencyDubaiSEO />}
        />
        <Route
          path="/full-service-branding-agency-in-dubai"
          element={<FullServiceBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/luxury-branding-agency-in-dubai"
          element={<LuxuryBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/innovative-branding-agency-in-dubai"
          element={<InnovativeBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/business-branding-agency-in-dubai"
          element={<BusinessBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/affordable-branding-services-in-dubai"
          element={<AffordableBrandingServicesSEO />}
        />
        <Route
          path="/top-rated-branding-agency-in-dubai"
          element={<TopRatedBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/digital-branding-agency-in-dubai"
          element={<DigitalBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/logo-design-and-branding-in-dubai"
          element={<LogoDesignAndBrandingDubaiSEO />}
        />
        <Route
          path="/best-creative-branding-agency-in-dubai"
          element={<BestCreativeBrandingAgencyDubaiSEO />}
        />
        
      </Routes>
      <Footer />
    </AnimationRevealPage>
  );
};

export default () => (
  <Router>
    <App />
  </Router>
);
