
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaTrophy,
  FaShieldAlt,
  FaChartLine,
  FaLightbulb,
  FaUsers,
  FaPaintBrush,
  FaRocket,
  FaBrain
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const TopBrandingAgencyBurDubai = () => {
  return (
    <AnimationRevealPage>
      <Header from="Top Branding Agency in Bur Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Bur Dubai's Brand Authority</Subheading>}
        heading={
          <Heading>
            Crafting Unforgettable Brands in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            Recognized as a leading branding agency, we combine strategic vision
            with creative brilliance to help businesses stand out in Dubai's
            competitive landscape. Our results-driven approach has transformed
            over 500 brands across the region.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3", // Team brainstorming brand strategy
            "https://images.unsplash.com/photo-1542744094-3a31f272c490?ixlib=rb-4.0.3", // Brand identity design work
            "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3"  // Modern creative workspace
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 50%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 35%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Transform Your Brand"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Expertise</Subheading>}
        heading="Comprehensive Branding Solutions"
        description="Our 6-pillar approach to brand mastery:"
        cards={[
          {
            icon: <FaPaintBrush color="#FF5733" size={40} />,
            title: "Strategic Brand Positioning",
            description:
              "Define your unique value proposition and market differentiation"
          },
          {
            icon: <FaRocket color="#FF5733" size={40} />,
            title: "Visual Identity Creation",
            description:
              "Memorable logos, color palettes, and typography systems that resonate"
          },
          {
            icon: <FaBrain color="#FF5733" size={40} />,
            title: "Brand Narrative Development",
            description:
              "Compelling stories that connect emotionally with your audience"
          },
          {
            icon: <FaChartLine color="#FF5733" size={40} />,
            title: "Digital Brand Experience",
            description:
              "Seamless web and app experiences that drive conversions"
          },
          {
            icon: <FaLightbulb color="#FF5733" size={40} />,
            title: "Brand Activation Strategies",
            description:
              "Launch campaigns that create immediate market buzz"
          },
          {
            icon: <FaUsers color="#FF5733" size={40} />,
            title: "Brand Management Systems",
            description:
              "Tools and processes to maintain brand consistency long-term"
          }
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Proven Success</Subheading>}
        heading={<Heading>Case Studies That Speak Volumes</Heading>}
        description={
          <Description>
            <strong>Startup to Market Leader:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Increased brand recognition by 300% in 12 months</li>
              <li>Generated 25% more leads through strategic rebranding</li>
              <li>Secured major partnerships through enhanced brand credibility</li>
            </ul>

            <strong tw="mt-8 block">Legacy Brand Revitalization:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Revived customer engagement by 45%</li>
              <li>Expanded market share by 20% in competitive category</li>
              <li>Won industry awards for innovative brand transformation</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1558403194-611308249627?ixlib=rb-4.0.3", // Team reviewing brand designs
            "https://images.unsplash.com/photo-1531973576160-7125cd663d86?ixlib=rb-4.0.3", // Brand success celebration
            "https://images.unsplash.com/photo-1536064479547-7ee40b74b807?ixlib=rb-4.0.3"  // Creative branding materials
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 15%;",
          "left: 28%; bottom: 20%;",
        ]}
        primaryButtonText="Explore Our Portfolio"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>The Bur Dubai Branding Edge</Subheading>}
        heading="Why Growing Businesses Choose Us"
        description="Our approach is built on transparency, expertise, and results:"
        cards={[
          {
            icon: <FaTrophy color="#FF5733" size={40} />,
            title: "Specialized in Emerging Brands",
            description:
              "We focus on helping startups and SMEs build strong foundations for sustainable growth."
          },
          {
            icon: <FaShieldAlt color="#FF5733" size={40} />,
            title: "Transparent Process",
            description:
              "Clear timelines, regular updates, and direct access to our creative team."
          },
          {
            icon: <FaChartLine color="#FF5733" size={40} />,
            title: "Data-Driven Decisions",
            description:
              "Every strategy is informed by market research, competitor analysis, and audience insights."
          },
        ]}
      />
    </AnimationRevealPage>
  );
};