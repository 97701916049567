// src/pages/CreativeAdvertisingAgencyDubai.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaTrophy,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaBullseye,
  FaStar,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const CreativeAdvertisingAgencyDubai = () => {
  return (
    <AnimationRevealPage>
      <Header from="Creative Advertising Agency in Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Unlock Your Brand's Potential</Subheading>}
        heading={
          <Heading>
            Elevate Your Brand with a <span tw="text-primary-500">Creative</span> Advertising Agency in Dubai
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in creating innovative and impactful advertising campaigns that captivate audiences and drive results. Our team of creative experts combines strategic thinking with artistic flair to deliver unique solutions that set your brand apart.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Work With Us"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Our Commitment to Creativity</Subheading>}
        heading="Why Choose Us"
        description="Our dedication to creativity and innovation sets us apart:"
        cards={[
          {
            icon: <FaStar color="#ff5464" size={40} />,
            title: "Innovative Solutions",
            description:
              "We offer cutting-edge strategies tailored to your needs, ensuring your brand stands out."
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "We prioritize your needs and goals, ensuring every campaign is tailored to your success."
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Measurable Results",
            description:
              "Our strategies are backed by data, ensuring measurable and impactful outcomes."
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Ethical Practices",
            description:
              "We adhere to the highest standards of integrity and transparency in all our work."
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Targeted Solutions",
            description:
              "We focus on delivering precise, effective campaigns that meet your specific needs."
          },
          {
            icon: <FaTrophy color="#ff5464" size={40} />,
            title: "Continuous Improvement",
            description:
              "We stay updated with industry trends and continuously refine our methods to deliver the best."
          }
        ]}
      />

      {/* Service Areas */}
      <Features
        subheading={<Subheading>Dubai Coverage</Subheading>}
        heading="Serving Businesses Across Dubai"
        description="We offer comprehensive advertising solutions in key areas:"
        cards={[
          {
            title: "Bur Dubai",
            description:
              "Targeted campaigns for local residents and businesses"
          },
          {
            title: "Deira",
            description:
              "Ads for education providers, retail, and service businesses"
          },
          {
            title: "Dubai Marina",
            description:
              "Promotions for luxury brands and lifestyle businesses"
          },
          {
            title: "Business Bay",
            description: "Corporate and real estate marketing solutions"
          },
          {
            title: "Jumeirah",
            description: "Campaigns for high-end hospitality and tourism"
          },
          {
            title: "Jebel Ali",
            description: "Industrial and logistics marketing strategies"
          }
        ]}
      />

      {/* Specialized Services Section */}
      <MainFeature1
        subheading={<Subheading>Our Specialized Services</Subheading>}
        heading={<Heading>What Makes Us Stand Out</Heading>}
        description={
          <Description>
            At MilaajBrandset, we pride ourselves on offering specialized
            services that cater to the unique needs of each client. Our
            expertise spans across various domains, ensuring that we deliver
            comprehensive and tailored solutions.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Learn More"
        textOnLeft={true}
      />

      {/* Vision and Commitment Section */}
      <StyledFeatures
        subheading={<Subheading>Our Vision and Commitment</Subheading>}
        heading="Our Promise to You"
        description="At MilaajBrandset, we are driven by a vision to transform brands and build lasting relationships:"
        cards={[
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Expert Team",
            description:
              "Our team consists of seasoned professionals with diverse backgrounds in marketing, design, and strategy."
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "We leverage the latest data analytics tools to create campaigns that deliver measurable results."
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "We prioritize your goals and work closely with you to understand your needs and exceed your expectations."
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Targeted Solutions",
            description:
              "We focus on delivering precise, effective campaigns that meet your specific needs and objectives."
          },
          {
            icon: <FaStar color="#ff5464" size={40} />,
            title: "Innovative Creativity",
            description:
              "Our innovative approach ensures that your brand stands out in a crowded market."
          },
          {
            icon: <FaTrophy color="#ff5464" size={40} />,
            title: "Commitment to Excellence",
            description:
              "We are dedicated to continuous improvement and staying ahead of industry trends to deliver the best possible results."
          }
        ]}
      />
    </AnimationRevealPage>
  );
};