import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaBullhorn,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaSearchDollar,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Affordable Marketing Services Page Component
export const AffordableMarketingServicesInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Affordable Marketing Services in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Trusted Marketing Partner in Bur Dubai</Subheading>
        }
        heading={
          <Heading>
            Affordable Marketing Services in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we provide cost-effective marketing solutions
            tailored to businesses in Bur Dubai. From digital marketing to brand
            strategy, we help you grow your business without breaking the bank.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/6476246/pexels-photo-6476246.jpeg?auto=compress&cs=tinysrgb&w=600", // Dubai skyline
          "https://images.pexels.com/photos/6483583/pexels-photo-6483583.jpeg?auto=compress&cs=tinysrgb&w=600", // Modern office with laptops
          "https://images.pexels.com/photos/6476249/pexels-photo-6476249.jpeg?auto=compress&cs=tinysrgb&w=600", // Team working on a project
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
      />

      {/* Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Marketing Solutions for Your Business"
        description="Explore our affordable marketing services designed to help your business thrive in Bur Dubai:"
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Digital Marketing",
            description:
              "Boost your online presence with SEO, PPC, and social media campaigns tailored for Bur Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Tracking",
            description:
              "Monitor and optimize your marketing efforts with data-driven insights.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Social Media Management",
            description:
              "Engage your audience and build brand loyalty through strategic social media content.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Strategy",
            description:
              "Work closely with our team to align your goals with actionable marketing plans.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Content Creation",
            description:
              "Produce high-quality, engaging content to captivate your target audience in Bur Dubai.",
          },
          {
            icon: <FaSearchDollar color="#ff5464" size={40} />,
            title: "Cost-Effective Campaigns",
            description:
              "Maximize ROI with budget-friendly marketing strategies tailored to your needs.",
          },
        ]}
      />

      {/* Success Stories Section */}
      <MainFeature1
        subheading={<Subheading>Our Impact</Subheading>}
        heading={
          <Heading>Affordable Marketing Success Stories in Bur Dubai</Heading>
        }
        description={
          <Description>
            <strong>Local Retailer Growth:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Increased online sales by 50% through targeted PPC campaigns.
              </li>
              <li>Improved website traffic by 70% with SEO optimization.</li>
              <li>Achieved measurable results within a limited budget.</li>
            </ul>
            <strong tw="mt-8 block">Startup Brand Awareness:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Built a strong social media presence for a new startup in Bur
                Dubai.
              </li>
              <li>Grew Instagram followers by 300% in six months.</li>
              <li>Generated leads at a low cost-per-acquisition rate.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.pexels.com/photos/3194519/pexels-photo-3194519.jpeg?auto=compress&cs=tinysrgb&w=600", // Dubai skyline
            "https://images.pexels.com/photos/590045/pexels-photo-590045.jpeg?auto=compress&cs=tinysrgb&w=600", // Team working on a project
            "https://images.pexels.com/photos/6229/marketing-board-strategy.jpg?auto=compress&cs=tinysrgb&w=600", // Modern office with laptops
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Discover More Success Stories"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Affordable Marketing Experts in Bur Dubai"
        description="Here’s why businesses in Bur Dubai trust us as their marketing partner:"
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Affordable Pricing",
            description:
              "We offer competitive pricing without compromising on quality or results.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "Our track record speaks for itself—delivering impactful marketing solutions in Bur Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the core of everything we do in Bur Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
