// src/pages/ExpertAdvertisingAgencyDubai.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import { 
  FaLightbulb, 
  FaChartLine, 
  FaUsers, 
  FaRocket, 
  FaPaintBrush, 
  FaGlobe,
  FaCogs,
  FaHandshake,
  FaBullseye
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const ExpertAdvertisingAgencyDubai = () => {
  return (
    <AnimationRevealPage>
      <Header from="Expert Advertising Agency in Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Strategic Advertising Partner</Subheading>}
        heading={
          <Heading>
            Expert Advertising Solutions for <span tw="text-primary-500">Dubai Businesses</span>
          </Heading>
        }
        description={
          <Description>
            As an expert advertising agency in Dubai, we combine deep market knowledge, cutting-edge strategies, and creative excellence to help your business thrive in Dubai's competitive landscape. Whether you're targeting local audiences or global markets, we deliver results that matter.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Work With Experts"
      />

      {/* Core Expertise Section */}
      <StyledFeatures
        subheading={<Subheading>Our Expertise</Subheading>}
        heading="What Makes Us Dubai's Advertising Experts"
        description="We bring a unique blend of skills and experience to every project:"
        cards={[
          {
            icon: <FaCogs color="#ff5464" size={40} />,
            title: "Strategic Planning",
            description: "Data-driven strategies tailored to your business goals and audience."
          },
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description: "Visually stunning designs that capture attention and drive engagement."
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Precision Targeting",
            description: "Reach the right audience with laser-focused campaigns."
          },
          {
            icon: <FaGlobe color="#ff5464" size={40} />,
            title: "Global & Local Reach",
            description: "Campaigns that resonate with both Dubai and international audiences."
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Tracking",
            description: "Real-time analytics to measure and optimize campaign success."
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description: "Tailored solutions that align with your unique business needs."
          }
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Proven Results</Subheading>}
        heading={<Heading>Success Stories from Dubai Businesses</Heading>}
        description={
          <Description>
            <strong>Device Repair Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Increased customer inquiries by 40%</li>
              <li>Targeted ads for Bur Dubai residents</li>
              <li>Improved ROI through precise audience targeting</li>
            </ul>

            <strong tw="mt-8 block">Education Provider Campaign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Boosted enrollment inquiries by 35%</li>
              <li>Ads targeting parents and students in Deira</li>
              <li>Enhanced brand visibility through creative campaigns</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Service Areas */}
      <Features
        subheading={<Subheading>Dubai Coverage</Subheading>}
        heading="Serving Businesses Across Dubai"
        description="We work with clients in key areas:"
        cards={[
          {
            title: "Bur Dubai",
            description: "Targeted campaigns for local residents and businesses"
          },
          {
            title: "Deira",
            description: "Ads for education providers, retail, and service businesses"
          },
          {
            title: "Dubai Marina",
            description: "Promotions for luxury brands and lifestyle businesses"
          },
          {
            title: "Business Bay",
            description: "Corporate and real estate marketing solutions"
          },
          {
            title: "Jumeirah",
            description: "Campaigns for high-end hospitality and tourism"
          },
          {
            title: "Jebel Ali",
            description: "Industrial and logistics marketing strategies"
          }
        ]}
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Expert Advantage"
        description="Here's why Dubai businesses trust us:"
        cards={[
          {
            icon: <FaRocket color="#ff5464" size={40} />,
            title: "Fast Execution",
            description: "Quick turnaround without compromising quality."
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Client Collaboration",
            description: "We work closely with you to achieve your goals."
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Innovative Solutions",
            description: "Stay ahead with cutting-edge advertising strategies."
          }
        ]}
      />
    </AnimationRevealPage>
  );
};


