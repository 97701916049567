import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaBullhorn,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// PPC Advertising Services Page Component
export const PPCAdvertisingServicesInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="PPC Advertising Services in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Maximize Your ROI with PPC Advertising</Subheading>}
        heading={
          <Heading>
            Drive Results with{" "}
            <span tw="text-primary-500">PPC Advertising Services in Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in creating high-performing PPC campaigns that drive traffic, generate leads, and boost conversions. As a startup, we bring fresh ideas, data-driven strategies, and a client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
          "https://t4.ftcdn.net/jpg/00/99/55/13/240_F_99551361_wnAC5XjVNH5B6AkGhhqKN8fBWyimmq5Z.jpg", // Digital marketing dashboard
          "https://plus.unsplash.com/premium_photo-1683980578016-a1f980719ec2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fG1hcmtldGluZ3xlbnwwfHwwfHx8MA%3D%3D", // Team analyzing data
          "https://media.istockphoto.com/id/1585515116/photo/close-up-businessman-hand-touch-screen-on-digital-tablet-to-use-marketing-tool-to-announce.jpg?s=612x612&w=0&k=20&c=VBpasa7jf0hx12bidvqywMk5iwgwCFtP_he9NR29G4k=", // Writers working on laptops
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted PPC Advertising Experts"
        description="We combine innovation, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Targeted Campaigns",
            description:
              "Reach your ideal audience with precision targeting across platforms.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Backed by analytics to ensure maximum ROI and campaign performance.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Engagement",
            description:
              "Create ads that resonate with your audience and drive action.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience crafting successful PPC campaigns.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every campaign.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined PPC Advertising Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business and target audience.</li>
              <li>Campaign Strategy: Develop tailored plans for your goals.</li>
              <li>Ad Creation: Design engaging, high-converting ads.</li>
              <li>Performance Tracking: Monitor results and optimize campaigns.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://t3.ftcdn.net/jpg/00/77/87/64/240_F_77876464_oOGha1zZFMNYN0ajaIZz8DLUI5UMdXLI.jpg", // Digital marketing dashboard
          "https://t3.ftcdn.net/jpg/10/10/49/82/240_F_1010498200_9EYKJiO5c5TztbtEITMUjEffc9YclAig.jpg", // Team analyzing data
          "https://t4.ftcdn.net/jpg/03/08/75/65/240_F_308756550_VHoOdRtxuXnoZeMHmIjr6AW7ro6Bcafx.jpg", // Writers working on laptops
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how we’ve helped businesses grow with our PPC advertising services."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Startup Growth",
            description:
              "A local startup saw a 200% increase in leads within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a retail client’s sales by 40% with targeted ad campaigns.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Elevate Your Brand?</Subheading>}
        heading="Let’s Drive Results Together"
        description={
          <Description>
            Partner with us to unlock the full potential of your PPC campaigns. Whether you’re looking to boost your online presence or generate leads, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://t3.ftcdn.net/jpg/04/87/55/40/240_F_487554057_wVYJ4OhJHmGyX64ixrCkkbFVxfwPA0Qo.jpg", // Happy team celebrating
        ]}
        widths={["70%"]}
        heights={["70%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 25%; top: 15%;",
          
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

