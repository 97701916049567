// src/components/Modal.jsx
import React, { useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { gsap } from "gsap";
import FocusTrap from "focus-trap-react";
import { FaTimes } from "react-icons/fa"; // Ensure react-icons is installed

// Styled Components
const Overlay = styled.div`
  ${tw`fixed inset-0 bg-black bg-opacity-100 flex items-center justify-center`}
  z-index: 1000;
`;

const Content = styled.div`
  ${tw`bg-black rounded-lg shadow-lg relative w-full h-full overflow-y-auto`}
  
  /* Animation initial state */
  opacity: 0;
  transform: translateX(100%);
`;

const CloseButton = styled.button`
  ${tw`absolute top-[4rem] right-[4rem] text-gray-100 hover:text-gray-700 focus:outline-none`}
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001; /* Ensure it's above other elements */
`;

const Modal = ({ children, onClose }) => {
  const contentRef = useRef(null);

  const handleClose = useCallback(() => {
    console.log("CloseButton clicked"); // Debugging log
    gsap.to(contentRef.current, {
      duration: 0.7,
      opacity: 0,
      x: 100,
      ease: "power3.in",
      onComplete: () => {
        console.log("Animation complete, closing modal"); // Debugging log
        onClose();
      },
      delay: 0,
    });
  }, [onClose]);

  useEffect(() => {
    // Animate the modal content on mount (slide in from right)
    gsap.to(contentRef.current, {
      duration: 0.9,
      opacity: 1,
      x: 0,
      ease: "power1.in",
      delay: 0.2,
    });

    // Prevent background scrolling when modal is open
    document.body.style.overflow = "hidden";

    // Handle Escape key to close the modal
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      // Re-enable background scrolling when modal is closed
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClose]);

  return (
    <Overlay>
      <FocusTrap>
        <Content ref={contentRef} role="dialog" aria-modal="true">
          <CloseButton
            aria-label="Close Modal"
            onClick={handleClose}
            onMouseDown={(e) => e.stopPropagation()} // Prevent event bubbling
          >
            <FaTimes />
          </CloseButton>
          {children}
        </Content>
      </FocusTrap>
    </Overlay>
  );
};

export default Modal;