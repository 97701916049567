import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaChartLine,
  FaUsers,
  FaHandshake,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Social Media Marketing Page Component
export const SocialMediaMarketingNearAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Social Media Marketing Companies Near Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Your Trusted Social Media Partner Near Al Barsha
          </Subheading>
        }
        heading={
          <Heading>
            Top Social Media Marketing Companies Near{" "}
            <span tw="text-primary-500">Al Barsha</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are one of the leading social media marketing
            companies near Al Barsha. We specialize in creating impactful social
            media strategies that help businesses grow their online presence,
            engage their audience, and drive results.
          </Description>
        }
        imageSrcs={[
          "https://img.freepik.com/free-photo/social-media-marketing-concept-marketing-with-applications_23-2150063164.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Social media team
          "https://img.freepik.com/premium-photo/bangkok-thailand-august-06-2021-people-hold-smartphones-different-brands-operating-systems-with-various-logos-social-applications-twitter-instagram-tiktok-linkedin-line-youtube_102814-4626.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Dubai skyline
          "https://img.freepik.com/premium-vector/realistic-social-media-background_52683-166524.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Team working
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
      />

      {/* Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Social Media Marketing Solutions"
        description="Explore our professional social media marketing services designed to help your business thrive near Al Barsha:"
        cards={[
          {
            icon: <FaInstagram color="#ff5464" size={40} />,
            title: "Instagram Growth Strategy",
            description:
              "Increase followers and engagement with targeted Instagram campaigns.",
          },
          {
            icon: <FaFacebook color="#ff5464" size={40} />,
            title: "Facebook Advertising",
            description:
              "Run cost-effective Facebook ads to reach your target audience near Al Barsha.",
          },
          {
            icon: <FaTwitter color="#ff5464" size={40} />,
            title: "Twitter Branding",
            description:
              "Build a strong Twitter presence with engaging tweets and campaigns.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Analytics",
            description:
              "Track and optimize your social media performance with data-driven insights.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Community Engagement",
            description:
              "Foster meaningful connections with your audience through interactive content.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Content Creation",
            description:
              "Work with our team to create high-quality, engaging social media content.",
          },
        ]}
      />

      {/* Success Stories Section */}
      <MainFeature1
        subheading={<Subheading>Our Impact</Subheading>}
        heading={<Heading>Social Media Success Stories Near Al Barsha</Heading>}
        description={
          <Description>
            <strong>Local Restaurant Growth:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Grew Instagram followers by 200% in three months.</li>
              <li>Increased reservations by 40% through targeted campaigns.</li>
              <li>Achieved measurable results within a limited budget.</li>
            </ul>
            <strong tw="mt-8 block">Fashion Retailer Awareness:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Built a strong Facebook presence for a fashion retailer near Al
                Barsha.
              </li>
              <li>Generated 1,000+ leads through Facebook ads.</li>
              <li>Improved brand visibility across social platforms.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://img.freepik.com/free-photo/social-media-marketing-concept-marketing-with-applications_23-2150063134.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Social media team
          "https://img.freepik.com/free-photo/hand-holding-smartphone-social-media-concept_23-2150208245.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Dubai skyline
          "https://img.freepik.com/free-photo/medium-shot-men-holding-smartphone_23-2150208243.jpg?uid=R120923326&ga=GA1.1.1964859193.1725122772&semt=ais_hybrid", // Team working
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Discover More Success Stories"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Social Media Experts Near Al Barsha"
        description="Here’s why businesses near Al Barsha trust us as their social media partner:"
        cards={[
          {
            icon: <FaInstagram color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "We have years of experience managing successful social media campaigns.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Our strategies are backed by analytics to ensure maximum ROI.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the core of everything we do near Al Barsha.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
