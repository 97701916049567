import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaSearch,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaBullseye,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Expert SEO Services Page Component
export const ExpertSEOInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Expert SEO Services in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted SEO Partner in Bur Dubai</Subheading>}
        heading={
          <Heading>
            Expert SEO Services in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert SEO services tailored to businesses in Bur Dubai. From keyword optimization to technical SEO, we help you rank higher on search engines and drive more organic traffic to your website.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1552664730-d307ca884978?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTM1fHxzZW98ZW58MHx8MHx8fDA%3D", // SEO team
          "https://plus.unsplash.com/premium_photo-1683980578016-a1f980719ec2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2VvfGVufDB8fDB8fHww", // Dubai skyline
          "https://images.unsplash.com/photo-1593437955835-fc8c51725430?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c2VvfGVufDB8fDB8fHww", // Team working
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
      />

      {/* Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive SEO Solutions for Your Business"
        description="Explore our expert SEO services designed to help your business thrive in Bur Dubai:"
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Keyword Optimization",
            description:
              "Target the right keywords to improve your search engine rankings.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Tracking",
            description:
              "Monitor your SEO performance with detailed analytics and reports.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "On-Page SEO",
            description:
              "Optimize your website's content, meta tags, and structure for better visibility.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Technical SEO",
            description:
              "Ensure your website is fast, secure, and mobile-friendly for maximum performance.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Local SEO",
            description:
              "Boost your visibility in Bur Dubai with location-based SEO strategies.",
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Link Building",
            description:
              "Build high-quality backlinks to enhance your domain authority and rankings.",
          },
        ]}
      />

      {/* Success Stories Section */}
      <MainFeature1
        subheading={<Subheading>Our Impact</Subheading>}
        heading={<Heading>SEO Success Stories in Bur Dubai</Heading>}
        description={
          <Description>
            <strong>Local Retailer Growth:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Increased organic traffic by 150% within six months.</li>
              <li>Achieved top rankings for competitive keywords in Bur Dubai.</li>
              <li>Generated measurable ROI through targeted SEO campaigns.</li>
            </ul>
            <strong tw="mt-8 block">Startup Visibility:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Built a strong online presence for a new startup in Bur Dubai.</li>
              <li>Grew organic leads by 80% through strategic SEO efforts.</li>
              <li>Improved local search visibility significantly.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://plus.unsplash.com/premium_photo-1684225765486-1f88c1c42b8e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjV8fHNlb3xlbnwwfHwwfHx8MA%3D%3D", // Search optimization
            "https://images.unsplash.com/photo-1521737711867-e3b97375f902?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTkxfHxzZW98ZW58MHx8MHx8fDA%3D", // Digital marketing
            "https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGN1c3RvbWVyfGVufDB8fDB8fHww", // Digital marketing
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Discover More Success Stories"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your SEO Experts in Bur Dubai"
        description="Here’s why businesses in Bur Dubai trust us as their SEO partner:"
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "We have years of experience delivering successful SEO campaigns.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Our strategies are backed by analytics to ensure maximum ROI.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the core of everything we do in Bur Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

