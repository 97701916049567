import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPenNib,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Content Creation Services Page Component
export const BestContentCreationServicesInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Content Creation Services in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Content Creation Partner</Subheading>}
        heading={
          <Heading>
            Elevate Your Brand with{" "}
            <span tw="text-primary-500">Best Content Creation Services in Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we craft high-quality, engaging content that drives traffic, boosts SEO, and connects with your audience. As a startup, we bring fresh ideas, agility, and a client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
            "https://media.istockphoto.com/id/506610728/photo/blog-weblog-media-digital-dictionary-online-concept.webp?a=1&b=1&s=612x612&w=0&k=20&c=ilt_0ph4UnF49IaEPwVI3tm111BrFNN1mLRWR4tATSE=", // Content team
            "https://images.unsplash.com/photo-1617899516937-54fb61f7d3d2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDB8fGNvbnRlbnQlMjBjcmVhdG9yfGVufDB8fDB8fHww", // Dubai skyline
            "https://images.unsplash.com/photo-1581464647113-0196a7dc232b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y29udGVudCUyMGNyZWF0b3J8ZW58MHx8MHx8fDA%3D", // Team working
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Content Creation Experts"
        description="We combine innovation, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience crafting impactful content across industries.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Backed by analytics to ensure maximum engagement and ROI.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Engaging Storytelling",
            description:
              "Compelling narratives that resonate with your audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Timely Delivery",
            description:
              "High-quality content delivered on schedule, every time.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Content Creation Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your brand and audience.</li>
              <li>Content Strategy: Develop tailored plans for your goals.</li>
              <li>Content Creation: Craft engaging, SEO-optimized content.</li>
              <li>Performance Tracking: Monitor results and refine strategies.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://plus.unsplash.com/premium_photo-1663931932651-ea743c9a0144?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Y2hhcnR8ZW58MHx8MHx8fDA%3D", // Content team
            "https://plus.unsplash.com/premium_photo-1684179641331-e89c6320b6a9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8bWFya2V0aW5nfGVufDB8fDB8fHww", // Dubai skyline
            "https://media.istockphoto.com/id/980243748/photo/successful-business-colleagues-giving-high-five-in-office.webp?a=1&s=612x612&w=0&k=20&c=MPYUUy0fVYLBeNVAvLxeHZPVdFhN-Z_kDhxuIugmfIU=", // Team working
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how we’ve helped businesses grow with our content creation services."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Startup Growth",
            description:
              "A local startup saw a 150% increase in website traffic within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent quality.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a retail client’s sales by 40% with engaging campaigns.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Elevate Your Brand?</Subheading>}
        heading="Let’s Create Impactful Content Together"
        description={
          <Description>
            Partner with us to unlock the full potential of your content strategy. Whether you’re looking to boost your online presence or tell your brand’s story, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjB8fGNsaWVudHxlbnwwfHwwfHx8MA%3D%3D"// Happy team celebrating
        
        ]}
        widths={["70%"]}
        heights={["70%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 15%; top: 15%;",
        
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
