// src/context/CursorContext.jsx
import React, { createContext, useState } from "react";

export const CursorContext = createContext();

export const CursorProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(true);
  const [info, setInfo] = useState(null); // Stores structural info

  return (
    <CursorContext.Provider value={{ isActive, setIsActive, info, setInfo }}>
      {children}
    </CursorContext.Provider>
  );
};
