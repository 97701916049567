import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaLaptopCode,
  FaCode,
  FaServer,
  FaUsers,
  FaHandshake,
  FaRocket,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const WebDevelopmentAgencyDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Web Development Agency in Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Web Development Partner in Dubai</Subheading>}
        heading={
          <Heading>
            Building Dynamic Websites in{" "}
            <span tw="text-primary-500">Dubai</span>
          </Heading>
        }
        description={
          <Description>
            As a web development agency in Dubai, we specialize in creating
            custom websites that drive growth and engage your audience. From
            e-commerce platforms to corporate websites, we ensure your online
            presence stands out in Dubai's competitive market.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress&cs=tinysrgb&w=600",
          "https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg?ga=GA1.1.1964859193.1725122772&semt=ais_hybrid"
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Web Project"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Web Development Solutions"
        description="We offer a full range of web development services to help your business thrive online in Dubai:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Custom Website Development",
            description:
              "Building tailored websites that meet your specific business needs in Dubai.",
          },
          {
            icon: <FaCode color="#ff5464" size={40} />,
            title: "E-commerce Solutions",
            description:
              "Creating robust e-commerce platforms to drive sales in Dubai.",
          },
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "Website Maintenance",
            description:
              "Regular updates and maintenance to keep your website running smoothly in Dubai.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "User Experience Design",
            description:
              "Designing intuitive and user-friendly interfaces for your website in Dubai.",
          },
          {
            icon: <FaRocket color="#ff5464" size={40} />,
            title: "SEO Integration",
            description:
              "Incorporating SEO best practices to improve your website's search engine rankings in Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Project Management",
            description:
              "Managing your project from start to finish to ensure timely delivery in Dubai.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Web Development Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Platform Development:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Launched a user-friendly e-commerce platform in Dubai</li>
              <li>Increased sales by 50% within the first year</li>
              <li>Received positive feedback from customers in Dubai</li>
            </ul>
            <strong tw="mt-8 block">Corporate Website Redesign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Redesigned a corporate website to improve user experience</li>
              <li>Enhanced brand visibility and engagement in Dubai</li>
              <li>Boosted lead generation by 30%</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1547658719-da2b51169166?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdlYiUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww",
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8d2ViJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D"
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Dubai Web Development Experts"
        description="Here’s why businesses in Dubai trust us for their web development needs:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Local Expertise",
            description:
              "We understand the unique web development challenges of Dubai businesses.",
          },
          {
            icon: <FaRocket color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We deliver measurable improvements in website performance and user engagement in Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description: "Your vision is at the heart of everything we do in Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

