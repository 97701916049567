import React from "react";
import tw, { styled } from "twin.macro";
import pageBackground from "images/header-back-pages.svg";
import Header from "Components/headers/header";

const HeaderContainer = styled.header`
  ${tw`relative mx-auto bg-center overflow-hidden`}
  background-image: url(${pageBackground});
  border-bottom-left-radius: 100px; /* Adjust radius as needed */
  border-bottom-right-radius: 100px; /* Adjust radius as needed */
  background-size: cover;
  background-position: center;
  height: 472px;
  width: 100%;
  padding-top: 60px; // Increase padding for more space
`;

const OpacityOverlay = tw.div`absolute inset-0 bg-[rgba(0, 0, 0, 0.8)] z-10`;

const ContentWrapper = styled.div`
  ${tw`z-20 flex flex-col lg:mx-24 md:mx-24 sm:mx-12 items-start justify-center h-full`}
`;

const Title = styled.h1`
  ${tw`text-3xl sm:text-4xl md:text-5xl lg:text-6xl mx-4 font-bold mb-4 z-30`}
  background: linear-gradient(to bottom, #f5d7da, #ff5464);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const HeaderWrapper = styled.header`
  ${tw`mt-16`}
`;

const PageHeader = (props) => {
  // Function to insert a <br /> after every two words
  const formatTitle = (text) => {
    const words = text.split(" ");
    const formatted = [];

    for (let i = 0; i < words.length; i += 2) {
      const line = words.slice(i, i + 2).join(" ");
      formatted.push(line);
    }

    return formatted.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <HeaderContainer from={props.from}>
      <OpacityOverlay />
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <ContentWrapper>
        <Title>{formatTitle(props.from)}</Title>
      </ContentWrapper>
    </HeaderContainer>
  );
};

export default PageHeader;
