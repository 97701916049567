import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  SectionHeading
} from "Components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "Components/misc/Buttons.js";

// Container for the entire section
const Container = styled.div(({ fromAbout }) => [
  tw`relative text-white text-2xl py-10 lg:py-8 rounded-t-5xl `,
  fromAbout ? tw`bg-black ` : tw`bg-white`,
  tw`z-[1]`,
]);

// TwoColumn layout
const TwoColumn = styled.div(({ hasImage }) => [
  tw`flex flex-col justify-between max-w-screen-xl mx-auto`,
  hasImage ? tw`md:flex-row sm:mx-20 md:mx-12 lg:mx-10 xl:mx-auto mx-4` : tw`md:flex-col`,
]);

// Generic Column
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 sm:mx-0 xs:mx-0`;

// ImageColumn with additional styles
const ImageColumn = styled(Column)((props) => [
  tw`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`,
]);

// ImageContainer for handling image styles and positions
const ImageContainer = styled.div`
  ${tw`rounded-2xl bg-cover bg-center absolute`}
  ${(props) => `background-image: url(${props.src});`}
  ${(props) => props.imageStyle}
  ${(props) => {
    const positions = props.positions || [
      "left: 0; top: 0;",
      "right: 0; top: 10%;",
      "left: 10%; bottom: 0;",
    ];
    return positions[props.index % positions.length];
  }}

  width: ${(props) => props.widths || "100%"};
  height: ${(props) => props.heights || "100%"};

  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);

  &:hover {
    transform: scale(1.05);
    z-index: 10;
  }
`;

// TextColumn with conditional styling based on props
const TextColumn = styled(Column)((props) => [
  tw`mt-12 md:mt-0`,
  props.hasImage ? tw`md:w-7/12` : tw`w-full`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-12 md:order-first`
    : tw`md:ml-8 lg:ml-12 md:order-last`,
]);

// TextContent for styling the inner text
const TextContent = tw.div`px-0 lg:py-8 xl:py-8 font-poppins text-left`;

// SubheadingWrapper to style subheading with lines
export const SubheadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;

// Line component for decorative lines
export const Line = styled.div`
  height: 1px;
  width: 50px;
  background-color: grey;
`;

// Text component for subheading text
export const Text = styled.h2`
  padding: 0 5px;
  color: #ff5464;
  font-size: 20px;
  white-space: nowrap;
`;


// Heading styled component with left alignment on small screens
const Heading = styled(SectionHeading)(({ fromAbout }) => [
  tw`mt-4 text-left text-2xl sm:text-3xl md:text-4xl lg:text-5xl leading-tight`,
  fromAbout && tw`text-white`,
]);

// Description styled component with left alignment on small screens
const Description = tw.p`mt-4 text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// PrimaryButton styled component without centering
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-8 text-sm inline-block md:mx-0`;

// Call to Action Wrapper
const CallToActionWrapper = styled.div`
  ${tw`flex flex-col w-4/6 p-6 bg-customPurple rounded-3xl shadow-lg ml-5 mt-8`}
`;

// Call to Action Text
const CallToActionText = styled.p`
  ${tw`text-start text-black text-2xl md:text-3xl lg:text-3xl xl:text-3xl font-medium mb-4`}
`;

// Call to Action Button
const CallToActionButton = styled.button`
  ${tw`bg-transparent text-white text-lg font-medium border-2 py-2 md:py-4 xl:py-4 lg:py-4 px-8 rounded-lg hover:bg-white hover:cursor-pointer hover:text-customPurple hover:border-transparent transition-all duration-300`}

 
  }
`;

// Span for styling specific text within Call to Action
const Span = styled.span`
  ${tw`text-white`}
`;

// Main Component
export default function TwoColWithButton({
  subheading = "OUR MISSION",
  heading = <>Transforming Ideas Into Exceptional Experiences</>,
  description = "At MilaajBrandset, we deliver exceptional digital solutions tailored to your needs. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  primaryButtonText = "Contact Us",
  primaryButtonUrl = "https://milaajbrandset.com/contact",
  imageSrcs = [], // Default to empty array
  textOnLeft = false,
  widths = [], // Default widths for each image
  heights = [], // Default heights for each image
  imageColumnStyle = ``,
  imageStyle = ``,
  imagePositions = null,
  from = "",
  fromAbout = false,
}) {
  const hasImage = imageSrcs && imageSrcs.length > 0;

  return (
    <Container fromAbout={fromAbout}>
      <TwoColumn hasImage={hasImage}>
        {/* Conditionally render Image Column */}
        {hasImage && (
          <ImageColumn imageColumnStyle={imageColumnStyle}>
            {imageSrcs.map((src, index) => (
              <ImageContainer
                key={index}
                src={src}
                index={index}
                widths={widths[index]}
                heights={heights[index]}
                imageStyle={imageStyle}
                positions={imagePositions}
              />
            ))}
          </ImageColumn>
        )}

        {/* Always render Text Column */}
        <TextColumn textOnLeft={textOnLeft} hasImage={hasImage}>
          <TextContent>
            <SubheadingWrapper>
              <Line />
              <Text>{subheading}</Text>
              <Line />
            </SubheadingWrapper>
            <Heading fromAbout={fromAbout}>{heading}</Heading>
            <Description>{description}</Description>

            {/* Conditional Rendering for CTA or Primary Button */}
            {from === "boost" ? (
              <CallToActionWrapper>
                <CallToActionText>
                  Ready to <Span className={`text-black font-bold`}>elevate</Span>{" "}
                  your brand?
                </CallToActionText>
                <a href="tel:+9710505225056">
                  <CallToActionButton>Let’s Chat</CallToActionButton>
                </a>
              </CallToActionWrapper>
            ) : (
              <PrimaryButton as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </PrimaryButton>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
}
