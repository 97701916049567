import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaCreativeCommons,
  FaPaintBrush,
  FaLightbulb,
  FaUsers,
  FaChartLine,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const CreativeBrandingAgencyNearDifc = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Creative Branding Agency Near DIFC" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Creative Branding Partner Near DIFC</Subheading>}
        heading={
          <Heading>
            Creative Branding Agency Near{" "}
            <span tw="text-primary-500">DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are a creative branding agency located near DIFC,
            dedicated to helping businesses grow and succeed. From brand identity
            to marketing strategy, we provide innovative and high-quality branding
            solutions tailored to your specific needs in the heart of Dubai.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3",
            "https://images.unsplash.com/photo-1521737711867-e3b97375f902?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3",
            "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3",
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Branding Journey"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Innovative Creative Branding Solutions"
        description="We offer a wide range of creative branding services to help your business thrive near DIFC:"
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Brand Identity",
            description:
              "Crafting unique logos, color schemes, and visual styles tailored for your business near DIFC.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Brand Strategy",
            description:
              "Developing a clear vision and positioning for your brand near DIFC.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "User Experience Design",
            description:
              "Designing intuitive and user-friendly interfaces for your brand in DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Process",
            description:
              "Working closely with you to understand your vision and goals near DIFC.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Creative Content Creation",
            description:
              "Generating engaging and creative content to captivate your audience near DIFC.",
          },
          {
            icon: <FaCreativeCommons color="#ff5464" size={40} />,
            title: "Brand Launch",
            description:
              "Rolling out your brand with impactful campaigns near DIFC.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Creative Branding Success Stories</Heading>}
        description={
          <Description>
            <strong>Device Repair Branding:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Created a modern, trustworthy brand identity near DIFC</li>
              <li>Increased customer trust and recognition near DIFC</li>
              <li>Delivered within a small business budget near DIFC</li>
            </ul>
            <strong tw="mt-8 block">Education Provider Branding:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Developed a professional and approachable brand near DIFC</li>
              <li>Boosted enrollment inquiries by 35%</li>
              <li>Enhanced brand visibility across DIFC</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1581091870623-4c4e1b6d1cae?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3",
            "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3",
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3",
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Creative Branding Experts Near DIFC"
        description="Here’s why businesses near DIFC trust us for their branding needs:"
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Innovative Ideas",
            description:
              "Our team generates innovative and creative ideas to set your business apart near DIFC.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Success",
            description:
              "We have a track record of delivering successful branding projects near DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Focus",
            description: "Your goals and vision are at the heart of everything we do near DIFC.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

