import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaSearch,
  FaChartBar,
  FaBullseye,
  FaUsers,
  FaHandshake,
  FaMapMarkerAlt,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const LocalSeoServicesAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Local SEO Services in Al Barsha" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Local SEO Partner in Al Barsha</Subheading>}
        heading={
          <Heading>
            Boost Your Online Presence in{" "}
            <span tw="text-primary-500">Al Barsha</span>
          </Heading>
        }
        description={
          <Description>
            As a local SEO agency in Al Barsha, we specialize in driving organic
            traffic and improving search engine rankings for businesses in your
            area. From keyword research to technical optimization, we ensure your
            website ranks higher and attracts more local customers in Al Barsha.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1511735656224-44b91ac72d4f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Local SEO
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODR8fHNlb3xlbnwwfHwwfHx8MA%3D%3D",
            "https://plus.unsplash.com/premium_photo-1684225765446-c929e4db2d29?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NzN8fHNlb3xlbnwwfHwwfHx8MA%3D%3D",
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your SEO Journey"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Local SEO Solutions"
        description="We offer a full range of local SEO services to help your business thrive in Al Barsha:"
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Local Keyword Research",
            description:
              "Identifying high-value local keywords to target your audience in Al Barsha.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "On-Page Optimization",
            description:
              "Optimizing content, meta tags, and URLs for better rankings in Al Barsha search engines.",
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Technical SEO",
            description:
              "Improving site speed, mobile-friendliness, and crawlability in Al Barsha.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Content Strategy",
            description:
              "Creating engaging and SEO-friendly content tailored for the Al Barsha market.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Local Business Listings",
            description:
              "Optimizing Google My Business and other local directories in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Performance Tracking",
            description:
              "Monitoring and reporting on key SEO metrics for continuous improvement in Al Barsha.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Local SEO Success Stories</Heading>}
        description={
          <Description>
            <strong>Restaurant SEO Optimization:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Increased local search visibility in Al Barsha</li>
              <li>Boosted reservations by 40%</li>
              <li>Received positive reviews from customers in Al Barsha</li>
            </ul>
            <strong tw="mt-8 block">Retail Store SEO:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Improved local search rankings in Al Barsha</li>
              <li>Enhanced foot traffic by 35%</li>
              <li>Increased sales by 25%</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://plus.unsplash.com/premium_photo-1684356819451-35a6a50ba57e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDV8fHNlb3xlbnwwfHwwfHx8MA%3D%3D", // Search optimization
            "https://plus.unsplash.com/premium_photo-1683872921964-25348002a392?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTd8fHNlb3xlbnwwfHwwfHx8MA%3D%3D",
            "https://images.unsplash.com/photo-1624555130581-1d9cca783bc0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDd8fHNlb3xlbnwwfHwwfHx8MA%3D%3D", // Digital marketing
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Local SEO Experts in Al Barsha"
        description="Here’s why businesses in Al Barsha trust us for their SEO needs:"
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Local Expertise",
            description:
              "We understand the unique SEO challenges of Al Barsha and deliver tailored solutions.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We have a track record of delivering successful local SEO projects in Al Barsha.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description: "Your goals and vision are at the heart of everything we do in Al Barsha.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

