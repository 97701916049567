import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaBullhorn,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaLightbulb,
  FaGlobe,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const DigitalMarketingAgencyNearDifc = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Digital Marketing Agency Near DIFC" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Digital Marketing Partner Near DIFC</Subheading>}
        heading={
          <Heading>
            Digital Marketing Agency Near{" "}
            <span tw="text-primary-500">DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are a digital marketing agency located near DIFC,
            dedicated to helping businesses grow and succeed. From SEO to social
            media marketing, we provide comprehensive digital marketing solutions
            tailored to your specific needs in the heart of Dubai.
          </Description>
        }
        imageSrcs={[
            "https://images.pexels.com/photos/6476246/pexels-photo-6476246.jpeg?auto=compress&cs=tinysrgb&w=600", // Dubai skyline
          "https://images.pexels.com/photos/6483583/pexels-photo-6483583.jpeg?auto=compress&cs=tinysrgb&w=600", // Modern office with laptops
          "https://images.pexels.com/photos/6476249/pexels-photo-6476249.jpeg?auto=compress&cs=tinysrgb&w=600", // Team working on a project
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "80%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Digital Marketing Campaign"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Digital Marketing Solutions"
        description="We offer a full range of digital marketing services to help your business thrive near DIFC:"
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "SEO Services",
            description:
              "Driving organic traffic and improving search engine rankings near DIFC.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Social Media Marketing",
            description:
              "Effective social media strategies to engage and grow your brand near DIFC.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Content Marketing",
            description:
              "High-quality content creation to drive traffic and conversions near DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Email Marketing",
            description:
              "Targeted email campaigns to nurture leads and increase customer engagement near DIFC.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "PPC Advertising",
            description:
              "Pay-per-click advertising to drive immediate traffic and results near DIFC.",
          },
          {
            icon: <FaGlobe color="#ff5464" size={40} />,
            title: "Online Reputation Management",
            description:
              "Managing and improving your online reputation to attract more customers near DIFC.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Digital Marketing Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Digital Marketing:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Implemented a successful digital marketing campaign for an e-commerce business near DIFC</li>
              <li>Increased website traffic by 60%</li>
              <li>Boosted sales by 45%</li>
            </ul>
            <strong tw="mt-8 block">Corporate Digital Marketing:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Developed a comprehensive digital marketing strategy for a corporate business near DIFC</li>
              <li>Enhanced brand awareness by 50%</li>
              <li>Generated 30% more leads</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1547658719-da2b51169166?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdlYiUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww", // Server room
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3", // Dubai skyline
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8d2ViJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D", // Data center
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Digital Marketing Experts Near DIFC"
        description="Here’s why businesses near DIFC trust us for their digital marketing needs:"
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Local Expertise",
            description:
              "We understand the unique digital marketing challenges and opportunities near DIFC.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We deliver measurable improvements in brand awareness and sales near DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description: "Your goals and vision are at the heart of everything we do near DIFC.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

