import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaLaptopCode,
  FaPalette,
  FaUsers,
  FaHandshake,
  FaBullseye,
  FaAward,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const DifcProfessionalWebDesigners = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="DIFC Professional Web Designers" />
      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Web Design Partner in DIFC</Subheading>}
        heading={
          <Heading>
            DIFC Professional Web Designers for{" "}
            <span tw="text-primary-500">Exceptional Websites</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are a startup dedicated to providing professional
            web design services in DIFC. Our team of talented designers creates
            unique and functional websites tailored to your business needs. From
            branding to user experience, we ensure your online presence stands out
            and drives growth in DIFC.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Modern website design
          "https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Creative web design process
          "https://images.unsplash.com/photo-1534972195531-d756b9bfa9f2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Team collaboration
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Meet Our Designers"
      />
      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Professional Web Design Services in DIFC"
        description="We offer a full range of professional web design services to help your business thrive online in DIFC:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Custom Website Design",
            description:
              "Creating unique and visually appealing designs tailored for your business in DIFC.",
          },
          {
            icon: <FaPalette color="#ff5464" size={40} />,
            title: "Branding & Identity",
            description:
              "Developing a strong brand identity and logo design for your business in DIFC.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "User Experience Design",
            description:
              "Designing intuitive and user-friendly interfaces for your website in DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Process",
            description:
              "Working closely with you to understand your vision and goals in DIFC.",
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "SEO Integration",
            description:
              "Incorporating SEO best practices to improve your website's search engine rankings in DIFC.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Quality Assurance",
            description:
              "Ensuring high-quality design and functionality for your website in DIFC.",
          },
        ]}
      />
      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Professional Web Design Success Stories</Heading>}
        description={
          <Description>
            <strong>E-commerce Website Design:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Designed a modern and user-friendly e-commerce platform in DIFC</li>
              <li>Increased sales by 45% within the first year</li>
              <li>Received positive feedback from customers in DIFC</li>
            </ul>
            <strong tw="mt-8 block">Corporate Website Design:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Created a professional and engaging corporate website in DIFC</li>
              <li>Enhanced brand visibility and engagement in DIFC</li>
              <li>Boosted lead generation by 30%</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // E-commerce website
          "https://images.unsplash.com/photo-1585834275571-442109c918d2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Corporate website
          "https://images.unsplash.com/photo-1587614387466-0a72ca909e16?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Web analytics
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />
      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The DIFC Professional Web Designers"
        description="Here’s why businesses in DIFC trust us for their web design needs:"
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Expert Design Team",
            description:
              "Our team of professional web designers has extensive experience in creating exceptional websites in DIFC.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Innovative Solutions",
            description:
              "We offer innovative and cutting-edge design solutions to set your business apart in DIFC.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description: "Your vision and success are our top priority in DIFC.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

