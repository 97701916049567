import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPaintBrush,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Logo Design and Branding Page Component
export const LogoDesignAndBrandingInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Logo Design and Branding in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Trusted Logo Design and Branding Partner</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Brand with{" "}
            <span tw="text-primary-500">
              Top Logo Design and Branding in Bur Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in crafting visually stunning logos
            and cohesive brand identities that resonate with your audience and
            drive business growth. As a startup, we bring fresh ideas,
            data-driven insights, and a client-centric approach to every
            project.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1524863479829-916d8e77f114?ixlib=rb-4.0.3", // Designer creating logo
          "https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?ixlib=rb-4.0.3", // Logo design sketches
          "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3", // Digital branding workstation
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Logo Design and Branding Experts"
        description="We combine innovation, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Visually Stunning Logos",
            description:
              "Our designs create a strong and memorable brand presence.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description: "Backed by research to ensure maximum impact and ROI.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience crafting successful brands and logos.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Logo Design and Branding Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Strategy Development: Create tailored plans for your goals.
              </li>
              <li>
                Design Development: Craft visually appealing logos and brand
                elements.
              </li>
              <li>
                Implementation: Roll out your brand identity across platforms.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1558655146-d09347e92766?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGRlc2lnbiUyMGFnZW5jeXxlbnwwfHwwfHx8MA%3D%3D", // Brand identity materials
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3", // Team collaborating on logo design
          "https://images.unsplash.com/photo-1634942537034-2531766767d1?ixlib=rb-4.0.3", // Logo mockups and presentation
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our logo design and branding services have helped businesses grow."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Brand Growth",
            description:
              "A local startup saw a 350% increase in brand recognition within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a retail client’s sales by 60% with a rebranded identity.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Elevate Your Brand?</Subheading>}
        heading="Let’s Build Your Brand Together"
        description={
          <Description>
            Partner with our expert logo design and branding agency in Bur Dubai
            to unlock the full potential of your brand. Whether you’re looking
            to refresh your identity or launch a new brand strategy, we’re here
            to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1572044162444-ad60f128bdea?ixlib=rb-4.0.3", // Logo design workspace
          "https://images.unsplash.com/photo-1558403194-611308249627?ixlib=rb-4.0.3", // Team reviewing logo concepts
          "https://images.unsplash.com/photo-1434626881859-194d67b2b86f?ixlib=rb-4.0.3", // Final brand presentation
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
