import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaPenNib,
  FaVideo,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Content Creation Services Page Component
export const ContentCreationServicesInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Content Creation Services in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Content Creation Partner in Bur Dubai</Subheading>}
        heading={
          <Heading>
            Professional Content Creation Services in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering high-quality content creation services in Bur Dubai. From engaging blog posts to captivating videos, we help businesses tell their stories and connect with their audience effectively.
          </Description>
        }
        imageSrcs={[
          "https://media.istockphoto.com/id/506610728/photo/blog-weblog-media-digital-dictionary-online-concept.webp?a=1&b=1&s=612x612&w=0&k=20&c=ilt_0ph4UnF49IaEPwVI3tm111BrFNN1mLRWR4tATSE=", // Content team
          "https://images.unsplash.com/photo-1617899516937-54fb61f7d3d2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDB8fGNvbnRlbnQlMjBjcmVhdG9yfGVufDB8fDB8fHww", // Dubai skyline
          "https://images.unsplash.com/photo-1581464647113-0196a7dc232b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y29udGVudCUyMGNyZWF0b3J8ZW58MHx8MHx8fDA%3D", // Team working
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
      />

      {/* Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Content Creation Solutions"
        description="Explore our range of professional content creation services designed to help your business thrive in Bur Dubai:"
        cards={[
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Blog Writing",
            description:
              "Create engaging and informative blog posts that attract and educate your audience.",
          },
          {
            icon: <FaVideo color="#ff5464" size={40} />,
            title: "Video Production",
            description:
              "Produce high-quality videos that captivate your audience and boost engagement.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Tracking",
            description:
              "Monitor the impact of your content with detailed analytics and reports.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Social Media Content",
            description:
              "Develop creative and shareable content for platforms like Instagram, Facebook, and Twitter.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Collaborative Strategy",
            description:
              "Work closely with our team to align your vision with actionable content plans.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Creative Copywriting",
            description:
              "Craft compelling copy that resonates with your target audience and drives action.",
          },
        ]}
      />

      {/* Success Stories Section */}
      <MainFeature1
        subheading={<Subheading>Our Impact</Subheading>}
        heading={<Heading>Success Stories in Bur Dubai</Heading>}
        description={
          <Description>
            <strong>Local Retailer Growth:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Increased website traffic by 80% through consistent blog content.</li>
              <li>Improved customer engagement with targeted social media posts.</li>
              <li>Achieved measurable results within a limited budget.</li>
            </ul>
            <strong tw="mt-8 block">Startup Brand Awareness:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Built a strong online presence for a new startup in Bur Dubai.</li>
              <li>Grew email subscribers by 50% with engaging newsletters.</li>
              <li>Generated leads at a low cost-per-acquisition rate.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://plus.unsplash.com/premium_photo-1663931932651-ea743c9a0144?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Y2hhcnR8ZW58MHx8MHx8fDA%3D", // Content team
          "https://plus.unsplash.com/premium_photo-1684179641331-e89c6320b6a9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8bWFya2V0aW5nfGVufDB8fDB8fHww", // Dubai skyline
          "https://media.istockphoto.com/id/980243748/photo/successful-business-colleagues-giving-high-five-in-office.webp?a=1&s=612x612&w=0&k=20&c=MPYUUy0fVYLBeNVAvLxeHZPVdFhN-Z_kDhxuIugmfIU=", // Team working
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Discover More Success Stories"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Content Creation Experts in Bur Dubai"
        description="Here’s why businesses in Bur Dubai trust us as their content creation partner:"
        cards={[
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "We bring fresh, innovative ideas to every content project in Bur Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "Our track record speaks for itself—delivering impactful content solutions in Bur Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your goals and vision are at the core of everything we do in Bur Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

