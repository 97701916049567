import { AdvertisingAgencyDubai } from "Pages/SeoPages/Advertising/dubai/AdvertisingAgencyDubai";
import { AffordableAdvertisingAgencyDubai } from "Pages/SeoPages/Advertising/dubai/AffordableAdvertisingAgencyDubai";
import { BestAdvertisingAgencyDubai } from "Pages/SeoPages/Advertising/dubai/BestAdvertisingAgencyDubai";
import { CreativeAdvertisingAgencyDubai } from "Pages/SeoPages/Advertising/dubai/CreativeAdvertisingAgencyDubai";
import { ExcellentAdvertisingAgencyDubai } from "Pages/SeoPages/Advertising/dubai/ExcellentAdvertisingAgencyDubai";
import { ExpertAdvertisingAgencyDubai } from "Pages/SeoPages/Advertising/dubai/ExpertAdvertisingAgencyDubai";
import { No1AdvertisingAgencyDubai } from "Pages/SeoPages/Advertising/dubai/No1AdvertisingAgencyDubai";
import { ReliableAdvertisingAgencyDubai } from "Pages/SeoPages/Advertising/dubai/ReliableAdvertisingAgencyDubai";
import { TopAdvertisingAgencyDubai } from "Pages/SeoPages/Advertising/dubai/TopAdvertisingAgencyDubai";
import { Helmet } from "react-helmet";


export const AdvertisingAgencyDubaiPage = () => (
    <>
      <Helmet>
        <title>Top Advertising Agency in Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset: Premier advertising agency serving all Dubai areas. Expert marketing solutions for Downtown, Marina, JLT, and city-wide campaigns. Drive growth in UAE's competitive market."
        />
        <meta
          name="keywords"
          content="Dubai advertising agency, UAE marketing solutions, city-wide campaigns, Dubai marketing strategy, Emirates business growth"
        />
        <meta
          property="og:title"
          content="Leading Advertising Agency in Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="Full-service Dubai marketing agency offering city-wide campaigns and Emirates-focused strategies. Expertise in Dubai's competitive business landscape."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://www.yourdomain.com/logo.jpg",
              "@id": "",
              "url": "https://www.yourdomain.com/advertising-agency-dubai",
              "telephone": "+971-4-XXX-XXXX",
              "priceRange": "$$$",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Business Bay",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.204849,
                "longitude": 55.270782
              },
              "sameAs": [
                "https://www.facebook.com/yourpage",
                "https://www.linkedin.com/company/yourcompany"
              ]
            }
          `}
        </script>
      </Helmet>
      <AdvertisingAgencyDubai />
    </>
  );


export const BestAdvertisingAgencyDubaiPage = () => (
    <>
      <Helmet>
        <title>Best Advertising Agency in Dubai | Award-Winning 2024 | MilaajBrandset</title>
        <meta
          name="description"
          content="Voted Dubai's Best Advertising Agency 2024 - Proven strategies delivering 3.8X ROI. 5-Time Award Winners with 97% client retention. See why top Dubai businesses choose us."
        />
        <meta
          name="keywords"
          content="best advertising agency in Dubai, top Dubai marketing firm, award-winning Dubai agency, #1 Dubai advertisers"
        />
        <meta property="og:type" content="website" />
        <meta 
          property="og:title" 
          content="#1 Best Advertising Agency in Dubai | MilaajBrandset" 
        />
        <meta
          property="og:description"
          content="Officially recognized as Dubai's top advertising agency. View our award-winning campaigns and client success stories."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "MilaajBrandset",
              "url": "https://www.milaajbrandset.com/best-advertising-agency-dubai",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "Award-winning advertising agency recognized as Dubai's best marketing partner",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "bestRating": "5",
                "ratingCount": "89"
              },
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Business Bay",
                "addressLocality": "Dubai",
                "addressCountry": "AE"
              }
            }
          `}
        </script>
      </Helmet>
      <BestAdvertisingAgencyDubai />
    </>
  );  


 export const ProfessionalAdvertisingAgencyDubaiPage = () => (
    <>
      <Helmet>
        <title>Professional Advertising Agency in Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a professional advertising agency in Dubai, offering tailored marketing solutions for businesses. From strategic campaigns to creative design, we deliver results."
        />
        <meta
          name="keywords"
          content="professional advertising agency in Dubai, Dubai marketing agency, advertising services Dubai, digital marketing Dubai"
        />
        <meta
          property="og:title"
          content="Professional Advertising Agency in Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="Trusted by Dubai businesses for professional advertising solutions. We deliver strategic campaigns, creative design, and digital marketing services."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "Professional advertising agency in Dubai offering tailored marketing solutions.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Business Bay",
                "addressLocality": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2048,
                "longitude": 55.2708
              },
              
            }
          `}
        </script>
      </Helmet>
      <ProfessionalAdvertisingAgencyDubaiPage />
    </>
  );


  export const ExpertAdvertisingAgencyDubaiPage = () => (
    <>
      <Helmet>
        <title>Expert Advertising Agency in Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is an expert advertising agency in Dubai, offering strategic, creative, and data-driven marketing solutions for businesses. Trusted by Dubai's top brands."
        />
        <meta
          name="keywords"
          content="expert advertising agency in Dubai, Dubai marketing experts, advertising solutions Dubai, strategic marketing Dubai"
        />
        <meta
          property="og:title"
          content="Expert Advertising Agency in Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="Dubai's trusted expert advertising agency. We deliver strategic, creative, and data-driven marketing solutions for businesses of all sizes."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "Expert advertising agency in Dubai offering strategic, creative, and data-driven marketing solutions.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Business Bay",
                "addressLocality": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2048,
                "longitude": 55.2708
              },
              
            }
          `}
        </script>
      </Helmet>
      <ExpertAdvertisingAgencyDubai />
    </>
  );


  export const AffordableAdvertisingAgencyDubaiPage = () => (
    <>
      <Helmet>
        <title>Affordable Advertising Agency in Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is an affordable advertising agency in Dubai, offering high-quality marketing solutions tailored to your budget. Get exceptional results without breaking the bank."
        />
        <meta
          name="keywords"
          content="affordable advertising agency in Dubai, budget-friendly marketing Dubai, low-cost advertising Dubai, Dubai advertising agency"
        />
        <meta
          property="og:title"
          content="Affordable Advertising Agency in Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="Dubai's most affordable advertising agency. We deliver high-quality marketing solutions tailored to your budget."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "Affordable advertising agency in Dubai offering budget-friendly marketing solutions.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Business Bay",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2048,
                "longitude": 55.2708
              },
            }
          `}
        </script>
      </Helmet>
      <AffordableAdvertisingAgencyDubai />
    </>
  );
  

  export const No1AdvertisingAgencyDubaiPage = () => (
    <>
      <Helmet>
        <title>No.1 Advertising Agency in Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a fast-growing advertising agency in Dubai, delivering innovative and results-driven marketing solutions for businesses of all sizes."
        />
        <meta
          name="keywords"
          content="advertising agency in Dubai, Dubai marketing agency, creative advertising Dubai, affordable advertising Dubai"
        />
        <meta
          property="og:title"
          content="No.1 Advertising Agency in Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="A fast-growing advertising agency in Dubai, delivering innovative and results-driven marketing solutions for businesses of all sizes."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "A fast-growing advertising agency in Dubai, delivering innovative and results-driven marketing solutions.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Business Bay",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2048,
                "longitude": 55.2708
              },
              
            }
          `}
        </script>
      </Helmet>
      <No1AdvertisingAgencyDubai />
    </>
  );

  export const TopAdvertisingAgencyDubaiPage = () => (
    <>
      <Helmet>
        <title>Top Advertising Agency in Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a top advertising agency in Dubai, offering innovative marketing solutions. Trusted by startups and businesses for creativity and results."
        />
        <meta
          name="keywords"
          content="Top advertising agency in Dubai, best advertising agency Dubai, top marketing agency Dubai, innovative advertising Dubai"
        />
        <meta
          property="og:title"
          content="Top Advertising Agency in Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="Dubai's leading advertising agency. Innovative strategies and impactful campaigns for startups and businesses."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "Top advertising agency in Dubai, offering innovative marketing solutions.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Business Bay",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2048,
                "longitude": 55.2708
              },
              
            }
          `}
        </script>
      </Helmet>
      <TopAdvertisingAgencyDubai />
    </>
  );
  

  
export const ExcellentAdvertisingAgencyDubaiPage = () => (
  <>
    <Helmet>
      <title>Excellent Advertising Agency in Dubai | MilaajBrandset</title>
      <meta
        name="description"
        content="MilaajBrandset is an excellent advertising agency in Dubai, offering innovative and trustworthy marketing solutions. We are committed to ethical practices and client satisfaction."
      />
      <meta
        name="keywords"
        content="Excellent advertising agency in Dubai, top advertising agency Dubai, best marketing agency Dubai, trustworthy advertising Dubai"
      />
      <meta
        property="og:title"
        content="Excellent Advertising Agency in Dubai | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Dubai's excellent advertising agency. Innovative, trustworthy, and results-driven marketing solutions for startups and businesses."
      />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
            "description": "Excellent advertising agency in Dubai, offering innovative and trustworthy marketing solutions.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Business Bay",
              "addressLocality": "Dubai",
              "postalCode": "XXXXX",
              "addressCountry": "AE"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 25.2048,
              "longitude": 55.2708
            },
            
          }
        `}
      </script>
    </Helmet>
    <ExcellentAdvertisingAgencyDubai />
  </>
);
  

export const ReliableAdvertisingAgencyDubaiPage = () => (
  <>
    <Helmet>
      <title>Reliable Advertising Agency in Dubai | MilaajBrandset</title>
      <meta
        name="description"
        content="MilaajBrandset is a reliable advertising agency in Dubai, offering trustworthy and effective marketing solutions. We are committed to ethical practices and client satisfaction."
      />
      <meta
        name="keywords"
        content="Reliable advertising agency in Dubai, trustworthy advertising agency Dubai, best marketing agency Dubai, reliable marketing solutions Dubai"
      />
      <meta
        property="og:title"
        content="Reliable Advertising Agency in Dubai | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Dubai's reliable advertising agency. Trustworthy and effective marketing solutions for startups and businesses."
      />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
            "description": "Reliable advertising agency in Dubai, offering trustworthy and effective marketing solutions.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Business Bay",
              "addressLocality": "Dubai",
              "postalCode": "XXXXX",
              "addressCountry": "AE"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 25.2048,
              "longitude": 55.2708
            },
          }
        `}
      </script>
    </Helmet>
    <ReliableAdvertisingAgencyDubai />
  </>
);
  
  

export const CreativeAdvertisingAgencyDubaiPage = () => (
  <>
    <Helmet>
      <title>Creative Advertising Agency in Dubai | MilaajBrandset</title>
      <meta
        name="description"
        content="MilaajBrandset is a creative advertising agency in Dubai, offering innovative and impactful marketing solutions. We specialize in creating unique campaigns that captivate audiences and drive results."
      />
      <meta
        name="keywords"
        content="Creative advertising agency in Dubai, innovative advertising Dubai, best marketing agency Dubai, creative marketing solutions Dubai"
      />
      <meta
        property="og:title"
        content="Creative Advertising Agency in Dubai | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Dubai's creative advertising agency. Innovative and impactful marketing solutions for startups and businesses."
      />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "MilaajBrandset",
            "image": "https://milaajbrandset.com/favicon.ico",
            "description": "Creative advertising agency in Dubai, offering innovative and impactful marketing solutions.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Business Bay",
              "addressLocality": "Dubai",
              "postalCode": "XXXXX",
              "addressCountry": "AE"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 25.2048,
              "longitude": 55.2708
            },
           
          }
        `}
      </script>
    </Helmet>
    <CreativeAdvertisingAgencyDubai />
  </>
);