import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaPalette,
  FaLightbulb,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const BestBrandingServicesBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Best Branding Services in Bur Dubai" />

      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Branding Partner in Bur Dubai</Subheading>}
        heading={
          <Heading>
            Best Branding Services in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we offer the best branding services in Bur Dubai
            to help your business stand out and connect with your target
            audience. From logo design to brand strategy, we create unique and
            impactful brand identities tailored to your business needs in Bur
            Dubai.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3",
          "https://images.unsplash.com/photo-1521737711867-e3b97375f902?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3",
          "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Branding Journey"
      />

      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Top Branding Services in Bur Dubai"
        description="We provide a wide range of branding services to help your business thrive in Bur Dubai:"
        cards={[
          {
            icon: <FaPalette color="#ff5464" size={40} />,
            title: "Brand Identity",
            description:
              "Crafting unique logos, color schemes, and visual styles tailored for Bur Dubai.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Brand Strategy",
            description:
              "Developing a clear vision and positioning for your brand in Bur Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Brand Messaging",
            description:
              "Creating compelling taglines and brand stories for Bur Dubai.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Research",
            description:
              "Understanding your target market in Bur Dubai for better engagement.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Brand Launch",
            description:
              "Rolling out your brand with impactful campaigns in Bur Dubai.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Brand Management",
            description:
              "Ensuring consistency and growth for your brand in Bur Dubai.",
          },
        ]}
      />

      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Work</Subheading>}
        heading={<Heading>Branding Success Stories</Heading>}
        description={
          <Description>
            <strong>Device Repair Branding:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Created a modern, trustworthy brand identity in Bur Dubai</li>
              <li>Increased customer trust and recognition in Bur Dubai</li>
              <li>Delivered within a small business budget in Bur Dubai</li>
            </ul>
            <strong tw="mt-8 block">Education Provider Branding:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Developed a professional and approachable brand in Bur Dubai
              </li>
              <li>Boosted enrollment inquiries by 35%</li>
              <li>Enhanced brand visibility across Bur Dubai</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1581091870623-4c4e1b6d1cae?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3",
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3",
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3",
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />

      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="The Best Branding Experts in Bur Dubai"
        description="Here’s why businesses in Bur Dubai trust us for their branding needs:"
        cards={[
          {
            icon: <FaPalette color="#ff5464" size={40} />,
            title: "Expert Knowledge",
            description:
              "Our team has extensive experience in branding and understands the latest trends in Bur Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Proven Results",
            description:
              "We have a track record of delivering successful branding projects in Bur Dubai.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Your vision and success are our top priority in Bur Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
