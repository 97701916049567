// src/pages/CreativeBrandingAgencyBurDubai.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaPaintBrush,
  FaLightbulb,
  FaRocket,
  FaUsers,
  FaBrain,
  FaPalette,
  FaSearch,
  FaChartLine,
  FaHandshake,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const CreativeBrandingAgencyBurDubai = () => {
  return (
    <AnimationRevealPage>
      <Header from="Creative Branding Agency in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Creative Brand Partner</Subheading>}
        heading={
          <Heading>
            Transform Your Brand with{" "}
            <span tw="text-primary-500">Creative Brilliance</span>
          </Heading>
        }
        description={
          <Description>
            As a creative branding agency in Bur Dubai, we specialize in turning
            ordinary brands into extraordinary stories. Our team combines
            strategic thinking with artistic innovation to create memorable
            brand experiences that resonate with your audience and drive
            business growth.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3", // Creative team collaboration meeting
          "https://images.unsplash.com/photo-1542744094-3a31f272c490?ixlib=rb-4.0.3", // Designer working on brand identity
          "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3", // Modern creative agency workspace
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 50%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 35%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Let’s Create Something Amazing"
      />

      {/* Creative Process Section */}
      <StyledFeatures
        subheading={<Subheading>Our Creative Process</Subheading>}
        heading="Where Strategy Meets Artistry"
        description="Our approach blends analytical rigor with creative freedom:"
        cards={[
          {
            icon: <FaBrain color="#FF5733" size={40} />,
            title: "Idea Incubation",
            description:
              "We start with deep audience insights to generate concepts that resonate emotionally.",
          },
          {
            icon: <FaPalette color="#FF5733" size={40} />,
            title: "Visual Storytelling",
            description:
              "Crafting compelling narratives through color, typography, and imagery that captures attention.",
          },
          {
            icon: <FaRocket color="#FF5733" size={40} />,
            title: "Concept to Reality",
            description:
              "Turning creative ideas into tangible brand assets with precision and impact.",
          },
          {
            icon: <FaLightbulb color="#FF5733" size={40} />,
            title: "Innovative Solutions",
            description:
              "Pushing boundaries with fresh approaches that differentiate your brand in the market.",
          },
          {
            icon: <FaSearch color="#FF5733" size={40} />,
            title: "Audience-Centric Design",
            description:
              "Creating experiences tailored to your target audience’s preferences and behaviors.",
          },
          {
            icon: <FaChartLine color="#FF5733" size={40} />,
            title: "Performance Tracking",
            description:
              "Monitoring brand performance to refine strategies and maximize impact.",
          },
        ]}
      />

      {/* Why Choose Us Section */}
      <MainFeature1
        subheading={<Subheading>Creative Excellence</Subheading>}
        heading={<Heading>Why Brands Choose Us</Heading>}
        description={
          <Description>
            <strong>Startup-Focused Expertise:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Specialized in helping startups establish memorable brand
                identities from scratch.
              </li>
              <li>
                Collaborative approach that integrates your vision with our
                creative expertise.
              </li>
              <li>
                Flexible packages designed to accommodate growing businesses.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1558403194-611308249627?ixlib=rb-4.0.3", // Creative strategy meeting
          "https://images.unsplash.com/photo-1531973576160-7125cd663d86?ixlib=rb-4.0.3", // Successful project presentation
          "https://images.unsplash.com/photo-1536064479547-7ee40b74b807?ixlib=rb-4.0.3", // Brand collateral display
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 15%;",
          "left: 28%; bottom: 20%;",
        ]}
        primaryButtonText="Start Your Creative Journey"
      />

      {/* Creative Portfolio Section */}
      <StyledFeatures
        subheading={<Subheading>Creative Portfolio</Subheading>}
        heading="Bringing Brands to Life"
        description="Explore some of our recent creative projects:"
        cards={[
          {
            icon: <FaPaintBrush color="#FF5733" size={40} />,
            title: "Fintech Brand Launch",
            description:
              "Created a bold visual identity for a Dubai-based fintech startup, emphasizing innovation and trust.",
          },
          {
            icon: <FaUsers color="#FF5733" size={40} />,
            title: "Community-Focused Café",
            description:
              "Developed a warm, inviting brand experience for a local café targeting young professionals.",
          },
          {
            icon: <FaHandshake color="#FF5733" size={40} />,
            title: "E-commerce Rebrand",
            description:
              "Revitalized an e-commerce brand with a modern aesthetic that increased customer engagement by 35%.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
