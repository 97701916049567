import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import {
  FaMobileAlt,
  FaDesktop,
  FaChartLine,
  FaUsersCog,
  FaSearchPlus,
  FaShieldAlt,
} from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const StyledFeatures = styled(Features)`
  ${tw`mb-20`}
  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export const ResponsiveWebsiteDesignBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header */}
      <Header from="Responsive Website Design in Bur Dubai" />
      {/* Value Proposition Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Partner for Responsive Web Design</Subheading>}
        heading={
          <Heading>
            Responsive Website Design Services in{" "}
            <span tw="text-primary-500">Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in creating responsive websites that adapt seamlessly to all devices. As a startup based in Bur Dubai, we understand the importance of having a mobile-friendly website that engages users and drives conversions. Our team combines creativity with technical expertise to deliver websites that are fast, functional, and visually stunning.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Modern responsive web design
          "https://images.unsplash.com/photo-1587614387466-0a72ca909e16?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Mobile-first design
          "https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Creative process
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Learn More About Us"
      />
      {/* Core Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Responsive Website Design Solutions in Bur Dubai"
        description="We provide end-to-end services to ensure your website performs flawlessly across all devices:"
        cards={[
          {
            icon: <FaMobileAlt color="#ff5464" size={40} />,
            title: "Mobile-First Design",
            description:
              "Building websites optimized for mobile devices to enhance user experience in Bur Dubai.",
          },
          {
            icon: <FaDesktop color="#ff5464" size={40} />,
            title: "Cross-Device Compatibility",
            description:
              "Ensuring your website looks great on desktops, tablets, and smartphones in Bur Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Performance Optimization",
            description:
              "Improving website speed and performance for better engagement in Bur Dubai.",
          },
          {
            icon: <FaUsersCog color="#ff5464" size={40} />,
            title: "User-Centric Approach",
            description:
              "Designing websites with intuitive navigation and user-friendly interfaces in Bur Dubai.",
          },
          {
            icon: <FaSearchPlus color="#ff5464" size={40} />,
            title: "SEO Integration",
            description:
              "Incorporating SEO strategies to boost your website's visibility in search engines in Bur Dubai.",
          },
          {
            icon: <FaShieldAlt color="#ff5464" size={40} />,
            title: "Security & Reliability",
            description:
              "Implementing robust security measures to protect your website and data in Bur Dubai.",
          },
        ]}
      />
      {/* Case Studies Section */}
      <MainFeature1
        subheading={<Subheading>Our Success Stories</Subheading>}
        heading={<Heading>Responsive Website Design Success Stories</Heading>}
        description={
          <Description>
            <strong>Restaurant Website Redesign:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Transformed a local restaurant’s outdated website into a fully responsive platform in Bur Dubai.</li>
              <li>Increased online reservations by 35% within three months.</li>
              <li>Improved customer satisfaction with faster load times and mobile optimization.</li>
            </ul>
            <strong tw="mt-8 block">E-commerce Store Optimization:</strong>
            <ul tw="mt-4 list-disc pl-6">
              <li>Optimized an e-commerce store for mobile and tablet users in Bur Dubai.</li>
              <li>Achieved a 50% reduction in bounce rates.</li>
              <li>Boosted sales by 25% through improved usability and design.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Restaurant website
          "https://images.unsplash.com/photo-1585834275571-442109c918d2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // E-commerce store
          "https://images.unsplash.com/photo-1587614387466-0a72ca909e16?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3", // Analytics dashboard
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="View More Case Studies"
      />
      {/* Why Choose Us Section */}
      <StyledFeatures
        subheading={<Subheading>Why Choose Us</Subheading>}
        heading="Your Responsive Website Design Experts in Bur Dubai"
        description="Here’s why businesses in Bur Dubai trust us for their responsive web design needs:"
        cards={[
          {
            icon: <FaMobileAlt color="#ff5464" size={40} />,
            title: "Mobile-First Focus",
            description:
              "We prioritize mobile responsiveness to ensure your website reaches users effectively in Bur Dubai.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Design",
            description:
              "Using analytics and user behavior insights to create high-performing websites in Bur Dubai.",
          },
          {
            icon: <FaUsersCog color="#ff5464" size={40} />,
            title: "Customer-Centric Process",
            description:
              "We collaborate closely with you to bring your vision to life in Bur Dubai.",
          },
        ]}
      />
    </AnimationRevealPage>
  );
};

